import React, {useEffect, useState} from "react";

const CustomCSVTemplate = () => {
  return (
    <div className="custom-csv-template-info">
      <div className="param-section">
        <h3>Template Name</h3>
        <p>Please contact Card Dealer Pro Support to create a custom CSV export</p>
      </div>
      <button className={'btn primary'}>Contact us</button>
    </div>
  )
}

export default CustomCSVTemplate;