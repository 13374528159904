import ApiHelper from "../../helpers/apiHelper";
import ACTION_TYPE from "../../consts/actions";
import API_URL from "../../consts/urls";

export const getBatchList = (params) => dispatch => {
  dispatch({type: ACTION_TYPE.BATCHES_LIST_REQUEST});
  return ApiHelper.get(API_URL.GetBatchList, params).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_BATCH_INFO,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.BATCHES_LIST_FAILED});
  })
};

export const getProcessingBatch = () => dispatch => {
  return ApiHelper.get(API_URL.ProcessingStatus).then(res => {
    dispatch({
      type: ACTION_TYPE.PROCESSING_BATCH_INFO,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.BATCHES_LIST_FAILED});
  });
};

export const setBatchReload = (batch_id) => dispatch => {
  dispatch({
    type: ACTION_TYPE.BATCH_UPDATED,
    data: batch_id
  });
}

export const setResetBatchReload = () => dispatch => {
  dispatch({
    type: ACTION_TYPE.RESET_BATCH_UPDATED
  });
}