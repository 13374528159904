import React, {useCallback, useEffect, useRef, useState} from "react";
import {toastr} from "react-redux-toastr";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import breadCrumbIcon from "../../assets/images/breadCrumbIcon.svg"
import addIconWhite from "../../assets/images/addIconWhite.svg"
import cardValueDark from "../../assets/images/cardValueDark.svg"
import moreInfoDark from "../../assets/images/moreInfoDark.svg"
import ebayLogo from "../../assets/images/settings/ebayLogo.svg"
import openInNewTabRed from "../../assets/images/openInNewTabRed.svg"
import gavelDark from "../../assets/images/gavelDark.svg"
import cartDark from "../../assets/images/cartDark.svg"
import {ReactComponent as SearchIcon} from "../../assets/images/searchIconBlack.svg"

import {connect} from "react-redux";
import {useParams} from "react-router";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import SearchCards from "../../components/Popups/searchCards";
import {Link, useHistory} from "react-router-dom";
import FileUploader from "../../components/FileInput/fileHandler";
import {Spinner} from "react-bootstrap";
import {getMarketLink} from "../../helpers/util";
import DefaultData from "../../consts/defaultData";
import MatchAllView from "../../components/Popups/matchAllView";
import CustomLazyLoadImage from "../../components/LazyLoadImage";
import RouteLeavingGuard from "../../components/Popups/prompt";

const CardDetail = (props) => {
  const history = useHistory();
  const {batch_id, card_id} = useParams();
  const [showSearchImage, setShowSearchImage] = useState(false);
  const [fileBack, setFileBack] = useState(null);
  const [cardMatchData, setCardMatchData] = useState(DefaultData.defaultCardMatchData);
  const [cardMarvin, setCardMarvin] = useState({});
  const [cardStaticData, setCardStaticData] = useState(DefaultData.defaultCardDetail);
  const [selectedSubset, setSelectedSubSet] = useState(0);
  const [previewFrontImage, setPreviewFrontImage] = useState(true);
  const [cardNavInfo, setCardNavInfo] = useState({prev: {}, next: {}});
  const [staticLoading, setStaticLoading] = useState(false);
  const [matchDataLoading, setMatchDataLoading] = useState(false);
  const [matchAllViewPopupShow, setMatchAllViewPopupShow] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const _isMounted = useRef(true);

  const doActionOnMounted = (fn) => {
    if (_isMounted.current === true) {
      fn();
    }
  }

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, []);

  useEffect(() => {
    getCardStaticData(card_id);
    // getBatchDefaultInfo();
    const params = new URLSearchParams(props.location.search);
    // if (params.get('status') && params.get('status') === "1") {
    const popup_id = params.get('popup');
    if (popup_id && popup_id !== '0') {
      setIsDirty(true);
    }
    getCardMatchData(card_id, popup_id ? popup_id : '0');
    getBatchListInfo();
  }, [card_id, batch_id, props.location.search]);


  // const getBatchDefaultInfo = () => {
  //   ApiHelper.get(API_URL.GetBatchDefault).then(res => {
  //     setInventoryCustomInfo(res.data);
  //   }).catch(err => {
  //     setInventoryCustomInfo(DefaultData.defaultBatch);
  //   });
  // }
  const getBatchListInfo = () => {
    ApiHelper.get(API_URL.GetBatchListDetail, {
      batch: batch_id
    }).then(res => {
      const cards = res.data.cards;
      const currentCardIndex = cards.findIndex(card => card.card_id.toString() === card_id);
      const _cardNavInfo = {
        prev: { link: `#` }, next: { link: `#` }
      };
      if (currentCardIndex > 0) {
        _cardNavInfo.prev = {
          link: `/batches/${batch_id}/${cards[currentCardIndex-1].card_id}`,
          front_image: cards[currentCardIndex-1].front_image,
          title:cards[currentCardIndex-1].title,
          status:cards[currentCardIndex-1].status
        }
      }
      if (currentCardIndex > -1 && currentCardIndex < cards.length -1) {
        _cardNavInfo.next = {
          link: `/batches/${batch_id}/${cards[currentCardIndex + 1].card_id}`,
          front_image: cards[currentCardIndex + 1].front_image,
          title: cards[currentCardIndex + 1].title,
          status:cards[currentCardIndex + 1].status
        }
      }
      doActionOnMounted(() => setCardNavInfo(_cardNavInfo));
    }).catch(err => {
    })
  }
  const getCardStaticData = (card_id) => {
    setStaticLoading(true);
    setCardStaticData(DefaultData.defaultCardDetail);
    ApiHelper.get(API_URL.GetCardStaticDetail, {
      card: card_id
    }, {}, {}).then(res => {
      doActionOnMounted(() => {
        setCustomFields(res.data.custom_fields.map(c_f => ({
          field: c_f[0],
          value: c_f[1]
        })));
        setCardStaticData(res.data);
      });
    }).catch(err => {
      toastr.error('Err', err.data.message);
    }).finally(() => {
      setStaticLoading(false);
    })
  }
  const getCardMatchData = (card_id, popup_id) => {
    setMatchDataLoading(true);
    if (popup_id !== '0') {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setCardMatchData(DefaultData.defaultCardMatchData);
    ApiHelper.get(API_URL.GetCardMatchDetail, {
      card: card_id,
      popup: popup_id
    }, {}, {}).then(res => {
      doActionOnMounted(() => setCardMatchData(res.data));
    }).catch(err => {
      toastr.error('Err', err.response.data.message);
    }).finally(() => {
      setMatchDataLoading(false);
    })
    ApiHelper.get(API_URL.GetMarvinValue, {
      card: card_id,
      popup: popup_id}, {}, {}).then(res => {
      doActionOnMounted(() => setCardMarvin(
        {avg_value: res.data.avg_value,
          high_value: res.data.high_value,
          low_value: res.data.low_value}));
    }).catch(err => {
    });
  }
  
  const onChangeMatchData = (child_property, e) => {
    let newMatchData = {...cardMatchData};
    newMatchData[child_property][e.target.name] = e.target.value;
    setIsDirty(true);
    setCardMatchData(newMatchData);
  }
  
  const onChangeStaticData = (child_property, e) => {
    let newStaticData = {...cardStaticData};
    newStaticData[child_property][e.target.name] = e.target.value;
    setIsDirty(true);
    setCardStaticData(newStaticData);
  }

  const onChangeParallel = (e) => {
    setSelectedSubSet(e.target.value)
    setIsDirty(true);
    setCardMatchData({
      ...cardMatchData,
      card: {
        ...cardMatchData.card,
        subset: cardMatchData.parallel[e.target.value],
        attribute: cardMatchData.attrs[e.target.value]
      }
    })
  }
  
  const handleShowSearchCard = (searchData) => {
    ApiHelper.get(API_URL.SearchCardDetail, {
      sport: searchData.sport,
      year: searchData.year,
      uid: searchData.uid
    }).then(res => {
      setIsDirty(true);
      setCardMatchData(res.data);
      setCardStaticData({
        ...cardStaticData,
        card_info: {...cardStaticData.card_info, status: 1}
      })
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    }).finally(() => {
      setShowSearchImage(false);
    })
    
  }
  
  const handleToPopup = (popup_id) => {
    history.push({
      pathname: `/batches/${batch_id}/${card_id}`,
      search: '?' + new URLSearchParams({popup: popup_id, status: cardStaticData.card_info.status}).toString()
    });
  }

  const handleNavLink = (link, status) => {
    if (link !== '#') {
      history.push({
        pathname: link,
        search: '?' + new URLSearchParams({status: status}).toString()
      });
    }
  }
  const handleHideSearchImage = () => {
    setShowSearchImage(false);
  }
  const handleShowSearchImage = () => {
    setShowSearchImage(true);
  }
  
  const handleSaveChanges = () => {
    if (!isDirty) {
      return;
    }
    let newCardFormData = new FormData();
    for (let _key in cardStaticData.card_info) {
      newCardFormData.append(_key, cardStaticData.card_info[_key]);
    }
    for (let _key = 0; _key < 10; _key ++) {
      newCardFormData.append(`custom_field${_key+1}`, '');
    }
    // newCardFormData.set('status', cardStaticData.card_info.status || cardStaticData.card_info.manual_status);
    // newCardFormData.set('status', cardStaticData.card_info.status || cardStaticData.card_info.manual_status);
    newCardFormData.set('card_id', card_id);
    
    for (let _key in cardStaticData.grade_info) {
      newCardFormData.append(_key, cardStaticData.grade_info[_key]);
    }
    
    for (let _key in cardMatchData.card) {
      newCardFormData.append(_key, cardMatchData.card[_key]);
    }
    
    newCardFormData.append('status', '1');

    newCardFormData.delete("front_image");
    newCardFormData.delete("stock_image");
    newCardFormData.delete("manual_status");
    
    if (fileBack != null) {
      newCardFormData.set('back_image', fileBack);
    } else {
      newCardFormData.delete('back_image');
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    ApiHelper.put(API_URL.UpdateCard, {}, config, newCardFormData)
      .then(res => {
        if (cardStaticData.card_info.status === 0 && cardStaticData.card_info.manual_status === 1) {
          setCardStaticData({
            ...cardStaticData,
            card_info: {...cardStaticData.card_info, status: 1}
          })
        }
        getCardStaticData(card_id);
        getCardMatchData(card_id, '0');
        toastr.success('Success', res.data.message);
      }).catch(err => {
      toastr.error('Error', err.response.data.message);
    });
  }

  const handleFileBack = (file) => {
    setFileBack(file);
  }

  const previewImage = useCallback(() => {
    if (previewFrontImage) {

      if (cardStaticData.card_info.front_image !== '') {
        return (
          <Zoom>
            <CustomLazyLoadImage effect="blur" src={cardStaticData.card_info.front_image} alt="" style={{height: '330px'}}/>
          </Zoom>
        )
      }
      return (
        <div className={"no-preview-image"}>No Image</div>
      )
    } else {
      if (cardStaticData.card_info.back_image !== '') {
        return (
          <Zoom>
            <CustomLazyLoadImage effect="blur" src={cardStaticData.card_info.back_image} alt="" style={{height: '330px'}}/>
          </Zoom>
        )
      }
      return (
        <div className={"no-preview-image"}>
          <div className={"no-image"}>
            {fileBack ?
              <img src={URL.createObjectURL(fileBack)} className="img-fluid" style={{height: '100%'}} alt={''}/> :
              <span>No Image</span>
            }
          </div>
          <div className={"no-image-upload"} >
            <FileUploader handleFile={handleFileBack} />
          </div>
        </div>
      )
    }
  }, [previewFrontImage, cardStaticData.card_info.front_image, cardStaticData.card_info.back_image, fileBack]);

  // const onChangeInventoryCustomInfo = (e) => {
  //   setInventoryCustomInfo({
  //     ...inventoryCustomInfo,
  //     [e.target.name]: e.target.value
  //   });
  // }

  const onChangeCustomField = (i, value) => {
    setCustomFields([...customFields.slice(0, i),
      {name: customFields[i].name, value}, ...customFields.slice(i + 1)]);
  }

  const getPopupState = () => {
    const params = new URLSearchParams(props.location.search);
    return { popup: params.get('popup'), status: cardStaticData.card_info.status };
  }

  const getCardTitle = (title) => {
    if (title?.length > 100) {
      return `${title.substring(0, 100)}...`
    }
    return title;
  }

  const navigate = (path) => {
    history.push(path);
  }

  if (cardStaticData && showSearchImage) {
    return (<SearchCards batchId={cardStaticData.batch_name.batch_id}
                         handleHide={handleHideSearchImage}
                         handleAction={handleShowSearchCard}/>);
  }

  return (
    <div className={"card-details"}>
      <RouteLeavingGuard
        when={isDirty}
        // Navigate function
        navigate={navigate}
        shouldBlockNavigation={location => {
          return isDirty;
          // if (isDirty) {
          //   if (!location.pathname.startsWith('/settings')) {
          //     return true
          //   }
          // }
          // return false
        }}
      />
      <div className="content-header-main">
        <div className="column left vert">
          <h1>Card Details</h1>
          <div className="breadcrumbs red-on-dark normal-mode-only">
            <Link to="/batches">Batches</Link>
            <img src={breadCrumbIcon} alt=""/>
            {cardStaticData &&
            <Link to={`/batches/${cardStaticData.batch_name.batch_id}`}>{cardStaticData.batch_name.name}</Link>}
            <img src={breadCrumbIcon} alt=""/>
            {cardMatchData && <span className="current-page">{getCardTitle(cardMatchData.title)}</span>}
          </div>
        </div>
        <div className="column right">
          <div className="btn primary create-new-batch-btn" onClick={handleSaveChanges}>
            <p>Save changes</p>
          </div>
        </div>
        <div className="btn primary round floating create-new-batch-btn">
          <img src={addIconWhite} alt=""/>
        </div>
      </div>
      {matchAllViewPopupShow &&
        <MatchAllView handleHide={() => setMatchAllViewPopupShow(false)}
                      matches={cardStaticData.pop_up}
                      popupStatus={getPopupState()}
        />
      }
      <div className="content-container">
        {/*{cardStaticData.pop_up.length > 0 &&*/}
          <div className={"card-match-status"}>
            <div className={"matched-to"}>
              <h2>Matched To</h2>
              <div className="other-matched-card" onClick={() => handleToPopup(0)}>
                <div className="card-image">
                  <CustomLazyLoadImage src={cardMatchData.card.stock_image} effect={'blur'} />
                </div>
                <div className="description">
                  <p className="card-title">
                    {getCardTitle(`${cardMatchData.card?.sport} ${cardMatchData.card?.year} ${cardMatchData.card?.brand} ${cardMatchData.card?.card} ${cardMatchData.card?.player}`)}
                  </p>
                </div>
              </div>
            </div>
            <div className="other-matched-cards-container" id="other-matches">
              <div className={'other-matches-header'}>
                <h2>Other Matches</h2>
                <h2 style={{color: 'red', cursor: 'pointer'}} onClick={() => setMatchAllViewPopupShow(true)}>View All</h2>
              </div>
              <div className="other-matched-cards">
                {cardStaticData.pop_up.slice(0, 8).map((_popup) => {
                  return (
                    <div className="card-image" key={_popup.popup_id}
                         onClick={() => handleToPopup(_popup.popup_id)}>
                      <CustomLazyLoadImage src={_popup.front_image} effect={'blur'}/>
                    </div>
                  )
                })}
                {cardStaticData.pop_up.length > 8 &&
                  <div className={"card-image"}>
                    <p>{`+ ${cardStaticData.pop_up.length - 8}`}</p>
                  </div>}
              </div>
            </div>
            <div className="not-the-right-card-actions-container">
              <h2>Manual Search</h2>
              <div className="not-the-right-card-actions" onClick={handleShowSearchImage}>
                <SearchIcon style={{ width: '24px', height: '24px', margin: 'auto 12px'}}/>
                <p>Search Manually</p>
              </div>
            </div>
          </div>
        {/*}*/}
        <div className="card-main">
          <div className="card-main-image">
            {staticLoading === true ?
              <div className="user-submitted-image">
                <div className='component-loading-overlay'>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              </div>:
            <div className="user-submitted-image">
              {previewImage()}
              <div className={"card-item-image-preview"}>
                <div className={'tab-front-back-switch' + (previewFrontImage ? ' active' : '')}
                      onClick={() => setPreviewFrontImage(true)}>
                  Front
                </div>
                <div className={'tab-front-back-switch' + (previewFrontImage ? '' : ' active')}
                     onClick={() => setPreviewFrontImage(false)}>
                  Back
                </div>
              </div>
            </div>}
            <div className="info-note">
              <p>Notes</p>
              <textarea name="note" id="" value={cardStaticData.card_info.note} onChange={(e) => {
                onChangeStaticData('card_info', e)
              }}/>
            </div>
          </div>
          <div className="card-main-info">
            <div className={"card-main-info-column"}>
              <div className="info editable">
                <p>Sport</p>
                <input type="text" name={'sport'} value={cardMatchData.card.sport} onChange={(e) => {
                  onChangeMatchData('card', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Player</p>
                <input type="text" name={'player'} value={cardMatchData.card.player} onChange={(e) => {
                  onChangeMatchData('card', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Year</p>
                <input type="text" name={'year'} value={cardMatchData.card.year} onChange={(e) => {
                  onChangeMatchData('card', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Brand</p>
                <textarea className={'brand'} name="brand" id="" value={cardMatchData.card.brand}
                          onChange={(e) => {
                            onChangeMatchData('card', e)
                          }}>{cardMatchData.card.brand}</textarea>
              </div>
              <div className="info editable">
                <p>Card#</p>
                <input type="text" name={'card'} value={cardMatchData.card.card} onChange={(e) => {
                  onChangeMatchData('card', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Subset/parallel</p>
                <select name="parallel" value={selectedSubset} style={{fontWeight: 'bold'}} onChange={onChangeParallel}>
                  {
                    cardMatchData.parallel.map((_parallel, i) => {
                      return (
                        <option value={i} key={i}>{_parallel}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="info editable">
                <p>Variation</p>
                <input type="text" name={'variation'} value={cardMatchData.card.variation}
                       onChange={(e) => {
                         onChangeMatchData('card', e)
                       }}/>
              </div>
              <div className="info editable">
                <p>Attributes</p>
                <input type="text" name={'attribute'} disabled value={cardMatchData.card.attribute}/>
                {/*onChange={(e) => {onChangeMatchData('card', e)}}/>*/}
              </div>
              <div className="info editable">
                <p>Team</p>
                <input type="text" name={'team'} value={cardMatchData.card.team}
                       onChange={(e) => {
                         onChangeMatchData('card', e)
                       }}/>
              </div>
            </div>
            <div className={"card-main-info-column"}>
              <div className="info editable">
                <p>Condition / Grade Name</p>
                {/* <input type="text" name={'grade'} value={cardStaticData.grade_info.grade} onChange={(e) => {
                  onChangeStaticData('grade_info', e)
                }}/> */}
                <select name={'grade'} value={cardStaticData.grade_info.grade}
                        onChange={(e) => onChangeStaticData('grade_info', e)}>
                  <option value={'Near mint or better'}>Near mint or better</option>
                  <option value={'Excellent'}>Excellent</option>
                  <option value={'Very good'}>Very good</option>
                  <option value={'Poor'}>Poor</option>
                </select>
              </div>
              <div className="info editable">
                <p>Graded</p>
                <select name={'graded'} value={cardStaticData.grade_info.graded}
                        onChange={(e) => onChangeStaticData('grade_info', e)}>
                  <option value={'Graded'}>Graded</option>
                  <option value={'Ungraded'}>Ungraded</option>
                </select>
              </div>
              <div className="info editable">
                <p>Grader</p>
                {/* <input type="text" name={'grader'} value={cardStaticData.grade_info.grader?.toUpperCase()}
                       onChange={(e) => {
                         onChangeStaticData('grade_info', e)
                       }}/> */}
                <select name={'grader'} value={cardStaticData.grade_info.grader?.toUpperCase()}
                        onChange={(e) => onChangeStaticData('grade_info', e)}>
                  <option value={''}>N/A</option>
                  <option value={'PSA'}>PSA</option>
                  <option value={'BCCG'}>BCCG</option>
                  <option value={'BVG'}>BVG</option>
                  <option value={'BGS'}>BGS</option>
                  <option value={'CSG'}>CSG</option>
                  <option value={'SGC'}>SGC</option>
                  <option value={'KSA'}>KSA</option>
                  <option value={'GMA'}>GMA</option>
                  <option value={'HGA'}>HGA</option>
                  <option value={'ISA'}>ISA</option>
                  <option value={'GSG'}>GSG</option>
                  <option value={'PGS'}>PGS</option>
                  <option value={'MNT'}>MNT</option>
                  <option value={'TAG'}>TAG</option>
                  <option value={'Rare'}>Rare</option>
                  <option value={'RCG'}>RCG</option>
                  <option value={'CGA'}>CGA</option>
                  <option value={'TCG'}>TCG</option>
                  <option value={'OTHER'}>Other</option>
                </select>
              </div>
              <div className="info editable">
                <p>Numeric grade</p>
                {/* <input type="text" name={'numeric_grade'} value={cardStaticData.grade_info.numeric_grade}
                       onChange={(e) => {
                         onChangeStaticData('grade_info', e)
                       }}/> */}
                <select name={'numeric_grade'} value={cardStaticData.grade_info.numeric_grade}
                        onChange={(e) => onChangeStaticData('grade_info', e)}>
                  <option value={''}>N/A</option>
                  <option value={'10'}>10</option>
                  <option value={'9.5'}>9.5</option>
                  <option value={'9'}>9</option>
                  <option value={'8.5'}>8.5</option>
                  <option value={'8'}>8</option>
                  <option value={'7.5'}>7.5</option>
                  <option value={'7'}>7</option>
                  <option value={'6.5'}>6.5</option>
                  <option value={'6'}>6</option>
                  <option value={'5.5'}>5.5</option>
                  <option value={'5'}>5</option>
                  <option value={'4.5'}>4.5</option>
                  <option value={'4'}>4</option>
                  <option value={'3.5'}>3.5</option>
                  <option value={'3'}>3</option>
                  <option value={'2.5'}>2.5</option>
                  <option value={'2'}>2</option>
                  <option value={'1.5'}>1.5</option>
                  <option value={'1'}>1</option>
                </select>
              </div>
              <div className="info editable">
                <p>Cert #</p>
                <input type="text" name={'cert'} value={cardStaticData.grade_info.cert} onChange={(e) => {
                  onChangeStaticData('grade_info', e)
                }}/>
              </div>
            </div>
            <div className={"card-main-info-column"}>
              <div className="info editable">
                <p>Price</p>
                <input type="text" placeholder="$0.00" name={'start_price'}
                       value={cardStaticData.card_info.start_price} onChange={(e) => {
                  onChangeStaticData('card_info', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Purchase price</p>
                <input type="text" placeholder="$0.00" name={'purchase_price'}
                       value={cardStaticData.card_info.purchase_price} onChange={(e) => {
                  onChangeStaticData('card_info', e)
                }}/>
              </div>
              <div className="info editable">
                <p>Purchase Date</p>
                <input type="date" placeholder="N/A" name={'purchase_date'}
                       value={cardStaticData.card_info.purchase_date || ""} onChange={(e) => {
                  onChangeStaticData('card_info', e)
                }}/>
              </div>
              <div className="market-places">
                <div className="market-place card-value">
                  <div className="card-value-header">
                    <img src={cardValueDark} alt=""/>
                    <p>Card value</p>
                    <div className="more-info">
                      <img src={moreInfoDark} alt=""/>
                      <p>
                        Card values are calculated off of historic eBay sales. To see the eBay sales/comps that are
                        part of the value calculation, click here or on the eBay Comps button. You can also use the
                        eBay Active Listings button to see all available listings of this card on eBay.
                      </p>
                    </div>
                  </div>
                  <div className="values">
                    <div className="value">
                      <p>Average</p>
                      <span>$ {cardMarvin.avg_value}</span>
                    </div>
                    <div className="value">
                      <p>High</p>
                      <span>$ {cardMarvin.high_value}</span>
                    </div>
                    <div className="value">
                      <p>Low</p>
                      <span>$ {cardMarvin.low_value}</span>
                    </div>
                  </div>
                </div>
                <a className="market-place" href={getMarketLink('ebay_sold', cardMatchData?.card, cardStaticData?.grade_info)} target="_blank">
                  <img src={cartDark} alt=""/>
                  <p>Recent Sales</p>
                  <div className="open-in-new">
                    <img src={ebayLogo} alt="" className="market-place-logo"/>
                    <img src={openInNewTabRed} alt="" className="open-in-new-icon"/>
                  </div>
                </a>
                <a className="market-place" href={getMarketLink('ebay_active', cardMatchData?.card, cardStaticData?.grade_info)} target="_blank">
                  <img src={gavelDark} alt=""/>
                  <p>Active Listings</p>
                  <div className="open-in-new">
                    <img src={ebayLogo} alt="" className="market-place-logo"/>
                    <img src={openInNewTabRed} alt="" className="open-in-new-icon"/>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="inventory-card-setting">
          <h2>Inventory & Custom Fields</h2>
          <div className={"inventory-settings"}>
            <h3 className="underline">Inventory</h3>
            <div className={"inventory-inputs"}>
              <div className="row1">
                <div className="inventory-field">
                  <label htmlFor="">SKU</label>
                  <input type="text" name="sku" value={cardStaticData?.card_info?.sku} onChange={(e) => onChangeStaticData('card_info', e)}/>
                </div>
              </div>
              <div className="inventory-field">
                <label htmlFor="">Cabinet</label>
                <input type="text" name="cabinet" value={cardStaticData?.card_info?.cabinet} onChange={(e) => onChangeStaticData('card_info', e)}/>
              </div>
              <div className="inventory-field">
                <label htmlFor="">Shelf</label>
                <input type="text" name="shelf" value={cardStaticData?.card_info?.shelf} onChange={(e) => onChangeStaticData('card_info', e)}/>
              </div>
              <div className="inventory-field">
                <label htmlFor="">Box</label>
                <input type="text" name="box" value={cardStaticData?.card_info?.box} onChange={(e) => onChangeStaticData('card_info', e)}/>
              </div>
              <div className="inventory-field">
                <label htmlFor="">Row</label>
                <input type="text" name="row" value={cardStaticData?.card_info?.row} onChange={(e) => onChangeStaticData('card_info', e)}/>
              </div>
              <div className="inventory-field">
                <label htmlFor="">Section</label>
                <input type="text" name="section" value={cardStaticData?.card_info.section} onChange={(e) => onChangeStaticData('card_info', e)}/>
              </div>
            </div>
          </div>
          <div className={"inventory-settings"}>
            <h3 className="underline">Custom Fields</h3>
            <div className={"inventory-inputs"}>
              {cardStaticData.custom_fields?.length === 0 &&
                <p>You currently do not have any custom fields configured. To enable and create custom fields, please</p>
              }
              {cardStaticData.custom_fields?.map((field, i) => (
                <div className="inventory-field" key={`field_${i}`}>
                  <label htmlFor="">{field[0]}</label>
                  <input type="text" name={field[0]} value={field[1]}
                         onChange={(e) => onChangeCustomField(i, e.target.value)}/>
                </div>))}
            </div>
          </div>
        </div>
        <div className={"batch-cards-list-container"}>
          <button className={'btn primary btn-prev'} disabled={cardNavInfo.prev.link === '#'}
                  onClick={() => handleNavLink(cardNavInfo.prev.link, cardNavInfo.prev.status)}>{`< Previous`}</button>
          <div className={'batch-cards-list'}>
            <div className={'other-matched-card' + (cardNavInfo.prev?.front_image ? ' nav-card' : '')}
                 onClick={() => handleNavLink(cardNavInfo.prev.link, cardNavInfo.prev.status)}>
              {cardNavInfo.prev?.front_image &&<div className="card-image">
                <CustomLazyLoadImage src={cardNavInfo.prev.front_image} effect={'blur'}/>
              </div>}
              <div className="description">
                <p className="card-title">{getCardTitle(cardNavInfo.prev?.title)}</p>
              </div>
            </div>
            <div className="other-matched-card">
              <div className="card-image">
                <CustomLazyLoadImage src={cardStaticData?.card_info?.front_image} effect={'blur'}/>
              </div>
              <div className="description">
                <p className="card-title">{getCardTitle(cardMatchData?.title)}</p>
              </div>
            </div>
            <div className={'other-matched-card' + (cardNavInfo.next?.front_image ? ' nav-card' : '')}
                 onClick={() => handleNavLink(cardNavInfo.next.link, cardNavInfo.next.status)}>
              {cardNavInfo.next?.front_image && <div className="card-image">
                <CustomLazyLoadImage src={cardNavInfo.next.front_image} effect={'blur'}/>
              </div>}
              <div className="description">
                <p className="card-title">{getCardTitle(cardNavInfo.next?.title)}</p>
              </div>
            </div>
          </div>
          <button className={'btn primary btn-next'} disabled={cardNavInfo.next.link === '#'}
                  onClick={() => handleNavLink(cardNavInfo.next.link, cardNavInfo.next.status)}>{`Next >`}</button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  batches: state.batches,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetail);