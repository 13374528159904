import React, {useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import {Redirect} from "react-router";
import Sidebar from "./sidebar";
import routes from "../../routes.js";
import NavbarPage from "./navbar";
import Footer from "./footer";
import {useDispatch, useSelector} from "react-redux";
import {getUserRole} from "../../redux/actions/auth";

const Layout = props => {
  const admin_role = useSelector(state => state.auth.admin_role)
  const token = useSelector(state=>state.auth.token)
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getUserRole());
  }, [token])
  
  const getRoutes = () => {
    let roleRoutes = []
    for (const [key, value] of Object.entries(routes)) {
      if (routes[key].role === admin_role) {
        roleRoutes = value.routes;
      }
    }
    return roleRoutes.map((_route, key) => {
      return (
        <Route
          exact
          path={_route.path}
          key={key}
          render={routeProps => (
            <_route.component
              {...routeProps}
            />
          )}
        />
      );
    });
  };
  
  if(admin_role === undefined){
    return null
  }
  
  return (
    <div className="wrapper">
      <Sidebar {...props} />
      <div className="main-container">
        <NavbarPage {...props} />
        <Switch>
          {getRoutes()}
          {(admin_role === 0) ? <Redirect from="/" to="/dashboard"/> : <Redirect from="/" to="/admin/dashboard"/>}
        </Switch>
        <Footer/>
      </div>
    </div>
  );
}

export default Layout;