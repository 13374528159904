import ACTION_TYPE from "../../consts/actions";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";

export const getEBayTemplates = () => dispatch => {
  dispatch({type: ACTION_TYPE.EBAY_TEMPLATES_REQUEST});
  return ApiHelper.get(API_URL.EbayTemplate).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_EBAY_TEMPLATES,
      data: (res.data.template.map(t => {
        t.created_date = new Date(t.created_date);
        t.edited_date = new Date(t.edited_date);
        return t;
      }))
    });
  }).catch(e => {
    dispatch({type: ACTION_TYPE.EBAY_TEMPLATES_FAILED});
  });
}
