import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noCardsInBatch from "../../assets/images/noCardsInBatch.svg";

const CustomLazyLoadImage = (props) => {
  const onImageError = (e) => {
    e.target.src = noCardsInBatch
  }
  return (
    <LazyLoadImage {...props} onError={onImageError} />
  )
};

export default CustomLazyLoadImage;