import React, {useMemo} from "react";
import Chart from "react-apexcharts";

const BasicChart = React.memo((props) => {
  const {xVal, yVal} = props;
  const state = {
    series: [{
      name: 'Contrats',
      data: yVal
    }],
    options: {
      chart: {
        type: 'bar',
      },
      legend: { show: false },
      xaxis: {
        categories: xVal,
        labels: {
          style: {
            fontSize: '10px',
            rotate: 0,
          }
        }
      },
      title: {
        text: "By Each Membership Level"
      },
    }
  }

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      width="100%"
      height="370px"
    />
  )
});

export default BasicChart;