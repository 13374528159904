import React, {useState} from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import {useHistory, useLocation} from "react-router";
import CustomLazyLoadImage from "../LazyLoadImage";

const MatchAllView = ({handleHide, matches, popupStatus}) => {
  const history = useHistory()
  const location = useLocation();
  const [selectedMatch, setSelectedMatch] = useState(-1);
  const onClickMatch = (index) => {
    setSelectedMatch(index);
  }

  const onClickApply = () => {
    if (selectedMatch === -1) {
      return;
    }
    const matchInfo = matches[selectedMatch];
    history.push({
      pathname: location.pathname,
      search: '?' + new URLSearchParams({popup: matchInfo.popup_id, status: popupStatus.status}).toString()
    });
    handleHide();
  }
  return (
    <div className="main-popup-container match-all-view">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>View All Matches</h2>
          <div className="close-popup">
            <img src={closeIconBlack} alt="" onClick={handleHide}/>
          </div>
        </div>
        <div className="body">
          {matches.map((popup, index) =>
            (<div className={'card-match-popup' + (index === selectedMatch ? ' active' : '')}
                  key={`${popup.popup_id}_${index}`}
                  onClick={() => onClickMatch(index)}
            >
              <div className="card-popup-image">
                <CustomLazyLoadImage src={popup.front_image} effect={"blur"} alt=""/>
              </div>
              <div className={"card-popup-info"}>
                <p className={'year-brand'}>{`${popup.year} ${popup.brand}`}</p>
                <p className={'year-brand card-player'}>{`${popup.card} ${popup.player}`}</p>
              </div>
              <div className="match-select"/>
            </div>)
          )}
        </div>
        <div className="footer">
          <div className="btn primary apply-changes" onClick={onClickApply}>Apply Changes</div>
        </div>
      </div>
    </div>
  )
}

export default MatchAllView;