import React, {useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {validatePassword} from '../../helpers/commonHelper';
import ApiHelper from '../../helpers/apiHelper';
import logoImage from "../../assets/images/logo.png";
import {Spinner} from "react-bootstrap";
import backArrow from "../../assets/images/backArrowRed.svg";
import API_URL from "../../consts/urls";

const ResetPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState({
    password: '',
    confirmPwd: '',
  })
  
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  
  let query = useQuery();
  let email = query.get('email');
  let token = query.get('token');
  
  const handleChangeInput = e => {
    if (e.target.value !== '') {
      setErrorText({...errorText, [e.target.name]: ''})
    }
  };
  
  const handleResetPassword = e => {
    e.preventDefault();
    let password = e.target.elements.password.value;
    let confirmPassword = e.target.elements.confirmPassword.value;
    
    if (password === '') {
      setErrorText({...errorText, password: 'Password is required'})
      return;
    }
    
    if (!validatePassword(password)) {
      const errText = 'Password should be 8-20 characters, which contain at least one numeric digit, ' +
        'one uppercase and one lowercase letter'
      toastr.error('Warning!', errText);
      return;
    }
    
    if (confirmPassword === '') {
      toastr.error('Warning!', 'ConfirmPassword is required');
      return;
    }
    
    if (password !== confirmPassword) {
      toastr.error('Warning!', 'Password does not match');
      return;
    }
    
    setLoading(true);
    ApiHelper.post(API_URL.PasswordResetConfirm, {}, {}, {
      email: email,
      token: token,
      password: password,
    }, false).then(res => {
      setLoading(false);
      toastr.success('Success!', 'Password was successfully reset.');
      history.push('/login');
    }).catch(err => {
      setLoading(false);
      toastr.error('Fail!', 'Please try again');
    })
  };
  
  return (
    <div className='reset-password login-bg'>
      <div className="main-container reset-password">
        <div className="sec-container">
          <div className="logo">
            <img src={logoImage} alt="" onClick={() => history.push('/login')}/>
          </div>
          {!loading && <div className="reset-password-container">
            <h1>Reset password</h1>
            <p>Please enter your new password below</p>
            <form onSubmit={handleResetPassword}>
              
              <div className="input text">
                <label htmlFor="new-password">New password</label>
                <input type="password" name="password" id="password" onChange={handleChangeInput}
                />
              </div>
              <div className="input text">
                <label htmlFor="confirm-new-password">Confirm new password</label>
                <input type="password" name="confirmPassword" id="confirmPassword" onChange={handleChangeInput}/>
              </div>
              
              <button type="submit" className="btn primary">Reset</button>
              <div className="no-account-yet">
                <Link to="/login"> <img src={backArrow} alt=""/> Back to login page</Link>
              </div>
            </form>
          
          </div>}
          {loading &&
          <div className="component-loading-overlay">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          }
        
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;