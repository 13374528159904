const defaultEbayTemplate = {
  name: '',
  listing_type: "Fixed Price",
  listing_price: true,
  calculated_method: true,
  fixed_amount_to_price: 0,
  start_price: 0,
  percent_to_price: 0,
  allow_best_offer: true,
  duration: "7 Days",
  free_shipping: false,
  handling_time: 1,
  shipping_service: 'eBay Standard Envelope',
  shipping_cost: 0.0,
  additional_items: '',
  returns: true,
  return_profile_name: '',
  shipping_profile_name: '',
  refund_given_as: "Money Back",
  default: false,
  returns_within: 14,
  additional_return_info: '',
  zip_code: '',
  auto_decline_offer: 0.0,
  auto_accept_offer: 0.0,
  store_category: 0,
  return_shipping_paid_by: 'Buyer',
  enable_buy_it_now_price: false,
  state: '',
  city: ''
}

const defaultTitleTemplate = [{
  "label": "Standard Title",
  "value": "{{year}} {{brand}} #{{card}} {{player}} {{variation}} {{grader}} {{numerical}}"
}];

const defaultTemplateFieldKeys = [
  "sport", "year", "brand", "player", "card", "attribute", "team", "variation", "grader", "grade", "numerical", "subset", "cert",
  "sku", "rookie", "auto"
]
const defaultDescriptionTemplate = [{
  "label": "Standard Description",
  "value": "This is a nice example of a {{year}} {{brand}} #{{card}} {{player}} {{subset}}.\n" +
    "<br><br>Other important information about this card:\n" +
    "<br>{{variation}}\n" +
    "<br>{{grader}} {{numerical}} {{grade}}\n" +
    "<br>{{attribute}}\n" +
    "<br>{{note}}\n" +
    "<br><br>In {{year}} {{player}} played for the {{team}}.\n" +
    "<br><br>Please check out our other listings for more great cards."
}]


const defaultCardDetail = {
  "batch_name": {
    "batch_id": "",
    "name": ""
  },
  "card_info": {
    "card_id": 0,
    "status": 0,
    "manual_status": 0,
    "purchase_price": "",
    "note": "",
    "cabinet": "",
    "shelf": "",
    "box": "",
    "row": "",
    "section": "",
    "sku": "",
    "store_category": "",
    "start_price": "",
    "buy_it_now_price": "",
    "best_offer": "",
    "ebay_format": "",
    "shipping_service": "",
    "shipping_cost": "",
    "duration": "",
    "returns": "",
    "zip_code": null,
    "shipping_profile_name": null,
    "return_profile_name": null,
    "payment_profile_name": null,
    "application": "",
    "auction": "",
    "commission": "",
    "conditions": "",
    "category_id": "",
    "category_id2": "",
    "category_id3": "",
    "category_id4": "",
    "consignor_id": "",
    "shipping_category_id": "",
    "min_bid": "",
    "weight": "",
    "custom_field1": "",
    "custom_field2": "",
    "custom_field3": "",
    "custom_field4": "",
    "custom_field5": "",
    "custom_field6": "",
    "custom_field7": "",
    "custom_field8": "",
    "custom_field9": "",
    "custom_field10": "",
    "front_image": "",
    "back_image": "",
    "lot_number": "",
    "dispatch_time_max": "",
    "purchase_date": "",
  },
  "pop_up": [],
  "grade_info": {
    "graded": "",
    "grader": "",
    "grade": "",
    "cert": "",
    "numeric_grade": ""
  }
}

const defaultBatch = {
  "cabinet": "",
  "shelf": "",
  "box": "",
  "row": "",
  "section": "",
  "sku": "",
  "store_category": "",
  "grade": '',
  "buy_it_now_price": 0.0,
  "best_offer": "Yes",
  "ebay_format": "Auction",
  "shipping_service": "",
  "shipping_cost": 0.0,
  "duration": "",
  "returns": "",
  "application": "",
  "auction": 0.0,
  "commission": "",
  "conditions": "",
  "category_id": "",
  "category_id2": "",
  "category_id3": "",
  "category_id4": "",
  "consignor_id": "",
  "shipping_category_id": "",
  "min_bid": 0.0,
  "weight": "",
  "custom_field1": "",
  "custom_field2": "",
  "custom_field3": "",
  "custom_field4": "",
  "custom_field5": "",
  "custom_field6": "",
  "custom_field7": "",
  "custom_field8": "",
  "custom_field9": "",
  "custom_field10": "",
  "zip_code": "",
  "shipping_profile_name": "",
  "return_profile_name": "",
  "payment_profile_name": "",
  "dispatch_time_max": "3"
}

const defaultCardMatchData = {
  "card": {
    "sport": "",
    "year": "",
    "card": "",
    "player": "",
    "brand": "",
    "subset": "",
    "attribute": "",
    "team": "",
    "variation": "",
    "stock_image": ""
  },
  "parallel": [
    ""
  ],
  "attrs": [
    ""
  ],
  "marvin": {
    "avg_value": "0.00",
    "high_value": "0.00",
    "low_value": "0.00"
  }
}

const defaultExportSettingOption = {
  ebay_export: false,
  auction_export: false,
  shopify_export: false,
  custom_export: false
};

const batchSpreadNumericColumns = [
  'card'
]
const batchSpreadColumns = [
  'sport', 'year', 'brand', 'player', 'card', 'subset', 'attribute', 'team', 'status', 'title',
  'variation', 'graded', 'grader', 'grade', 'cert', 'numeric_grade', 'purchase_price', 'note', 'cabinet', 'shelf',
  'box', 'row', 'section', 'sku', 'store_category', 'start_price', 'application', 'auction',
  'commission', 'conditions', 'category_id', 'category_id2', 'category_id3', 'category_id4', 'consignor_id', 'shipping_category_id', 'min_bid', 'weight',
  'lot_number', 'description'];

const batchFlagValues = {
  // red: 3
  // yellow: 2
  column_info: [
    'sport', 'year', 'brand', 'player', 'card', 'subset', 'attribute', 'team','variation', 'front_image_available',
    'back_image_available','graded', 'grader', 'grade', 'cert', 'numeric_grade', 'purchase_price', 'note', 'cabinet', 'shelf',
    'box', 'row', 'section', 'store_category', 'start_price', 'application', 'auction', 'commission', 'conditions', 'category_id', 'category_id2',
    'category_id3', 'category_id4', 'consignor_id', 'shipping_category_id', 'min_bid', 'weight', 'lot_number', 'title', 'description'],
  ebay:           [2,3,3,3,3,1,1,2,1,3,
                   1,3,3,3,1,3,1,1,0,0,
                   0,0,0,0,3,0,0,0,0,0,0,
                   0,0,0,0,0,0,0,3,3],
  shopify:        [2,3,3,3,3,1,1,2,1,3,
                   1,3,2,2,1,1,1,1,0,0,
                   0,0,0,0,3,0,0,0,0,0,0,
                   0,0,0,0,0,0,0,3,3],
  simple_auction: [2,3,3,3,3,1,1,2,1,3,
                   1,3,2,2,1,1,1,1,0,0,
                   0,0,0,0,3,1,1,1,1,1,1,
                   1,1,1,1,1,1,0,3,3],
  all:            [1,1,1,1,1,1,1,1,1,1,
                   1,3,1,1,1,1,1,1,1,1,
                   1,1,1,1,1,1,1,1,1,1,1,
                   1,1,1,1,1,1,1,1,1],
}

const DefaultData = {defaultEbayTemplate, defaultDescriptionTemplate, defaultTemplateFieldKeys, defaultTitleTemplate,
  defaultBatch, defaultCardMatchData, defaultCardDetail, defaultExportSettingOption,
  batchSpreadColumns, batchSpreadNumericColumns, batchFlagValues
};
export default DefaultData;