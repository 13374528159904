import React, { useState } from "react"
import { Col, Modal, Row, Button } from "react-bootstrap"
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import Avatar from "react-avatar-edit";

const AvatarModal = props => {
  const src = null;
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
  }

  const onCrop = preview => {
    setPreview(preview);
  }

  const onBeforeFileLoad = elem => {
    if(elem.target.files[0].size > 2097152){
      toastr.error('Fail', 'Maximum file size is 2M')
      elem.target.value = "";
    }
  }

  const uploadAvatar = ()=> {
    props.onSubmit(preview)
  }

  return(
    <Modal
      show={props.show} onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add your photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row horizontal>
          <Col md={{span: 8, offset: 2}} className="text-center">
            <Avatar
              width='100%'
              height={300}
              label="Choose your photo"
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
        <Button variant="success" onClick={uploadAvatar}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

AvatarModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AvatarModal
