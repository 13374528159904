import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import backImg from "../../assets/images/addNewCardImage.svg";

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
  height: '480px',
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function DropzoneComponent(props) {
  const {multi, inputFiles} = props;
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    if (multi) {
      props.onDrop(acceptedFiles);
    } else {
      props.onDrop(acceptedFiles[0]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  let thumbs = []
  if (files.length > 10) {
    thumbs = files.slice(0, 10).map(file => (
      <div key={file.name} style={{marginLeft: 'auto', marginRight: 'auto', zIndex: 3, color: 'black'}}>
        {file.name}
      </div>
    ));
    thumbs.push(<div key={'over-file'} style={{marginLeft: 'auto', marginRight: 'auto', zIndex: 3, color: 'red'}}>
      {files.length} Card Images Added
    </div>)
  } else {
    thumbs = files.map(file => (
      <div key={file.name}>
        {/*<img*/}
        {/*  src={file.preview}*/}
        {/*  alt={file.name}*/}
        {/*/>*/}
        {file.name}
      </div>
    ));
  }


  // clean up
  useEffect(() => {
    if (Array.isArray(inputFiles)) {
      const _files = inputFiles;
      _files.forEach(file => URL.revokeObjectURL(file.preview));
      setFiles(_files)
    } else {
      URL.revokeObjectURL(inputFiles.preview);
      let _files = [];
      _files.push(inputFiles);
      setFiles(_files);
    }
  }, [inputFiles]);

  return (
      <div {...getRootProps({style})}>
        <img src={backImg} style={{position: 'absolute', height: '50%', zIndex: 0}}/>
        <input {...getInputProps()} />
        {thumbs.length > 0 &&
        <div style={{margin:'auto', zIndex: 3, color: 'black'}}>
          {thumbs}
        </div>}
        {thumbs.length === 0 && <div style={{margin: 'auto', zIndex: 0, color: 'red'}}>Drag and drop your images here.</div>}
      </div>
  )
}

export default DropzoneComponent;
