import moment from 'moment';

const validateEmail = (email) => {
  // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = password => {
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return re.test(password)
}

const timestampFormatter = (cell, row) => {
  let date = moment(cell);
  return date.format('YYYY-MM-DD HH:mm');
};

const getFormatVal = (val) => {
  if (val === undefined || val === null) {
    return ''
  }
  return val;
}

const deepCloneObject = (val) => {
  return JSON.parse(JSON.stringify(val))
}

const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";

export {
  validateEmail,
  validatePassword,
  timestampFormatter,
  getFormatVal,
  deepCloneObject,
  isJsonBlob
}

