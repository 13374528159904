import React, {useState} from "react";

import closeIconBlack from "../../assets/images/closeIconBlack.svg"
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {logout} from "../../redux/actions/auth";
import {useDispatch} from "react-redux";

const CancelMembership = (props) => {
  const {handleHide} = props;
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();
  const [action, setAction] = useState(false);

  const [reason, setReason] = useState({
    'Too Expensive': false,
    'Matches Are Not Accurate': false,
    'Missing Features': false,
    'Issues / Errors': false,
    'Other': false,
  });
  const [comment, setComments] = useState('');

  const handleChangeCancelReason = (e) => {
    setReason({
      ...reason,
      [e.target.name]: !reason[e.target.name]
    })
  }

  const handleChangeCancelComments = (e) => {
    setComments(e.target.value);
  }

  const handleConfirm = () => {
    setConfirm(true);
  }

  const handleSubmit = () => {
    if (action) {
      return;
    }
    setAction(true)
    const reasonContents = Object.keys(reason).filter(key => reason[key] === true).join(',');
    ApiHelper.put(API_URL.CancelMembership, {}, {}, {
      reasons: reasonContents,
      comments: comment
    }).then(res=> {
      toastr.success('Success', res.data.message);
      setAction(false)
      handleHide();
      dispatch(logout());
      history.push('/')
    }).catch(err => {
      toastr.error('Fail', err.response.data.message);
      setAction(false)
    })
  }
  return (
    <div className="main-popup-container cancel-membership-popup">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Cancel Membership</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          {confirm === false &&
          <div>
            <p style={{marginBottom: '12px'}}>Please tell us why you would like to cancel so that we can
              improve:</p>
            <div className="checkbox-options-container">
              <div className="checkbox-option">
                <div className="checkbox">
                  <input type="checkbox"
                         name="Too Expensive"
                         checked={reason["Too Expensive"]}
                         onChange={handleChangeCancelReason}/>
                </div>
                <p>Too Expensive</p>
              </div>
              <div className="checkbox-option">
                <div className="checkbox">
                  <input type="checkbox"
                         name="Matches Are Not Accurate"
                         checked={reason["Matches Are Not Accurate"]}
                         onChange={handleChangeCancelReason}/>
                  <div className="checkbox-overlay"/>
                </div>
                <p>Matches Are Not Accurate</p>
              </div>
              <div className="checkbox-option">
                <div className="checkbox">
                  <input type="checkbox"
                         name="Missing Features"
                         checked={reason["Missing Features"]}
                         onChange={handleChangeCancelReason}/>
                  <div className="checkbox-overlay"/>
                </div>
                <p>Missing Features</p>
              </div>
              <div className="checkbox-option">
                <div className="checkbox">
                  <input type="checkbox"
                         name="Issues / Errors"
                         checked={reason["Issues / Errors"]}
                         onChange={handleChangeCancelReason}/>
                  <div className="checkbox-overlay"/>
                </div>
                Issues / Errors
              </div>
              <div className="checkbox-option">
                <div className="checkbox">
                  <input type="checkbox"
                         name="Other"
                         checked={reason["Other"]}
                         onChange={handleChangeCancelReason}/>
                  <div className="checkbox-overlay"/>
                </div>
                Other
              </div>
              <div className="checkbox-option">
                <textarea name="" id=""
                          placeholder="Please some comments about how we can improve our product"
                          value={comment}
                          onChange={handleChangeCancelComments}
                ></textarea>
              </div>
            </div>
            <div className="new-category-options-container">
              <div className="btn primary" onClick={handleConfirm}>Submit</div>
            </div>
          </div>}
          {confirm === true &&
            <div>
              <div className="cancel-membership-container">
                <p className="headline-style" style={{marginBottom: '12px'}}>Are you sure you want to cancel your
                  membership?</p>
                <p style={{lineHeight: '1.5'}}>You will no longer have access to use the Card Dealer Pro desktop or web
                  apps. Make sure to complete and export all batches before canceling.</p>
                <div className="buttons-container" style={{marginTop: '24px'}}>
                  <button className="btn secondary" style={{marginBottom: '12px', width: '100%'}} onClick={handleSubmit} disabled={action}>
                    {action ?
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner> : <span>Terminate My Subscription</span>}
                  </button>
                  <button className="btn primary" onClick={handleHide} disabled={action}>Keep My Account Active</button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CancelMembership;