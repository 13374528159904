import React, { useState, useEffect } from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import DefaultData from "../../consts/defaultData";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";

export const DataField = (props) => {
  const { handleHide, handleAddField } = props
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    ApiHelper.get(API_URL.GetCustomFields).then(res => {
      const fields = res.data.custom_fields.map(field => (field[0]));
      setCustomFields(fields);
    }).catch(e => {
      setCustomFields([]);
    });
    return () => {
      setCustomFields([]);
    }
  }, [])
  return (
    <div className={"settings-sliding-data-field"}>
      <div className="header">
        <h3>Add Data Fields</h3>
        <div className="close-sliding" onClick={handleHide}>
          <img src={closeIconBlack} alt=""/>
        </div>
      </div>
      <div className="content">
        {DefaultData.defaultTemplateFieldKeys.map((template_key) =>
          <div className="btn secondary data-field-btn" key={template_key} onClick={() => handleAddField(template_key)}>
            {template_key}
          </div>
        )}
        {customFields.map((template_key) =>
          <div className="btn secondary data-field-btn" key={template_key} onClick={() => handleAddField(template_key)}>
            {template_key}
          </div>
        )}
      </div>
    </div>
  )
}