import ApiHelper from "../../helpers/apiHelper";
import ACTION_TYPE from "../../consts/actions";
import API_URL from "../../consts/urls";

export const getProfile = (params) => dispatch => {
  dispatch({type: ACTION_TYPE.GET_PROFILE_REQUEST});
  return ApiHelper.get(API_URL.GetProfile, params).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_PROFILE_INFO,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.GET_PROFILE_FAILED});
  })
};

export const getUserMembership = (params) => dispatch => {
  dispatch({type: ACTION_TYPE.GET_PROFILE_REQUEST});
  return ApiHelper.get(API_URL.GetUserMembership, params).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_MEMBERSHIP_INFO,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.GET_PROFILE_FAILED});
  })
};

export const getDashboardMembership = () => dispatch => {
  dispatch({type: ACTION_TYPE.GET_PROFILE_REQUEST});
  return ApiHelper.get(API_URL.GetDashboardMembership, {}, {}, {}, false).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_MEMBERSHIP_CONTENT,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.GET_PROFILE_FAILED});
  })
};

export const getProfilePhoto = (params) => dispatch => {
  dispatch({type: ACTION_TYPE.GET_PROFILE_REQUEST});
  return ApiHelper.get(API_URL.GetProfilePhoto, params).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_PROFILE_PHOTO,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.GET_PROFILE_FAILED});
  })
};

export const getCategories = (params) => dispatch => {
  dispatch({type: ACTION_TYPE.GET_PROFILE_REQUEST});
  return ApiHelper.get(API_URL.GetCategory, params).then(res => {
    dispatch({
      type: ACTION_TYPE.GET_CATEGORIES,
      data: res.data
    });
  }).catch(err => {
    dispatch({type: ACTION_TYPE.GET_PROFILE_FAILED});
  })
};
