import React, {useEffect, useState, useRef} from "react";
import { useDispatch } from "react-redux";
import {useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";

import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Template from "../../components/Template";
import SKUSetting from "../../components/Settings/skuSetting";
import Banner from "../../components/Settings/banner";
import AccountInfo from "../../components/Settings/accountInfo";
import ExportSetting from "../../components/Settings/export";
import CustomFields from "../../components/Settings/customFields";
import DefaultData from "../../consts/defaultData";
import RouteLeavingGuard from "../../components/Popups/prompt";



const Settings = () => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const subSideBarMenu = [
    {id: "section_account_information", title: "Account Information"},
    {id: "section_banner", title: "Banner"},
    {id: "section_custom_fields", title: "Custom Fields"},
    {id: "section_sku", title: "SKU"},
    {id: "section_title_template", title: "Title Template"},
    {id: "section_description_template", title: "Description Template"},
    {id: "section_export_settings", title: "Export Settings"}
  ];
  const [subMenuId, setCurrentSubMenuId] = useState(subSideBarMenu[0].id);
  const [skuSetting, setSKUSetting] = useState({});
  const [accountInfoSetting, setAccountInfoSetting] = useState({});
  const [bannerSetting, setBannerSetting] = useState({});
  const [customFieldSetting, setCustomFieldSetting] = useState(null);
  const [titleTemplatePayload, setTitleTemplatePayload] = useState({
    defaultTemplate: null,
    templates: [],
    templateType: 'titles'
  });
  const [descriptionTemplatePayload, setDescriptionTemplatePayload] = useState({
    defaultTemplate: null,
    templates: [],
    templateType: 'descriptions'
  });
  const [exportSetting, setExportSetting] = useState(DefaultData.defaultExportSettingOption);
  const [skuDirty, setSkuDirty] = useState(false);
  const [accountInfoDirty, setAccountDirty] = useState(false);
  const [bannerDirty, setBannerDirty] = useState(false);
  const [customFieldDirty, setCustomFieldDirty] = useState(false);
  const [titlesDirty, setTitlesDirty] = useState(false);
  const [descriptionsDirty, setDescriptionsDirty] = useState(false);
  const [exportDirty, setExportDirty] = useState(false);
  const sectionRefs = {
    section_account_information: useRef(null),
    section_banner: useRef(null),
    section_custom_fields: useRef(null),
    section_sku: useRef(null),
    section_title_template: useRef(null),
    section_description_template: useRef(null),
    section_export_settings: useRef(null),
  };
  const navContentRef = useRef(null);
  const contentContainerRef = useRef(null);

  useEffect(() => {
    setShowExitPrompt(skuDirty || bannerDirty || customFieldDirty || titlesDirty || descriptionsDirty || exportDirty || accountInfoDirty);
  }, [skuDirty, bannerDirty, customFieldDirty, titlesDirty, descriptionsDirty, exportDirty, accountInfoDirty])

  const saveAccountInfoSetting = () => {
    return ApiHelper.put(API_URL.UpdateBusinessName, {}, {}, {
      business_name: accountInfoSetting.business_name
    }).then(res => {
      toastr.success('Success!', res.data.message);
      setAccountDirty(false);
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    })
  }
  const saveBannerSetting = () => {
    let _bannerOption = bannerSetting.isBannerUpload ? bannerSetting.bannerOption : "None";
    return ApiHelper.put(API_URL.UpdateBannerSetting, {}, {}, {
      banner:_bannerOption
    }).then(res => {
      toastr.success('Success!', res.data.message);
      setBannerDirty(false);
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    })
  }

  const saveSKUSetting = () => {
    return ApiHelper.put(API_URL.SKUSetting, {}, {}, skuSetting).then(res => {
      toastr.success('Success!', res.data.message);
      setSkuDirty(false);
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    })
  }

  const saveCustomFields = () => {
    const payload = {"custom_fields": {}}
    const key_array = customFieldSetting.map(c => c.name);
    if (new Set(key_array).size !== key_array.length) {
      // it has duplicate names;
      toastr.warning('Custom Field Key error!', 'Custom field keys should be unique values');
      return;
    }
    for (let i = 0; i < customFieldSetting.length; i ++) {
      payload.custom_fields[customFieldSetting[i].name] = customFieldSetting[i].value;
    }
    return ApiHelper.put(API_URL.UpdateCustomFields, {}, {}, payload).then(res => {
      toastr.success('Success!', res.data.message);
      setCustomFieldDirty(false);
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    })
  }
  const saveTemplates = (templatePayload) => {
    if (templatePayload.templates.length > 0) {
      const payload = {
        "template": {},
        "type": templatePayload.templateType,
        "default": templatePayload.defaultTemplate
      };
      templatePayload.templates.forEach(template => {
        payload.template[template.label] = template.value;
      })

      return ApiHelper.put(API_URL.TemplateSetting, {}, {}, payload).then(res => {
        toastr.success('Success!', res.data.message);
        if (templatePayload.templateType === 'titles') {
          setTitlesDirty(false);
        } else {
          setDescriptionsDirty(false);
        }
      }).catch(err => {
        toastr.error('Fail', err.response.data.message);
      }).finally(() => {
      })
    }
  }

  const saveExportSettings = () => {
    return ApiHelper.put(API_URL.ExportSetting, {}, {}, exportSetting).then(res => {
      toastr.success('Success!', res.data.message);
      setExportDirty(false);
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    })
  }
  const onSubSideSectionChange = (menu_id) => {
    setCurrentSubMenuId(menu_id)
    // scrollTo(sectionRefs[menu_id]);
  }

  const onClickSaveChanges = () => {
    if (saving) {
      return;
    }
    setSaving(true)
    let promises = [];
    if (accountInfoDirty) {
      promises.push(saveAccountInfoSetting());
    }
    if (bannerDirty) {
      promises.push(saveBannerSetting());
    }
    if (skuDirty) {
      promises.push(saveSKUSetting());
    }
    if (customFieldDirty) {
      promises.push(saveCustomFields());
    }
    if (titlesDirty) {
      promises.push(saveTemplates(titleTemplatePayload));
    }
    if (descriptionsDirty) {
      promises.push(saveTemplates(descriptionTemplatePayload));
    }
    if (exportDirty) {
      promises.push(saveExportSettings());
    }
    Promise.all(promises).then(res => {
    }).catch(() => {
    }).finally(() => {
      setSaving(false);
    });
  }

  const navigate = (path) => {
    history.push(path);
  }

  const getCurrentNavigatedSection = (scrollTop) => {
    // this is order
    const scrollPos = scrollTop + navContentRef.current.offsetTop;
    let minOffset = contentContainerRef.current.clientHeight;
    let minId = 'section_account_information';
    for (const k in sectionRefs) {
      const value = sectionRefs[k];
      if (Math.abs(value.current.offsetTop - scrollPos) < minOffset) {
        minId = k;
        minOffset = Math.abs(value.current.offsetTop - scrollPos);
      }
    }
    return minId;
  }
  const onScrollNavContent = (e) => {
    const navId = getCurrentNavigatedSection(e.target.scrollTop);
    if (navId !== subMenuId) {
      setCurrentSubMenuId(navId);
    }
  }
  return (
    <div className="settings">
      <RouteLeavingGuard
        when={showExitPrompt}
        // Navigate function
        navigate={navigate}
        shouldBlockNavigation={location => {
          if (showExitPrompt) {
            if (!location.pathname.startsWith('/settings')) {
              return true
            }
          }
          return false
        }}
      />
      <div className="content-header-main">
        <div className="column left">
          <h1>Settings</h1>
        </div>
        <div className="column right">
          <button className={'btn primary'} onClick={onClickSaveChanges}>Save changes</button>
        </div>
      </div>

      <div className="content-container" ref={contentContainerRef}>
        <div className="side-nav">
          {subSideBarMenu.map(menu => (
            <div className={"side-nav-route" + (menu.id === subMenuId ? " selected": "")} key={menu.id}>
              <a href={`#${menu.id}`} onClick={() => {onSubSideSectionChange(menu.id)}}>{menu.title}</a>
            </div>)
          )}
        </div>
        <div className="nav-content" ref={navContentRef} onScroll={(e) => onScrollNavContent(e)}>
          <div className="settings-container account-info" id={"section_account_information"} ref={sectionRefs.section_account_information}>
            <AccountInfo updateSetting={setAccountInfoSetting} setDirty={setAccountDirty}/>
          </div>
          <div className="settings-container" id={"section_banner"} ref={sectionRefs.section_banner}>
            <Banner updateSetting={setBannerSetting} setDirty={setBannerDirty}></Banner>
          </div>
          <div className="settings-container custom-fields" id={"section_custom_fields"} ref={sectionRefs.section_custom_fields}>
            <CustomFields updateSetting={setCustomFieldSetting} setDirty={setCustomFieldDirty}/>
          </div>
          <div className="settings-container" id={"section_sku"} ref={sectionRefs.section_sku}>
            <h2>SKU</h2>
            <SKUSetting updateSetting={setSKUSetting} setDirty={setSkuDirty}></SKUSetting>
          </div>
          <div className="settings-container" id={"section_title_template"} ref={sectionRefs.section_title_template}>
            <Template templateType={'titles'} updateTemplates={setTitleTemplatePayload} setDirty={setTitlesDirty}/>
          </div>
          <div className="settings-container" id={"section_description_template"} ref={sectionRefs.section_description_template}>
            <Template templateType={'descriptions'} updateTemplates={setDescriptionTemplatePayload} setDirty={setDescriptionsDirty}/>
          </div>
          <div className={"settings-container export-settings"} id={"section_export_settings"} ref={sectionRefs.section_export_settings}>
            <ExportSetting id={"section_export_settings"} updateSetting={setExportSetting} setDirty={setExportDirty}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings;