import React, {useState} from "react";
import {Row, Col} from "react-bootstrap";
import ApiHelper from "../../helpers/apiHelper";
import {toastr} from "react-redux-toastr";
import API_URL from "../../consts/urls";

export default function ContactUs() {
  const [contactInfo, setContactInfo] = useState({
    name: '',
    phone_number: '',
    userName: '',
    email: '',
    reason: '',
    content: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({phone: '', email: ''});
  
  
  const onBlurPhone = (e) => {
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (!pattern.test(e.target.value)) {
      setErrors({
        ...errors,
        phone: "Please enter only number."
      });
    } else {
      setErrors({
        ...errors,
        phone: ""
      });
    }
  };
  
  const onBlurEmail = (e) => {
    if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value))) {
      // toastr.error('Error', 'Please input correct email');
      errors["email"] = "Please enter your correct email.";
      setErrors({
        ...errors,
        email: "Please enter your correct email."
      });
    } else {
      setErrors({
        ...errors,
        email: ""
      });
    }
  };
  
  const onChangeContactInfo = (e) => {
    let newContactInfo = {...contactInfo};
    newContactInfo[e.target.name] = e.target.value;
    setContactInfo(newContactInfo);
  }
  
  const onSendInfo = () => {
    let formData = new FormData();
    for (let key in contactInfo ) {
      formData.append(key, contactInfo[key]);
    }
    
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    setLoading(true);
    ApiHelper.post(API_URL.Contact, {}, config, formData)
      .then(res => {
        toastr.success('Success', 'Your information is sent successfully');
        setLoading(false);
      }).catch(err => {
      toastr.error('Error', err.response.data.message);
      setLoading(false);
    });
  };
  return (
    <div className="contact-us content-container">
      <div className="content_header">
        <h4>Contact & Support</h4>
      </div>
      <Row>
        <Col md={12}>
          <div className="main_contact_us">
            <div className="contact_p">
              <p>CardDealerPro.com takes great pride in providing you with the best support possible. To get in
                touch with us or to open a support request, please fill out the contact form below and an appropriate
                member of our staff will be in contact with you within 1 business day.</p>
            </div>
            <Row>
              <Col md={6}>
                <input style={{marginBottom: '1rem'}} className="form-control" placeholder="Name" name="name"
                       type="text" onChange={onChangeContactInfo} value={contactInfo.name}/>
              </Col>
              <Col md={6}>
                <input style={{marginBottom: '1rem'}} className="form-control" placeholder="Phone" name="phone_number"
                       type="text" onChange={onChangeContactInfo} onBlur={onBlurPhone} value={contactInfo.phone_number} />
                <div className="text-danger">{errors.phone}</div>
              </Col>
              <Col md={6}>
                <input style={{marginBottom: '1rem'}} className="form-control" placeholder="Email" name="email"
                       type="text" onBlur={onBlurEmail} onChange={onChangeContactInfo} value={contactInfo.email} />
                <div className="text-danger">{errors.email}</div>
              </Col>
              <Col md={6}>
                <select style={{marginBottom: '1rem'}} className="form-control" placeholder="Reason for Contact"
                        name="reason" onChange={onChangeContactInfo} value={contactInfo.reason}>
                  <option value={'Support'}>Support</option>
                  <option value={'General'}>General</option>
                  <option value={'Feature Request'}>Feature Request</option>
                  <option value={'Feedback'}>Feedback</option>
                  <option value={'Sales'}>Sales</option>
                  <option value={'Partnership'}>Partnership</option>
                </select>
              </Col>
              <Col md={12}>
                <textarea className="form-control" rows={5} placeholder="Your Message" name="content"
                          value={contactInfo.content} onChange={onChangeContactInfo} />
              </Col>
            </Row>
            <div className="custom_btn_main text-center contact_btn">
              <button className="btn primary" onClick={onSendInfo}>
                {loading ?
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{marginRight: "5px"}}
                  />: 'Send'}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
