import React, {useEffect, useState} from "react";
import checkGreen from "../../assets/images/checkGreen.svg";
import closeIconRed from "../../assets/images/closeIconRed.svg"
import SCANNER_INFORMATION from "../../consts/scannerInformation";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import { toastr } from "react-redux-toastr";
import {validateEmail} from "../../helpers/commonHelper";

const Scanners = (props) => {
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
    reason: "Scanner",
    content: ""
  });
  const [errorText, setErrorText] = useState('');
  useEffect(() => {
    if (errorText !== '') {
      toastr.error('Warning', errorText);
    }
  }, [errorText]);
  
  const updateContactInfo = (e) => {
    setErrorText('');
    let newInfo = {...contactInfo};
    newInfo[e.target.name] = e.target.value;
    setContactInfo(newInfo);
  }
  
  const handleContact = () => {
    if (contactInfo.name === '') {
      setErrorText('Name is required');
      return;
    }
  
    if (!validateEmail(contactInfo.email)) {
      setErrorText('Email is invalid');
      return;
    }
  
    if (contactInfo.content === '') {
      setErrorText('Content is required');
      return;
    }
    
    let contactFormData = new FormData();
    for (let key in contactInfo) {
      contactFormData.append(key, contactInfo[key]);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    ApiHelper.post(API_URL.Contact, {}, config, contactFormData).then(res => {
      toastr.success('Success', res.data.message);
    })
  }
  return (
    <div className={"scanners"}>
      <div className="content-header-main">
        <div className="column left">
          <h1>Scanners</h1>
        </div>
      </div>
      <div className="content-container scanners">
        <div className="supported-scanners-container">
          <h2>Supported Scanners</h2>
          <p className="para1">Card Dealer Pro supports a wide variety of scanners to fit any dealers needs and budget.
            The below list of scanners have all been tested and verified to work with the Card Dealer Pro desktop
            software (both Windows and Mac).</p>
          <div className="scanner-tiles-container">
            {
              SCANNER_INFORMATION.map(scanner_info => {
                return (
                  <div className="scanner-tile" key={scanner_info.name} >
                    <div className="scanner-tile-main-info">
                      <img src={scanner_info.img} alt="" />
                      <div>
                        <a href={scanner_info.link} rel="noreferrer" target="_blank">{scanner_info.name}</a>
                        <div className="scanner-type-price">
                          <p className="scanner-type">{scanner_info.type}</p>
                          <p className="scanner-price">${scanner_info.price}</p>
                        </div>
                      </div>
                    </div>
                    <div className="scanner-tile-features">
                      <div>
                        <p className="cards-per-hour-num">{scanner_info.cards_per_hour}&nbsp;</p>
                        Cards / hour
                      </div>
                      <div>
                        {scanner_info.loose ? <img src={checkGreen} alt="" /> : <img src={closeIconRed} alt="" />}
                        Loose
                      </div>
                      <div>
                        {scanner_info.card_saver ? <img src={checkGreen} alt="" /> : <img src={closeIconRed} alt="" />}
                        Card Saver
                      </div>
                      <div>
                        {scanner_info.top_loader ? <img src={checkGreen} alt="" /> : <img src={closeIconRed} alt="" />}
                        Top Loader
                      </div>
                      <div>
                        {scanner_info.slabs ? <img src={checkGreen} alt="" /> : <img src={closeIconRed} alt="" />}
                        Slabs
                      </div>
                      <div>
                        {scanner_info.penny ? <img src={checkGreen} alt="" /> : <img src={closeIconRed} alt="" />}
                        Penny
                      </div>
    
                    </div>
                  </div>
                )
              })
            }
            
          </div>
          <p className="further-notes">Other flatbed scanners outside of this list should also work, however we cannot
            test all scanners, and we highly recommend using a scanner from the approved list. It is doubtful that any
            other auto-feed scanners that are not on this list would be compatible with our software. If you have any
            questions or would like help choosing the right scanner for you and your card scanning needs, please email
            us, we’d be happy to help! </p>
        </div>
        <div className="new-scanner-help">
          <h2>Buying A New Scanner?</h2>
          <p className="para2">Card Dealer Pro has a partnership with Fujitsu and Amazon to bring you the best deals on
            our supported scanners. Purchasing your new scanners through us helps to support our software and our
            partnership with Fujitsu so that we can continue to develop new card scanning technology to help streamline
            your card shop operations.</p>
          <div>
            <p className="para3">For help purchasing a scanner, please fill out the below form and we will contact you
              and help get you setup with the perfect scanner for your business. </p>
            <div>
              <div className="main-form-container">
                <div className="row1">
                  <div className="">
                    <label htmlFor="">Name</label>
                    <input type="text" name={"name"} value={contactInfo.name} onChange={updateContactInfo}/>
                  </div>
                  <div className="">
                    <label htmlFor="">Email</label>
                    <input type="text" name={"email"} value={contactInfo.email} onChange={updateContactInfo}/>
                  </div>
                  <div className="">
                    <label htmlFor="">Phone</label>
                    <input type="text" name={"phone_number"} value={contactInfo.phone_number} onChange={updateContactInfo}/>
                  </div>
                </div>
                <div className="row2">
                  <div className="">
                    <label htmlFor="">Message</label>
                    <textarea name="content" id="" value={contactInfo.content} onChange={updateContactInfo}/>
                  </div>
                </div>
              </div>
              <button className="btn primary float-right" onClick={handleContact}>submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scanners;