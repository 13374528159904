import React from "react";

import ashlyFred from "../../assets/images/AShley-Fred.png"
import {useHistory} from "react-router-dom";

const About = () => {
  const history = useHistory();
  const handleFeedback = () => {
    history.push('/contact');
  }
  return (
    <div className="content-container about-us">
      <section>
        <div className="aboutus-image-container">
          <img src={ashlyFred} alt="" />
        </div>
        <div className="aboutus-info-container">
          <h1>About Card Dealer Pro</h1>
          <h2>Built By Card Dealers, For Card Dealers</h2>
          <p>
            Card Dealer Pro is a suite of software tools developed by Sports Card Scanner LLC (www.sportscardscanner.com). Founded by Ashley and Frederick Barnes, Sports Card Scanner was originally intended to be a service that provided tools to both dealers and collectors. We soon realized that the tools would be more powerful if we separated the needs of dealers from those of collectors, so the decision was made that Sports Card Scanner would focus on collectors, and a new solution was developed specifically for dealers… and Card Dealer Pro was born.
          </p>
          <p>
            At the heart of Card Dealer Pro is what we call the “Matching Engine”. The matching engine is the world’s first (and best) AI/Machine Learning engine that can take an image of a sports card and match it to a database. This allows users to submit card images, and the Matching Engine returns all the card’s information such as Year, Set, Card #, Player, Team, Subset/Parallel and attributes like Rookie, Auto, HOF...etc. If the card is graded, it identifies the grading company, the grade, and the cert number. We combine the power of the matching engine along with other tools such as automatic cropping, alignment, banner branding, image optimization, and historic price information to enable you to list THOUSANDS of cards per hour.
          </p>
          <p>
            The Card Dealer Pro team are not just software developers, we are sports card dealers as well. With annual card sales of over $2 Million Dollars between eBay, Amazon, Social, and our own website, we use all of our tools and we apply what we have learned over 2 decades of buying and selling cards to enable you to be as efficient and as profitable as possible with your cards and collectibles business. We have a lot of ideas and a ton of features we look forward to developing soon, and we are excited to share those with you as they develop. Check our news section in your dashboard for updates.
          </p>
        </div>
      </section>
      
      <section>
        <h2>Our belief</h2>
        <p>
          As active collectors, buyers, and sellers of vintage and modern cards, we have identified a lot of areas that technology could help us personally. Our belief is that if we found value in these tools, others in the hobby would as well. We thrive on feedback and would LOVE to hear any ideas on what to build and how we can improve. Please do not hesitate to share your experiences with our products and ideas on how we can get better.
        </p>
        <div className="btn secondary whitebg" onClick={handleFeedback}>
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5001 3.33317V9.1665H4.30841L3.81675 9.65817L3.33341 10.1415V3.33317H12.5001ZM13.3334 1.6665H2.50008C2.04175 1.6665 1.66675 2.0415 1.66675 2.49984V14.1665L5.00008 10.8332H13.3334C13.7917 10.8332 14.1667 10.4582 14.1667 9.99984V2.49984C14.1667 2.0415 13.7917 1.6665 13.3334 1.6665ZM17.5001 4.99984H15.8334V12.4998H5.00008V14.1665C5.00008 14.6248 5.37508 14.9998 5.83342 14.9998H15.0001L18.3334 18.3332V5.83317C18.3334 5.37484 17.9584 4.99984 17.5001 4.99984Z"/>
          </svg>
          Provide feedback
        </div>
      </section>
    </div>
  )
}

export default About;
