import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router";
import {toastr} from "react-redux-toastr";

import {ChangeMembershipModal} from "./ChangeMembershipModal";
import API_URL from "../../../consts/urls";
import ApiHelper from "../../../helpers/apiHelper";
import {useDispatch, useSelector} from "react-redux";
import {SetAdminModal} from "./SetAdminModal";
import {AlertYesNoModal} from "../../../components/Popups/alert";
import AddScanModal from "./AddScan";
import {loginByUserId} from "../../../redux/actions/auth";

const UserDetailView = () => {
  const {id} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const admin_role = useSelector(state => state.auth.admin_role)
  const [userInfo, setUserInfo] = useState(null);
  const [profile, setProfile] = useState(null);
  const [errorText, setErrorText] = useState({
    first_name: '',
    last_name: '',
    business_name: ''
  });
  const [changeMembershipModal, setChangeMembershipModal] = useState(false);
  const [adminSettingModal, setAdminSettingModal] = useState(false);
  const [addScanModal, setAddScanModal] = useState(false);
  const [suspendUser, setSuspendUser] = useState(false);
  const [activateUser, setActivateUser] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  
  useEffect(() => {
    ApiHelper.get(API_URL.GetUserDetail, {
      user_id: id
    }).then(res => {
      setUserInfo(res.data);
      setProfile(res.data.profile);
    }).catch(err => {
    
    })
  }, []);
  
  const handleAction = () => {
    ApiHelper.get(API_URL.GetUserDetail, {
      user_id: id
    }).then(res => {
      setUserInfo(res.data);
    }).catch(err => {
    });
  }
  
  const handleChangeInput = (e) => {
    if (e.target.value !== '') {
      setErrorText({...errorText, [e.target.name]: ''})
    }
    if (e.target.name === 'user_name' || e.target.name === 'email' || e.target.name === 'first_name' ||
      e.target.name === 'last_name' || e.target.name === 'business_name') {
      setProfile({...profile, [e.target.name]: e.target.value})
    }
  }
  
  const handleMembership = () => {
    setChangeMembershipModal(true);
  }
  const handleHideMembership = () => {
    setChangeMembershipModal(false);
  }
  
  const handleAdminSetting = () => {
    setAdminSettingModal(true)
  }
  
  const handleHideAdminSetting = () => {
    setAdminSettingModal(false)
  }
  
  const handleResetPassword = () => {
    ApiHelper.put(API_URL.SetDefaultPassword, {}, {}, {
      user_id: id
    }).then(res => {
      toastr.success("Success!", "The password for this user is set as default - 123456789.");
      setResetPassword(false);
    }).catch(err => {
      toastr.error("Fail!", "Please try again.");
      setResetPassword(false);
    })
  }
  const handleHideResetPassword = () => {
    setResetPassword(false)
  }
  
  const handleSuspendUser = () => {
    ApiHelper.put(API_URL.SetUserSuspend, {}, {}, {
      user_id: id
    }).then(res => {
      toastr.success("Success!", "This user was suspended successfully.");
      setSuspendUser(false);
    }).catch(err => {
      toastr.error("Fail!", "Please try again.");
      setSuspendUser(false);
    })
  }
  
  const handleShowSuspendUserModal = () => {
    setSuspendUser(true)
  }
  
  const handleHideSuspendUserModal = () => {
    setSuspendUser(false)
  }
  
  
  const handleActivateUser = () => {
    ApiHelper.put(API_URL.ActivateUser, {}, {}, {
      user_id: id
    }).then(res => {
      toastr.success("Success!", "This user is activated successfully.");
      setActivateUser(false);
    }).catch(err => {
      toastr.error("Fail!", "Please try again.");
      setActivateUser(false);
    })
  }
  
  
  const handleHideActiveUserModal = () => {
    setActivateUser(false)
  }
  
  const handleSave = e => {
    e.preventDefault();
    
    ApiHelper.put(API_URL.UpdateUserProfile, {}, {}, {
      user_id: id,
      user_name: profile?.user_name,
      email: profile?.email,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      business_name:  profile?.business_name,
    }, {}, true).then(res => {
      toastr.success('Success!', 'User was successfully updated.');
    }).catch(err => {
      toastr.error('Fail!', 'Please try again to update.');
    });
  };
  
  const loginAsUser = () => {
    dispatch(loginByUserId(id)).then(res => {
      history.push('/dashboard');
    }).catch(err => {
    })
  }
  if (userInfo && profile) {
    return (
      <div className="usermanagement-detail">
        {changeMembershipModal &&
        <ChangeMembershipModal
          id={id}
          membershipID={userInfo?.stats?.membership}
          handleHide={handleHideMembership}
          handleAction={handleAction}
        />
        }
        {adminSettingModal &&
        <SetAdminModal
          id={id}
          handleHide={handleHideAdminSetting}
          handleAction={handleAction}
        />
        }
        {suspendUser &&
        <AlertYesNoModal
          handleAction={handleSuspendUser}
          handleHide={handleHideSuspendUserModal}
          modalType="suspend_user"
        />
        }
        {activateUser &&
        <AlertYesNoModal
          handleAction={handleActivateUser}
          modalType={'activate_user'}
          handleHide={handleHideActiveUserModal}
        />
        }
        {resetPassword &&
        <AlertYesNoModal
          handleAction={handleResetPassword}
          modalType={'reset_password'}
          handleHide={handleHideResetPassword}
        />
        }
        {addScanModal &&
        <AddScanModal
          id={id}
          handleHide={() => setAddScanModal(false)}
          handleAction={handleAction}
        />
        }
        <div className="content-container">
          <div className="content_header">
            <h4>Profile</h4>
          </div>
          <div className="content-container-row1">
            <div className="add-new-card profile_main">
              <div className="main_profile_sec">
                <div className="row mr-0">
                  <div className="col-md-6 profile-input">
                    <label htmlFor="">Username<span className="star">*</span></label>
                    <input placeholder="Username" name="user_name" type="text"
                           value={profile?.user_name} onChange={handleChangeInput}/>
                  </div>
                  <div className="col-md-6 profile-input">
                    <label>Email<span className="star">*</span></label>
                    <input placeholder="Email" name="email" type="text"
                           value={profile?.email} onChange={handleChangeInput}/>
                  </div>
                  <div className="col-md-6 profile-input">
                    <label>First Name<span className="star">*</span></label>
                    <input placeholder="Full name" name="first_name" type="text"
                           value={profile?.first_name} onChange={handleChangeInput}/>
                  </div>
                  <div className="col-md-6 profile-input">
                    <label>Last Name<span className="star">*</span></label>
                    <input placeholder="Last Name" name="last_name" type="text"
                           value={profile?.last_name} onChange={handleChangeInput}/>
                  </div>
                  <div className="col-md-6 profile-input">
                    <label>Business Name<span className="star">*</span></label>
                    <input placeholder="Business name" name="business_name" type="text"
                           value={profile?.business_name} onChange={handleChangeInput}/>
                  </div>
                </div>
              </div>
              <div className="main_profile_sec">
                <div className="row mr-0">
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      <button type="button" className="no_bg_button" onClick={handleMembership}>Membership</button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      {admin_role === 3 ?
                        <button type="button" className="no_bg_button" onClick={handleAdminSetting}>
                          Login as Admin
                        </button>
                        : <button type="button" className="no_bg_button" disabled={true}>Login as Admin</button>
                      }
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      <button type="button" className="no_bg_button" onClick={handleShowSuspendUserModal}>Suspend User</button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      <button type="button" className={"no_bg_button"} style={profile?.activate_status ?
                        {cursor: 'unset', backgroundColor: 'grey', color: 'white', border: 'grey'} : {}}
                              onClick={() => setActivateUser(!profile?.activate_status)}
                      >
                        Activate User
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      <button type="button" className="no_bg_button"
                              onClick={() => setResetPassword(true)}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="admin_p_btn_nobg">
                      <button type="button" className="no_bg_button"
                              onClick={() => setAddScanModal(true)}
                      >
                        Buy Scan
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="admin_p_fillbtn">
                      <button type="button" className="fill_btn" onClick={handleSave}>
                        Update Settings
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="admin_p_btn_nobg main_bottom_page">
                      <button type="button" className="fill_btn" onClick={loginAsUser}>
                        Login as this user
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-card stats">
              <div className="header">
                <h2>Stats</h2>
              </div>
              <div className="separator" />
              <div className="body">
                <div className="stat-item">
                  <p>Lifetime Scans</p>
                  <span>{userInfo?.stats.lifetime_scans}</span>
                </div>
                <div className="separator" />
                <div className="stat-item">
                  <p>Total Cards</p>
                  <span>{userInfo?.stats.limit_scan}</span>
                </div>
                <div className="separator" />
                <div className="stat-item">
                  <p>Open Batch</p>
                  <span>{userInfo?.stats.open_batch}</span>
                </div>
                <div className="stat-item">
                  <p>Total Batch</p>
                  <span>{userInfo?.stats.total_batch}</span>
                </div>
                <div className="separator" />
                <div className="stat-item">
                  <p>Saved Time</p>
                  <span>{userInfo?.stats.saved_time}</span>
                </div>
                <div className="separator" />
                <div className="stat-item">
                  <p>Member Since</p>
                  <span>{userInfo?.stats.membership_since}</span>
                </div>
                <div className="separator" />
                <div className="stat-item">
                  <p>Membership</p>
                  <span>{userInfo?.stats.membership}</span>
                </div>
                <div className="separator" />
                <div className="stat-item scans-left">
                  <div className="scans-this-month">
                    <p>Scans This Month</p>
                    <span>{userInfo?.stats.scans} / {userInfo?.stats.limit_scans}</span>
                  </div>
                  <div className="bar">
                    <div className="loaded" style={{width: `${userInfo?.stats.scans / userInfo?.stats.limit_scans*100}%`}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default UserDetailView;