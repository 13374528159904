import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import {Nav, NavItem, NavLink, Row, Spinner} from "react-bootstrap";
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import searchIconRed from "../../assets/images/searchRed28.svg";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";

const SearchCards = (props) => {
  const {batchId, handleHide, handleAction} = props;
  const [sport, setSport] = useState('Baseball');
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams, setSearchParams] = useState({
    Year: '',
    Card: '',
    CardSet: '',
    Player:''});
  const [searching, setSearching] = useState(false);

  const onChangeSearchParams = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    })
  }
  const getSportsItem = e => {
    setSport(e.target.id)
  }
  
  const search = (query, sport) => {
    let filteredQuery = Object.values(query).filter(el => {
      return el !== '';
    })

    if (filteredQuery.length >= 2) {
      setSearching(true);
      ApiHelper.get(API_URL.SearchAPI, {
        sport: sport,
        input_data: JSON.stringify(query)
      }).then(res => {
        setSearchResults(res.data);
      }).catch(err => {
        setErrorMessage('No Results!')
      }).finally(() => {
        setSearching(false);
      })
    } else {
      toastr.info('Query must include at least 2 fields');
    }
  };
  
  const handleCard = (cardData) => {
    //0 : title
    //1: sport
    //2: year
    //3: card_id
    handleAction({
      title: cardData[0],
      sport: cardData[1],
      year: cardData[2],
      uid: cardData[3]
    });
  }

  const onSearch = () => {
    search(searchParams, sport);
  }
  return (
    <div className="main-popup-container search_modal-content">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Search Manually</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          <div className="container search-container">
            <div className={'nav-search'}>
              <Nav variant="tabs" className="nav-tabs2" defaultActiveKey="Baseball">
                <NavItem>
                  <NavLink eventKey="Baseball" id={"BaseBall"} onClick={getSportsItem} >
                    Baseball
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="Basketball" id="Basketball" onClick={getSportsItem}>
                    Basketball
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="Football" id="Football" onClick={getSportsItem}>
                    Football
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="Hockey" id="Hockey" onClick={getSportsItem}>
                    Hockey
                  </NavLink>
                </NavItem>
              </Nav>
              <div className={'search-img'}>
              {searching ?
                <div className='component-loading-overlay'>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>:
                <img src={searchIconRed} onClick={onSearch}/>
              }
              </div>
            </div>
            <div className="tab-content tab-content2">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Year</label>
                    <input name="Year" type="text" value={searchParams.Year} onChange={onChangeSearchParams}/>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Card Number</label>
                    <input name="Card" type="text" value={searchParams.Card} onChange={onChangeSearchParams}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group" style={{width: 'fit-content', marginLeft: 'auto'}}>
                    <label className="form-label">Set</label>
                    <input name="CardSet" type="text" value={searchParams.CardSet} onChange={onChangeSearchParams}/>
                  </div>
                  <div className="form-group" style={{width: 'fit-content', marginLeft: 'auto'}}>
                    <label className="form-label">Player</label>
                    <input name="Player" type="text" value={searchParams.Player} onChange={onChangeSearchParams}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="listing_links">
              {searchResults['result'] ?
                (searchResults['result'].map((searchResult, key) => (
                  <a
                    key={key}
                    style={{cursor: "pointer"}}
                    onClick={() => handleCard(searchResult)}
                  >
                    {searchResult[0]}<br />
                  </a>
                ))) : <h3 className="text-warning text-center">{errorMessage}</h3>}
            </div>
          </div>
        </div>
      </div>
    </div>
)
}

export default SearchCards;