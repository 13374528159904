import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import BasicChart from "../../../components/Chart";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";

const UserManagement = () => {
  const [stats, setStats] = useState(null);
  const [users, setUsers] = useState([]);
  const [pastDays, setPastDays] = useState(1);
  const [pastDaysData, setPastDaysData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [searchKeyword, setSearchKeyword] = useState('');
  
  const handleItemsChange = e => {
    setItemsPerPage(parseInt(e.target.value));
  };
  const handleDays = e => {
    setPastDays(parseInt(e.target.value));
  }
  
  const getUserManagement = (page, page_num) => {
    const params = {
      page: (page === -1) ? 'all' : page,
      page_num: page_num
    };
    
    ApiHelper.get(API_URL.GetUserStats, params, {}).then(res => {
      setStats(res.data.stats);
      setUsers(res.data.all_users);
    })
  }
  
  const getUsers = () => {
    ApiHelper.get(API_URL.GetUsersinXdays, {
      x_days: pastDays
    }).then(res => {
      setPastDaysData(res.data);
    })
  }
  
  const searchUsers = () => {
    ApiHelper.get(API_URL.SearchUser, {
      keyword: searchKeyword
    }).then(res => {
      setUsers(res.data.result);
    })
  }
  
  const onChangeKeyword = (e) => {
    setSearchKeyword(e.target.value)
  }
  
  useEffect(() => {
    getUserManagement(itemsPerPage, activePage);
  }, [itemsPerPage, activePage]);
  
  if (!stats) {
    return <></>
  }
  return (
    <div className="admin-dashboard">
      <div className="content-container">
        <div className="main_contebt_admin">
          <div className="second_heading_main">
            <h3>Count of all users</h3>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="main_chart">
                <BasicChart
                  xVal={['Prospect', 'Minors', 'Rookie', 'All Star' , 'MVP' , 'HOF']}
                  yVal={[
                    stats?.prospect_users,
                    stats?.minors_users,
                    stats?.rookie_users,
                    stats?.all_star_users,
                    stats?.mvp_users,
                    stats?.hall_of_frame_users
                  ]}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="right_admin_state">
                <div className="stat_admin_box">
                  <div className="haed_stat_b">
                    <h4>Stats</h4>
                  </div>
                  <div className="data_admin_b">
                    <div className="admin_b_list">
                      <p>Total</p>
                      <h5>{stats?.total}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Free</p>
                      <h5>{stats?.free}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Paid</p>
                      <h5>{stats?.paid}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Active Users in Past 30 Days</p>
                      <h5>{stats?.past_30_days}</h5>
                    </div>
                  </div>
                  {/*<div className="b_all_btn">*/}
                  {/*  <button type="button">Show All</button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_contebt_admin active_statusadmin">
          <div className="second_heading_main">
            <h3>Active within past Days</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="main_chart red_main_charts">
                <div className="topcharts">
                  <div className="chart_date chart_search">
                    <select onChange={handleDays} value={pastDays}>
                      <option value={1}>&nbsp;&nbsp;1 DAY&nbsp;&nbsp;</option>
                      <option value={7}>&nbsp;&nbsp;1 WEEK&nbsp;&nbsp;</option>
                      <option value={30}>&nbsp;&nbsp;30 DAYS&nbsp;&nbsp;</option>
                      <option value={365}>&nbsp;&nbsp;1 YEAR&nbsp;&nbsp;</option>
                    </select>
                    <button type="button" className="search_btn" onClick={getUsers}>
                      <i className='bx bx-search-alt-2' />
                    </button>
                  </div>
                </div>
                <div className="main_users_active">
                  <div className="user_data_list">
                    <h4 className="user_name_total">{pastDaysData ? pastDaysData.users : "0"} <span> Users </span></h4>
                    <p> In Last {pastDays} Days </p>
                  </div>
                  <i className='bx bxs-user-detail' />
                </div>
          
                <div className="value_graph">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bulk_table db_two_main">
          <div className="table_head">
            <h4>All Users ({stats?.total})</h4>
            <div className="main_select">
              <div className="custom_table_search_btn">
                <input type="text" name="" placeholder="Search By Name, Email or Username"
                       value={searchKeyword} onChange={onChangeKeyword}/>
                <button type="button" className="search_btn_tbl" onClick={searchUsers}>
                  <i className='bx bx-search-alt-2' /> Search
                </button>
              </div>
            </div>
          </div>
          <div className="table_main">
            <table className="table table-striped bulk_table_main">
              <thead>
              <tr>
                <th scope="col">SR#</th>
                <th scope="col">Name</th>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">Activated</th>
                <th scope="col">Membership</th>
                <th scope="col">ACTION</th>
              </tr>
              </thead>
              <tbody>
              {
                users.map((user, key) => (
                  <tr key={key}>
                    <td>{user.id}</td>
                    <td>
                      <p>{`${user.first_name} ${user.last_name}`}</p>
                    </td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user?.email_confirmed ? 'Yes' : 'No'}</td>
                    <td>{user.membership}</td>
                    <td>
                      <div className="action_icon">
                        <Link to={`/admin/user_management/detail/${user.id}`}>
                          <i className='bx bx-show-alt' />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
            <div className="table_footer">
              <div className="entries">
                {itemsPerPage === -1 &&
                <p>Showing all entries</p>
                }
                {itemsPerPage !== -1 && activePage * itemsPerPage < stats?.total &&
                <p>{(activePage - 1) * itemsPerPage + 1} to {activePage * itemsPerPage} of {stats?.total} entries</p>
                }
                {itemsPerPage !== -1 && activePage * itemsPerPage >= stats?.total &&
                <p>{(activePage - 1) * itemsPerPage + 1} to {users.length} of {stats?.total} entries</p>
                }
              </div>
              <div className="entries_select">
                <p>Show</p>
                <select onChange={handleItemsChange} value={itemsPerPage}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={-1}>All</option>
                </select>
                <p>Entries</p>
              </div>
              <div className="pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  prevPageText={
                    <span><i className="fa fa-angle-left"/></span>
                  }
                  nextPageText={
                    <span><i className="fa fa-angle-right"/></span>
                  }
                  totalItemsCount={stats?.total}
                  pageRangeDisplayed={3}
                  onChange={page => setActivePage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserManagement;