import React, {useState} from "react";

import closeIconBlack from "../../assets/images/closeIconBlack.svg"
import searchImage from "../../assets/images/searchRed28.svg"
import uploadImage from "../../assets/images/uploadRed28.svg"
import SearchCards from "./searchCards";
import UploadCards from "./uploadCards";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import {setBatchReload} from "../../redux/actions/batches";
import {useDispatch} from "react-redux";
import SelectUploadCards from "./selectUploadCards";


const AddCards = (props) => {
  const [showSearchImage, setShowSearchImage] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSearchImage = () => {
    setShowSearchImage(true);
    setShowUploadImage(false);
  }
  const handleHideSearchImage = () => {
    setShowSearchImage(false);
    setShowUploadImage(false);
  }
  
  const handleUploadImage = () => {
    setShowSearchImage(false);
    setShowUploadImage(true);
  }
  const handleHideUploadImage = () => {
    setShowSearchImage(false);
    setShowUploadImage(false);
  }
  
  const handleAddSearchesToBatch = (cardData) => {
    if (loading) {
      return;
    }
    setLoading(true)
    ApiHelper.post(API_URL.SearchAdd, {}, {}, {
      batch: props.batchId,
      sport: cardData.sport,
      year: cardData.year,
      uid: cardData.uid
    }).then(res => {
      dispatch(setBatchReload(props.batchId));
      handleHideSearchImage();
      toastr.success('Success', 'A card is added to current batch');
    }).catch(err => {
      toastr.error('Error', 'Failed to add card');
    }).finally(() => {
      setLoading(false)
    })
  }

  if (showSearchImage) {
    return (<SearchCards batchId={props.batchId}
                         handleHide={handleHideSearchImage}
                         handleAction={handleAddSearchesToBatch} />);
  }

  if (showUploadImage) {
    return (<SelectUploadCards batchId={props.batchId} handleHide={handleHideUploadImage}
                               handleAction={props.handleAction}/>)
  }
  
  return (
    <div className="main-popup-container add-cards">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Add Cards</h2>
          <div className="close-popup" onClick={props.handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <div>
            <p>How would you like to add cards to the batch?</p>
            <div className="add-cards-options-container">
              <div className="add-cards-option" onClick={handleSearchImage}>
                <img src={searchImage} alt="" />
                  <p>Manual Search</p>
                  <p>Use manual search to find cards and add them to your batch along with their stock photos</p>
              </div>
              <div className="add-cards-option" onClick={handleUploadImage}>
                <img src={uploadImage} alt="" />
                  <p>Upload Card Images</p>
                  <p>Bulk upload card images that will be processed and the corresponding cards will be added to the
                    batch</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCards;