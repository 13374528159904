const MEMBERSHIP_KIND = {
  "Prospect": 1,
  "Minors": 2,
  "Rookie": 3,
  "All-Star": 4,
  "MVP": 5,
  "Hall of Frame": 6
}

const MEMBERSHIP_OPTIONS = [
  { value: 1, label: "Propect"},
  { value: 2, label: "Minors"},
  { value: 3, label: "Rookie"},
  { value: 4, label: "All-Star"},
  { value: 5, label: "MVP"},
  { value: 6, label: "Hall of Frame"},
]



const MEMBERSHIP_CONTENT = [{
      "id": 1,
      "name": "Prospect",
      "scan": 500,
      "storage": 2.0,
      "payment": 9.0,
      // "ebay_export": false,
      // "auction_export": false,
      // "custom_export": 0,
      // "resolution": 1,
      // "dpi": 1,
      "tiff_support": false
    }, {
      "id": 2,
      "name": "Minors",
      "scan": 1100,
      "storage": 5.0,
      "payment": 19.0,
      // "ebay_export": true,
      // "auction_export": false,
      // "custom_export": 0,
      // "resolution": 1,
      // "dpi": 1,
      "tiff_support": false
    }, {
      "id": 3,
      "name": "Rookie",
      "scan": 2500,
      "storage": 25.0,
      "payment": 39.0,
      // "ebay_export": true,
      // "auction_export": true,
      // "custom_export": 0,
      // "resolution": 1,
      // "dpi": 1,
      "tiff_support": false
    }, {
      "id": 4,
      "name": "All-Star",
      "scan": 5000,
      "storage": 50.0,
      "payment": 59.0,
      // "ebay_export": true,
      // "auction_export": true,
      // "custom_export": 1,
      // "resolution": 2,
      // "dpi": 2,
      "tiff_support": false
    }, {
      "id": 5,
      "name": "MVP",
      "scan": 10000,
      "storage": 100.0,
      "payment": 99.0,
      // "ebay_export": true,
      // "auction_export": true,
      // "custom_export": 1,
      // "resolution": 3,
      // "dpi": 3,
      "tiff_support": false
    }, {
      "id": 6,
      "name": "Hall of Frame",
      "scan": 25000,
      "storage": 250.0,
      "payment": 199.0,
      // "ebay_export": true,
      // "auction_export": true,
      // "custom_export": 2,
      // "resolution": 4,
      // "dpi": 3,
      "tiff_support": true
}];

const SCAN_PACKAGES = [
  {"scan": 1000, "payment": 19.99},
  {"scan": 5000, "payment": 49.99},
  {"scan": 10000, "payment": 99.99},
  {"scan": 25000, "payment": 199.99},
  {"scan": 50000, "payment": 349.99},
  {"scan": 100000, "payment": 599.99}
]
const getMembership = (_id) => {
  return MEMBERSHIP_CONTENT.find(_membership => _membership.id === _id);
}
const membershipConst = {MEMBERSHIP_KIND, MEMBERSHIP_CONTENT, MEMBERSHIP_OPTIONS, SCAN_PACKAGES, getMembership};
export default membershipConst;