import * as auth from '../actions/auth.js';

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: true,
  errors: {},
  admin_role: 0,
  membership: {},
  cash_state: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.LOGIN_SUCCESS:
    case auth.REFRESH_TOKEN_SUCCESS:
      localStorage.setItem("token", action.data.token);
      return {
        ...state,
        token: action.data.token,
        admin_role: action.data.admin_role,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
      };

    case auth.LOGIN_REQUEST:
    case auth.REFRESH_TOKEN_REQUEST:
    case auth.GET_USER_ROLE_REQUEST:
    case auth.GET_USER_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case auth.LOGIN_FAILURE:
    case auth.LOGOUT:
    case auth.REFRESH_TOKEN_FAILURE:
    case auth.GET_USER_ROLE_FAILURE:
    case auth.GET_USER_MEMBERSHIP_FAILURE:
      localStorage.removeItem("token");
      return {
        ...state, 
        errors: action.data, 
        token: null,
        isAuthenticated: false, 
        isLoading: false
      };

    case auth.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        admin_role: action.data.admin_role
      };

    case auth.GET_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        membership: action.data
      };

    case auth.UPDATE_CASH_STATE:
      return {
        ...state,
        cash_state: action.data
      };
    default:
      return state
  }
}

export default authReducer;