import React, {useEffect, useState} from "react";
import NewEbayTemplate from "../../Popups/Settings/newEbayTemplate";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import {ReactComponent as SaveDocIcon} from "../../../assets/images/settings/savedoc.svg"
import {ReactComponent as EditIcon} from "../../../assets/images/settings/editIconBlack.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/images/settings/deleteBlack.svg";
import {ReactComponent as BackIcon} from "../../../assets/images/breadCrumbIcon.svg";
import {AlertYesNoModal} from "../../Popups/alert";
import {toastr} from "react-redux-toastr";
import EbayTemplateForm from "./ebayTemplateForm";
import {useDispatch, useSelector} from "react-redux";
import {getEBayTemplates} from "../../../redux/actions/settings";


const ebayColumns = [
  'Name', 'Data Created', 'Last Edited', ''
]
const ExportEbayTemplates = () => {
  const [showEbayOperationPopup, setShowEbayOperationPopup] = useState(0);
  const [currentOperationTemplateDetail, setCurrentOperationTemplateDetail] = useState(null);
  const [currentOperationTemplateId, setCurrentOperationTemplateId] = useState(-1);
  const [modifiedTemplate, setModifiedTemplateInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const ebayTemplates = useSelector(state => state.settings.ebayTemplates);

  const dispatch = useDispatch();

  const onClickCreateNewEbayTemplate = () => {
    setShowEbayOperationPopup(1);
  }

  const onCloseEbayOperationPopup = () => {
    setShowEbayOperationPopup(0);
  }

  const onClickMakeDefault = (templateId) => {
    if (loading) {
      return;
    }
    setLoading(true)
    ApiHelper.put(API_URL.EbayTemplateDefault, {}, {}, {
      template_id: templateId
    }).then(res => {
      toastr.success('', `Current template is set as default`);
      dispatch(getEBayTemplates());
    }).finally(() => {
      setLoading(false);
    })
  }
  const onClickEditTemplate = (templateId, mode) => {
    setCurrentOperationTemplateId(templateId);
    setEditMode(mode);
  }

  const onClickDeleteTemplate = (templateId) => {
    setCurrentOperationTemplateId(templateId);
    setShowEbayOperationPopup(2);
  }

  const onClickDuplicateTemplate = (templateId) => {
    // setCurrentOperationTemplateId(templateId);
    if (loading) {
      return;
    }
    setLoading(true)
    ApiHelper.post(API_URL.EbayTemplateDetail,{}, {}, {
      template_id: templateId
    }).then(res => {
      toastr.success('Success', 'Ebay Template is duplicated successfully');
      dispatch(getEBayTemplates());
    }).catch(err => {
      toastr.error('Failed!', 'Ebay Template is not removed.');
    }).finally(() => {
      setLoading(false);
    });
  }

  const deleteTemplate = () => {
    if (loading || currentOperationTemplateId === -1) {
      return;
    }
    setLoading(true);
    ApiHelper.delete(API_URL.EbayTemplate,{}, {}, {
      template_id: currentOperationTemplateId
    }).then(res => {
      toastr.success('Success', 'Ebay Template is removed successfully');
      dispatch(getEBayTemplates());
      onCloseEbayOperationPopup();
    }).catch(err => {
      toastr.error('Failed!', 'Ebay Template is not removed.');
    }).finally(() => {
      setLoading(false);
    });
  }

  const validationInput = (updatedTemplate) => {
    if (updatedTemplate.auto_decline_offer > updatedTemplate.auto_accept_offer) {
      toastr.error('Input data error!', `"Auto Decline Offer" can't be bigger than "Auto Accept Offer".`);
      return false;
    }
    return true;
  }
  const updateTemplate = () => {
    if (loading || modifiedTemplate === null) {
      return;
    }
    let updatedTemplate = {
      ...modifiedTemplate,
      template_id: modifiedTemplate.id
    }
    if (!validationInput(updatedTemplate)) {
      return;
    }
    setLoading(true);
    delete updatedTemplate['id'];

    ApiHelper.put(API_URL.EbayTemplate,{}, {}, updatedTemplate).then(res => {
      toastr.success('Success', 'Ebay Template is updated successfully');
      dispatch(getEBayTemplates());
    }).catch(err => {
      toastr.error('Failed!', 'Ebay Template is not updated.');
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    dispatch(getEBayTemplates());
  }, []);

  useEffect(() => {
    if (currentOperationTemplateId !== -1) {
      getEbayTemplateDetail(currentOperationTemplateId);
    }
  }, [currentOperationTemplateId]);

  const getEbayTemplateDetail = (templateId) => {
    setLoading(true);
    ApiHelper.get(API_URL.EbayTemplateDetail, {
      template_id: templateId
    }, {}, {}).then(res => {
      setCurrentOperationTemplateDetail(res.data.template[0]);
      setModifiedTemplateInfo(res.data.template[0]);
    }).catch(e => {
      setCurrentOperationTemplateDetail({});
      setModifiedTemplateInfo({});
    }).finally(() => {
      setLoading(false)
    })
  }

  return (editMode ?
    (<div className={'edit-mode-ebay'}>
      <div className="sub-link" onClick={() => onClickEditTemplate(-1, false)}>
        <BackIcon className={"flipped"} fill={"#EA3553"}/>
        <span>Go Back</span>
      </div>
      {currentOperationTemplateDetail && <div className={"ebay-template"}>
        <EbayTemplateForm ebayTemplateData={currentOperationTemplateDetail} onChange={setModifiedTemplateInfo}/>
        <button className={'btn primary btn-create'} onClick={updateTemplate}>Save Changes</button>
      </div>}
    </div>) :
    (<div className={"ebay-template"}>
      {showEbayOperationPopup === 1 &&
      <NewEbayTemplate handleHide={onCloseEbayOperationPopup}/>
      }
      {showEbayOperationPopup === 2 &&
        <AlertYesNoModal
          handleAction={deleteTemplate}
          handleHide={onCloseEbayOperationPopup}
          modalType="delete_ebay_template"
        />
      }
      <div className={"template-header"}>
        <h2>eBay Templates</h2>
        <button className={'btn primary'} onClick={onClickCreateNewEbayTemplate}>+ Create New Template</button>
      </div>
      <div className={"table_main"}>
        <table className="table table-striped bulk_table_main">
          <thead>
          <tr>
            {ebayColumns.map((th, i) => (<th scope="col" key={`header_${i}_${th}`}>{th}</th>))}
          </tr>
          </thead>
          {!loading && <tbody>
          { ebayTemplates.map((template, key) => (
              <tr key={key}>
                <td className={'name'}>{template.name + (template.default ? '(Default)' : '')}</td>
                <td>{template.created_date.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                })}</td>
                <td>{template.edited_date.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                })}</td>
                <td>
                  <div style={{display: 'flex', justifyContent: "flex-end", alignItems: "center"}}>
                    {!template.default && <div className="btn template-operation" onClick={() => onClickMakeDefault(template.id)}>Make Default</div>}
                    <div className="btn template-operation" onClick={() => onClickEditTemplate(template.id, true)}><EditIcon fill={'#EA3553'} style={{ width: '20px', height: '20px'}}/></div>
                    <div className="btn template-operation" onClick={() => onClickDuplicateTemplate(template.id)}><SaveDocIcon fill={'#EA3553'} style={{ width: '20px', height: '20px'}}/></div>
                    <div className="btn template-operation" onClick={() => onClickDeleteTemplate(template.id)}><DeleteIcon fill={'#EA3553'} style={{ width: '20px', height: '20px'}}/></div>
                  </div>
                </td>
              </tr>
            ))
          }
          </tbody>}
        </table>
      </div>
    </div>));
}

export default ExportEbayTemplates;