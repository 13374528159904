import React, {useMemo, useRef, useState} from "react";
import deleteRedImage from "../../assets/images/deleteRed.svg";
import editIconDark from "../../assets/images/editIconDark.svg";
import {toastr} from "react-redux-toastr";
import SlidingPane from "react-sliding-pane";
import {DataField} from "./dataField";
import {throttle} from "lodash";

export const TitleTemplate = (props) => {
  const {title, handleChangeTemplate, defaultTemplate, handleChangeDefault , handleRemoveTemplate} = props
  const [labelText, setLabelText] = useState(title.label);
  const [isStandard, setIsStandard] = useState(title.label === 'Standard Title');
  const [showTemplateSlidePanel, setShowTemplateSlidePanel] = useState(false);
  const textareaRef = useRef();

  const onChangeValue = (e) => {
    handleChangeTemplate('value', e.target.value, title);
  }

  const onChangeLabel = (e) => {
    if (e.target.innerText === 'Standard Title') {
      toastr.warning('', 'Current label is reserved for standard');
      return;
    }
    handleChangeTemplate('label', e.target.innerText, title)
  }

  const onClickMakeTitleDefault = (e) => {
    handleChangeDefault(title.label)
  }

  const onAddField = (fieldName) => {
    if (fieldName) {
      const selectionStart = textareaRef.current.selectionStart;
      const selectionEnd = textareaRef.current.selectionEnd;
      const newValue = title.value.substring(0, selectionStart) + `{{${fieldName}}}` +
        title.value.substring(selectionEnd, title.value.length);
      handleChangeTemplate('value', newValue, title);
      setTimeout(() => {
        textareaRef.current.focus();
        textareaRef.current.selectionStart = selectionStart + `{{${fieldName}}}`.length;
        textareaRef.current.selectionEnd = selectionStart + `{{${fieldName}}}`.length;
      }, 10);
    }
  }

  const throttledAddField = useMemo(
    () => throttle(onAddField, 300)
    , []);

  return (
    <div className={"template-container"}>
      { showTemplateSlidePanel &&
        <SlidingPane
          overlayClassName={"settings-sliding-overlay"}
          isOpen={showTemplateSlidePanel}
          width={"25%"}
          hideHeader={true}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setShowTemplateSlidePanel(false);
          }}
        >
          <DataField handleHide={() => {setShowTemplateSlidePanel(false)}}
                     handleAddField={throttledAddField}
          />
        </SlidingPane>
      }
      <div className={"title-textarea-edit"}>
        <div className="label-edit">
          <span contentEditable={!isStandard} suppressContentEditableWarning onInput={onChangeLabel}>{labelText}</span>
          {!isStandard && <img src={editIconDark} alt=""/>}
          {defaultTemplate && <span style={{fontWeight: 700}}>&nbsp;&nbsp;(Default)&nbsp;&nbsp;</span>}
        </div>
        <div className={'option-buttons'}>
          {title.label !== 'Standard Title' &&
            <button className="btn-template btn secondary" onClick={() => setShowTemplateSlidePanel(true)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#EA3553"/>
            </svg>
            Add Data Fields
          </button>}
          {!defaultTemplate &&
            <button className={"btn-template btn secondary"} onClick={onClickMakeTitleDefault}>Make default</button>}
          {!isStandard && <button className="btn-template btn secondary"  disabled={defaultTemplate} onClick={() => {handleRemoveTemplate(title)}}>
            <img src={deleteRedImage} alt=""/>
            Remove Template
          </button>}
        </div>
      </div>
      <div className="fw">
        <textarea ref={textareaRef} value={title.value} className={"title"} onChange={onChangeValue} disabled={isStandard}/>
      </div>
    </div>
  )
}
