import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router'
import {reducer as toastr} from 'react-redux-toastr';
import auth from './auth';
import batches from "./batches";
import profile from "./profile";
import payment from "./payment";
import settings from "./settings";

const reducers = (history) => combineReducers({
    auth,
    batches,
    profile,
    payment,
    settings,
    toastr,
    router: connectRouter(history),
});
export default reducers;
