import ACTION_TYPE from "../../consts/actions";

const initialState = {
  isLoadingProfileData: false,
  profile: {
    profile:{
      "user_name": "",
      "suspended": true
    },
    stats: {
      "current_scans": 0,
      "current_storage": 0,
      "limit_scans": 0,
      "limit_storage": 0
    },
    subscription: {
      "name": "",
      "payment": 0,
      "due_date": "",
      "resolution": 0,
      "dpi": 0
    },
  },
  photo: {
    profile_photo: "",
    full_name: ""
  },
  membership: {
    membership_id: 0,
    membership_name: "",
    membership_content: []
  },
  categories: {
    category_ids: [],
    category_names: [],
    category_values: []
  }
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_PROFILE_REQUEST:
      return {
        ...state,
        isLoadingProfileData: true
      }
    case ACTION_TYPE.GET_PROFILE_INFO:
      return {
        ...state,
        isLoadingProfileData: false,
        profile: action.data,
      }
    case ACTION_TYPE.GET_MEMBERSHIP_INFO:
      return {
        ...state,
        isLoadingProfileData: false,
        membership: action.data,
      }
  case ACTION_TYPE.GET_MEMBERSHIP_CONTENT:
    return {
      ...state,
      isLoadingProfileData: false,
      membership: {
        ...state.membership,
        membership_content: action.data.membership_content
      },
    }
    case ACTION_TYPE.GET_PROFILE_PHOTO:
      return {
        ...state,
        isLoadingProfileData: false,
        photo: action.data,
      }
    case ACTION_TYPE.GET_CATEGORIES:
      return {
        ...state,
        isLoadingProfileData: false,
        categories: action.data,
      }
    default:
      return state
  }
}

export default profile;