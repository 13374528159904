import React, {useState} from "react";
import ApiHelper from "../../../helpers/apiHelper";
import { toastr } from "react-redux-toastr";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import API_URL from "../../../consts/urls";

const AddScanModal = React.memo(props => {
  const {handleAction, handleHide, id} = props;
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  
  const handleInput = e => {
    if (e.target.value !== "") setError('');
    setAmount(e.target.value);
  }
  
  const handleAddScan = () => {
    if (amount === '') {
      setError("Please enter the additional scan numbers.")
    } else {
      ApiHelper.put(API_URL.UpdateScans, {}, {}, {
        user_id: parseInt(id),
        amount: amount
      }).then(res => {
        handleHide();
        toastr.success("Success!", "It was successfully added.");
        handleAction();
      }).catch(err => {
        toastr.error("Fail!", "Please try again.");
        handleHide();
      })
    }
  }
  
  return (
    <div className="main-popup-container change-membership">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Add scan</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <div className="main_modal_data nor_space_btns">
            <p className="collect_modal_coll chnage_fonts_size">
              Input additional scans you want to purchase.</p>
            <div className="select-membership">
              <input name="amount" type="text" placeholder="" value={amount} onChange={handleInput}/>
              <div className="select-button">
                <div className="btn primary" onClick={handleAddScan}> Save </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

export default AddScanModal;