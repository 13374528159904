import React, {useEffect, useState} from "react";
import {toastr} from "react-redux-toastr";
import {useDispatch} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import CheckoutForm from "../../Payment/checkoutForm";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import membershipConst from "../../../consts/membershipInformation";
import {endPaymentAction} from "../../../redux/actions/payment";
import {getProfile} from "../../../redux/actions/profile";


const BuyPackage = ({pkg, onClickBuy}) => {
  return (
    <div className="scan-package">
      <div className="package-feature">
        <p className="scan-feature-value">{pkg.scan.toLocaleString('en')}</p>
        <p className="scan-cost">${pkg.payment}</p>
        <div className={"scan-buy"} onClick={onClickBuy}>BUY</div>
      </div>
    </div>
  )
}
const BuyScans = (props) => {
  const {handleHide} = props;
  const [paying, setPaying] = useState(false)
  const [buyPkg, setCurrentBuyPkg] = useState(null);
  const dispatch = useDispatch()
  const onClickBuyScans = (pkg) => {
    setCurrentBuyPkg(pkg);
  }
  const onConfirmPay = (paymentId, email) => {
    if (paying || !buyPkg) {
      return;
    }
    setPaying(true)
    ApiHelper.post(API_URL.BuyScans, {}, {}, {
      payment_method_id: paymentId,
      amount: buyPkg.payment
    }).then(res=> {
      toastr.success('Success', `You purchased ${buyPkg.scan.toLocaleString('en')} additional scans`);
      setCurrentBuyPkg(null)
      dispatch(getProfile());
      handleHide();
    }).catch(err => {
      toastr.error('Fail', err.response.data.message);
    }).finally(() => {
      dispatch(endPaymentAction());
      setPaying(false);
    });
  }
  return (
    <div className="main-popup-container buy-scans-popup">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Buy Additional Scans</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <div>
            <div className="scan-package-container">
              {membershipConst.SCAN_PACKAGES.map((pk, i) =>
                (<BuyPackage pkg={pk} key={`scan_${i}`} onClickBuy={() => onClickBuyScans(pk)}/>)
              )}
            </div>
            {buyPkg && <div className={'scan-package-buy'}>
              <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
                <CheckoutForm email={""} action={onConfirmPay}/>
              </Elements>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyScans;