import React, {useCallback, useState, useRef, useEffect} from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";

const UploadBannerImage = (props) => {
  const {handleAction, handleHide} = props
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [imageUpdated, setImageUpdated] = useState(false);

  useEffect(() => {
    setLoading(true)
    ApiHelper.get(API_URL.GetBannerImage).then(res => {
      // setCroppedImage(res.data.banner_image);
      setImageToCrop(res.data.banner_image)
    }).finally(() => {
      setLoading(false)
    });
  }, [])
  const updateBannerImage = () => {
    if (loading) {
      return;
    }
    setLoading(true)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    ApiHelper.put(API_URL.UpdateBannerImage, {}, config, {
      // banner_image: croppedImage
      banner_image: imageToCrop
    }).then(res => {
      toastr.success('Success!', res.data.message);
      handleAction();
    }).finally(() => {
      setLoading(false)
      handleHide();
    });
  }

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () =>
        setImageToCrop(reader.result)
      );
      setImageUpdated(true);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="main-popup-container add-banner-popup">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Upload Banner Image</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          {!loading &&
          <input
            type="file"
            accept="image/*"
            onChange={onUploadFile}
          />}
          <div style={{display: "flex", width: '100%', height: '100%'}}>
            <div className={"image-zone"}>
              {imageToCrop && <LazyLoadImage
                alt="Cropped Image"
                effect={'blur'}
                src={imageToCrop}
                style={{overflowY: "scroll", maxWidth: "100%", maxHeight: '100%', objectFit: "contain"}}
              />}
            </div>
          </div>
          <button className="btn primary" style={{marginTop: '32px'}} onClick={updateBannerImage}
                  disabled={!imageUpdated || loading}>Upload</button>
        </div>
      </div>
    </div>
  )
}

export default UploadBannerImage;