import React, {useEffect} from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/actions/auth";
import {getProfilePhoto} from "../../redux/actions/profile";
import CustomLazyLoadImage from "../../components/LazyLoadImage";

const NavbarPage = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile);
    useEffect(() => {
      dispatch(getProfilePhoto());
    }, [dispatch])

    return (
      <header>
        <div className="header-search" />
        <div className="separator"/>
        <Dropdown className="profile">
          <Dropdown.Toggle>
            <CustomLazyLoadImage src={profile.photo.profile_photo} effect={"blur"} alt="" className="user-image"/>
            <p className="user-fullname">{profile.photo.full_name}</p>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="/settings">
              <i className="fa fa-user"/> Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(logout())}>
              <i className="fa fa-sign-out"/> Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>
    );
  }
;

export default NavbarPage;
