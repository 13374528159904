import React from 'react';

const FileUploader = props => {
  const {handleFile} = props;
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <a style={{cursor: "pointer", color: '#EA3553'}} onClick={handleClick}>Choose file</a>
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}}
      />
    </>
  );
};
export default FileUploader;
