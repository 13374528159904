import React, {useState} from 'react';
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import DropzoneComponent from "../FileInput/dropZoneComponent";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import { ProgressBar } from "react-bootstrap"
import {useDispatch} from "react-redux";
import {setBatchReload} from "../../redux/actions/batches";
import {useHistory} from "react-router-dom";
import axios from "axios";
import AuthHelper from "../../helpers/authHelper";

const UploadCards = (props) => {
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [progress, setProgress] = useState(0)

  const uploadFiles = (e) => {
    e.preventDefault()
    if (files.length <= 0) {
      return;
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Token ${AuthHelper.getAccessToken()}`
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.floor((progressEvent.loaded -1) / progressEvent.total * 100);
        setProgress(progress);
      },
    };
  
    const formData = new FormData();
    formData.append("batch", props.batchId);
    formData.append("back_ret", props.frontAndBack);
    files.forEach(value => {
      formData.append("file", value);
    });

    setUploading(true)
    axios.post(API_URL.BulkAdd, formData, config).then(res=> {
      toastr.success('Success', res.data.message);
      dispatch(setBatchReload(props.batchId));
      setUploading(false)
      props.handleAction();
    }).finally(() => {
    }).catch((err) => {
      toastr.error('Fail', err.response.data.message);
      setUploading(false)
    })
  }
  
  return (
    <div className="main-popup-container uploadcards-modal">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Upload Cards</h2>
          {!uploading &&
            <div className="close-popup" onClick={props.handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>}
        </div>

        <div className="body">
          {uploading &&
            <ProgressBar now={progress} label={`${progress}%`} style={{width:'80%', height: '30px'}}/>
          }
          <DropzoneComponent
            onDrop={setFiles}
            multi={true}
            inputFiles={files}
          />
          <button type="button" className="btn btn-danger btn-block" onClick={uploadFiles}
                  disabled={uploading}>Upload</button>
        </div>
      </div>
    </div>
  )
}

export default UploadCards;