import scannerFi8170 from "../assets/images/Fujitsu fi-8170.png";
import scannerFi7030 from "../assets/images/Fujitsu fi-7030.png";
import scannerFi7160 from "../assets/images/Fujitsu fi-7160.png"
import scannerEpsonV550 from "../assets/images/Epson V550 (refurb).png";
import scannerEpsonV600 from "../assets/images/Epson V600.png";

const SCANNER_INFORMATION = [
  {
    img: scannerFi8170,
    name: "Fujitsu fi-8170",
    type: "Auto-Feed",
    price: 1012,
    cards_per_hour: 6000,
    loose: true,
    card_saver: true,
    top_loader: true,
    slabs: false,
    penny: true,
    link: "https://www.scannerone.com/product/FUJ-Fi-8170_CDP.html"
  },
  {
    img: scannerFi7160,
    name: "Fujitsu fi-7160",
    type: "Auto-Feed",
    price: 1058,
    cards_per_hour: 3000,
    loose: true,
    card_saver: false,
    top_loader: false,
    slabs: false,
    penny: false,
    link: "https://www.scannerone.com/product/FUJ-FI-7160_CDP.html"
  },
  {
    img: scannerFi7030,
    name: "Fujitsu fi-7030",
    type: "Auto-Feed",
    price: 522,
    cards_per_hour: 1500,
    loose: true,
    card_saver: true,
    top_loader: true,
    slabs: false,
    penny: true,
    link: "https://www.scannerone.com/product/FUJ-Fi7030_SPC.html"
  },
  {
    img: scannerEpsonV550,
    name: "Epson V550",
    type: "Flatbed",
    price: 150,
    cards_per_hour: 200,
    loose: true,
    card_saver: true,
    top_loader: true,
    slabs: true,
    penny: true,
    link: "https://www.bestbuy.com/site/searchpage.jsp?st=epson+v550&_dyncharset=UTF-8&_dynSessConf=&id=pcat17071&type=page&sc=Global&cp=1&nrp=&sp=&qp=&list=n&af=true&iht=y&usc=All+Categories&ks=960&keys=keys"
  },
  {
    img: scannerEpsonV600,
    name: "Epson V600",
    type: "Flatbed",
    price: 300,
    cards_per_hour: 400,
    loose: true,
    card_saver: true,
    top_loader: true,
    slabs: true,
    penny: true,
    link: "https://www.bestbuy.com/site/epson-perfection-v600-photo-scanner-black/9599259.p?skuId=9599259"
  },
];

export default SCANNER_INFORMATION;