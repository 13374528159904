import React from 'react';
import {Spinner} from "react-bootstrap";

export default props => {
  return (
    //
    // <div className="ag-custom-loading-cell" style={{paddingLeft: '10px', lineHeight: '25px'}}>
    //   <i className="fas fa-spinner fa-pulse"/> <span> {props.loadingMessage}</span>
    // </div>
    <div className='component-loading-overlay'>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p>Card Information Loading </p>
      <p>Please wait while we load all of the information for your cards. Depending on the number of cards in the batch, this may take a few moments.</p>
    </div>
  );
};
