import React, {useEffect, useState} from "react";
import AvatarModal from "./AvatarModal";
import defaultAvatar from "../../assets/images/placeholder.jpg"
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import {getProfilePhoto} from "../../redux/actions/profile";
import {connect} from "react-redux";
import CustomLazyLoadImage from "../LazyLoadImage";

const CustomAvatar = (props) => {
  const [showModal, setShowModal] = useState(false);

  const addAvatar = data => {
    setShowModal(false);
  
    ApiHelper.put(API_URL.UpdateProfilePhoto, {}, {}, {
      photo: data
    }).then(res => {
      toastr.success('Success!', res.data.message);
      props.getProfilePhoto();
    }).catch(err => {
      toastr.error('Fail!', 'Please try again to update.');
    });
  }
  
  return (
    <div className="profile_pic_sec">
      <div className="pic_pro">
        {props.photo.profile_photo === '' ? <img src={defaultAvatar} alt="" className="avatar-image" /> :
          <CustomLazyLoadImage src={props.photo.profile_photo} effect={"blur"} className="avatar-image" />
        }
        <div className="hover_change">
          <span
            onClick={() => setShowModal(true)}
          >
            <i className='bx bxs-edit-alt' />
          </span>
          {showModal &&
            <AvatarModal
              show={showModal}
              onHide={() => setShowModal(false)}
              onSubmit={addAvatar}
            />
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  photo: state.profile.photo,
});

const mapDispatchToProps = {
  getProfilePhoto
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomAvatar);