export const PAYMENT_ACTION_BEGIN = 'payment/PAYMENT_ACTION_BEGIN';
export const PAYMENT_ACTION_END = 'payment/PAYMENT_ACTION_END';

export const beginPaymentAction = () => dispatch => {
  dispatch({type: PAYMENT_ACTION_BEGIN});
  // return {type: PAYMENT_ACTION_BEGIN}
};

export const endPaymentAction = () => dispatch => {
  dispatch({type: PAYMENT_ACTION_END});
  // return {
  //   type: PAYMENT_ACTION_END
  // }
};