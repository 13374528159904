import React, {useState} from 'react';
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import uploadFront from "../../assets/images/upload_front.jpg";
import uploadBack from "../../assets/images/upload_back.jpg";
import UploadCards from "./uploadCards";


const SelectUploadCards = (props) => {
	const batchId = props.batchId;
	const [uploadMode, setUploadMode] = useState(0);
	const handleUploadFrontAndBack = () => {
		setUploadMode(1)
	}

	const handleUploadFrontOnly = () => {
		setUploadMode(2)
	}

	const handleHideUploadImage = () => {
		setUploadMode(0);
	}

	if (uploadMode !== 0) {
		return (<UploadCards batchId={batchId} frontAndBack={uploadMode===1}
												 handleAction={props.handleAction}
												 handleHide={handleHideUploadImage} />)
	}

	return (
		<div className="main-popup-container add-cards upload-card-images">
			<div className="main-overlay"/>
			<div className="main-popup">
				<div className="header">
					<h2>Add cards by uploading card images</h2>
					<div className="close-popup" onClick={props.handleHide}>
						<img src={closeIconBlack} alt=""/>
					</div>
				</div>
				<div className="body">
					<div>
						<p>Images of which side(s) of cards do you want to upload?</p>
						<div className="add-cards-options-container">
							<div className="add-cards-option" onClick={handleUploadFrontAndBack}>
								<div className="add-cards-images">
									<img src={uploadFront} alt=""/>
									<img src={uploadBack} alt=""/>
								</div>
								<p>Front & Back Images</p>
								<p>Upload images of both the front and back sides of the cards. All cards must have
									a back image and named properly for this to work.</p>
								<p style={{marginTop: '8px', opacity: 1}}><a href="#">Click here for tutorial</a></p>
							</div>
							<div className="add-cards-option" onClick={handleUploadFrontOnly}>
								<div className="add-cards-images">
									<img src={uploadFront} alt=""/>
								</div>
								<p>Front Images only</p>
								<p>Upload images of only the front side of the cards. Back images will not work
									properly.</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default SelectUploadCards;