import {GET_EBAY_TEMPLATES} from "../redux/actions/settings";

const ACTION_TYPE = {
  BATCHES_LIST_REQUEST: 'api/BATCHES_LIST_REQUEST',
  BATCHES_LIST_FAILED: 'api/BATCHES_LIST_FAILED',
  GET_BATCH_INFO: 'payload/GET_BATCHES_LIST',
  
  // Batch is updated via app, need to reload
  BATCH_UPDATED: 'app/BATCH_UPDATED',
  RESET_BATCH_UPDATED: 'app/RESET_BATCH_UPDATED',
  
  // Checking processing batch
  PROCESSING_BATCH_INFO: 'api/PROCESSING_BATCH_INFO',
  
  GET_PROFILE_REQUEST: '/api/GET_PROFILE_REQUEST',
  GET_PROFILE_FAILED: '/api/GET_PROFILE_FAILED',
  
  GET_PROFILE_INFO: '/payload/GET_USER_PROFILE',
  GET_MEMBERSHIP_INFO: '/payload/GET_MEMBERSHIP_INFO',
  GET_MEMBERSHIP_CONTENT: '/payload/GET_MEMBERSHIP_CONTENT',
  
  GET_PROFILE_PHOTO: '/payload/GET_PROFILE_PHOTO',
  GET_CATEGORIES: '/payload/GET_CATEGORIES',

  //Get ebay export templates
  EBAY_TEMPLATES_REQUEST: 'api/EBAY_TEMPLATES_REQUEST',
  EBAY_TEMPLATES_FAILED: 'api/EBAY_TEMPLATES_FAILED',
  GET_EBAY_TEMPLATES: 'payload/GET_EBAY_TEMPLATES',
}

export default ACTION_TYPE;