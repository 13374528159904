import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { HashLink } from 'react-router-hash-link';

import {ReactComponent as BackIcon} from "../../assets/images/breadCrumbIcon.svg";

import ExportShopifyTemplate from "../../components/Settings/Export/shopifyTemplate";
import ExportSimpleAuctionTemplate from "../../components/Settings/Export/simpleAuctionTemplate";
import ExportCustomCSVTemplate from "../../components/Settings/Export/exportCustomCSVTemplate";
import ExportEbayTemplates from "../../components/Settings/Export/exportebayTemplate";

const ExportSettingsContainer = (props) => {
  const tabTitles = {
    "ebay": "eBay Templates",
    "shopify": "Shopify",
    "simple_auction": "Simple Auction",
    "custom_csv": "Custom CSV"
  };
  const [tabKey, setTabKey] = useState('ebay');
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const tab = params.get('tab');
    if (tabTitles[tab] !== undefined) {
      setTabKey(tab);
    }
  }, [props.location.search])
  return (
    <div className={'export-setting-container'}>
      <div className="content-header-main">
        <div className="column left vert">
          <h1 style={{textTransform: 'none'}}>
            Export Settings
          </h1>
          <div className="sub-link">
            <Link to="/settings">Settings</Link>
            <BackIcon fill={'grey'}/>
            <HashLink to="/settings#section_export_settings">Export Settings</HashLink>
            <BackIcon fill={'grey'}/>
            <span>{tabTitles[tabKey]}</span>
          </div>
        </div>
      </div>
      <div className="content-container">
        <Tabs
          className={'tab-settings'}
          defaultActiveKey="ebay"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
        >
          <Tab eventKey="ebay" title="eBay">
            <ExportEbayTemplates />
          </Tab>
          <Tab eventKey="shopify" title="Shopify">
            <ExportShopifyTemplate />
          </Tab>
          <Tab eventKey="simple_auction" title="Simple Auction">
            <ExportSimpleAuctionTemplate />
          </Tab>
          <Tab eventKey="custom_csv" title="Custom CSV">
            <ExportCustomCSVTemplate />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default ExportSettingsContainer;