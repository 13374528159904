import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import {getProfile, getUserMembership} from "../../../redux/actions/profile";
import MembershipItem from "../../Settings/membershipItem";
import CheckoutForm from "../../Payment/checkoutForm";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import {toastr} from "react-redux-toastr";
import {endPaymentAction} from "../../../redux/actions/payment";

const ConfirmMembershipChange = (props) => {
  const {handleHide, currentPlan, newPlan} = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    dispatch(getUserMembership())
  }, [])

  const onConfirmPay = (paymentMethodId, email) => {
    if (loading) {
      return;
    }
    setLoading(true)
    ApiHelper.post(API_URL.BuySubscription, {}, {}, {
      membership_id: newPlan.id,
      email: email,
      payment_method_id: paymentMethodId
    }).then(res=>{
      toastr.success('Success!', res.data.message);
      dispatch(getProfile());
      dispatch(getUserMembership());
      handleHide();
    }).finally(() => {
      dispatch(endPaymentAction());
      setLoading(false)
    })
  }
  return (
    <div className="main-popup-container confirm-membership-popup">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Confirm Membership Change</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <div className="memberships-container">
            <div className={'plan-current'}>
              <p>Current Plan</p>
              <div className={'membership-item'}>
                <MembershipItem plan={currentPlan} />
              </div>
            </div>
            <div className={'plan-new'}>
              <p>New Plan</p>
              <div className={'membership-item'}>
                <MembershipItem plan={newPlan} />
              </div>
            </div>
          </div>
          <div className={'membership-diff'}>
            <div>
              <span>Monthly price difference</span>
              <p>${newPlan.payment - currentPlan.payment}</p>
            </div>
            <div>
              <span>Activate on</span>
              <p>{new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              })}</p>
            </div>
          </div>
          <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
            <CheckoutForm email={""} action={onConfirmPay}/>
          </Elements>
        </div>
      </div>
    </div>
  )
}

export default ConfirmMembershipChange;