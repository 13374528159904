import React, {useEffect, useState} from "react";

import {TitleTemplate} from "./titleTemplate";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {deepCloneObject} from "../../helpers/commonHelper";
import {DescriptionTemplate} from "./descriptionTemplate";
import {toastr} from "react-redux-toastr";
import DefaultData from "../../consts/defaultData";

const Template = (props) => {
  const {templateType, updateTemplates, setDirty} = props
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState({});

  useEffect(() => {
    setLoading(true)
    ApiHelper.get(API_URL.TemplateSetting).then(res => {
      const dataTemplates = res.data[templateType].map(data => ({
        "label": data[0],
        "value": data[1],
      }));
      let defaultTemplateKey;
      if (templateType === 'titles') {
        dataTemplates.unshift(DefaultData.defaultTitleTemplate[0]);
        defaultTemplateKey = res.data.default_title;
      } else {
        dataTemplates.unshift(DefaultData.defaultDescriptionTemplate[0]);
        defaultTemplateKey = res.data.default_description;
      }
      const standardTemplate = findDefaultTemplate(defaultTemplateKey, dataTemplates);
      setDefaultTemplate(standardTemplate)
      setTemplates(dataTemplates);
      }).catch(err => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    updateTemplates({templates, defaultTemplate, templateType});
  }, [templates, defaultTemplate])

  const findDefaultTemplate = (target, templates) => {
    const standardTemplate = templates.find(t => t.label === target)
    if (standardTemplate) {
      return standardTemplate.label;
    }
    if (templateType === 'titles') {
      return DefaultData.defaultTitleTemplate[0].label;
    }
    return DefaultData.defaultDescriptionTemplate[0].label;
  }

  const handleRemoveTemplate = (template) => {
    const removeIndex = templates.indexOf(template);
    if (removeIndex !== -1) {
      const newTemplates = deepCloneObject(templates)
      newTemplates.splice(removeIndex, 1)
      setTemplates(newTemplates);
      setDirty(true);
    }
  }

  const handleChangeTemplate = (key, value, source) => {
    const editIndex = templates.indexOf(source);
    if (editIndex >= 0) {
      const updatedTemplates = templates.map((template, index) => {
        if (index === editIndex) {
          const updatedTemplate = templates[editIndex];
          updatedTemplate[key] = value;
          return updatedTemplate;
        }
        return template;
      })
      setTemplates(updatedTemplates);
      setDirty(true);
    }
  }

  const handleChangeDefault = (newDefault) => {
    setDefaultTemplate(newDefault);
    setDirty(true);
  }
  const onClickNewTemplate = () => {
    const newLabel = `New Template ${templates.length + 1}`
    const newTemplates = deepCloneObject(templates);
    newTemplates.push({'label': newLabel, 'value': ''})
    setTemplates(newTemplates)
    setDirty(true);
  }

  return (
    <div className={'settings-template'}>
      {templateType === 'titles' ? <h2>Title template</h2> : <h2>Description template</h2>}
      {templateType === 'titles' ?
        <div className="other-settings">
          {templates.map((title, index) => (
            <TitleTemplate title={title} key={`title_${index}`}
                           handleChangeTemplate={handleChangeTemplate}
                           handleChangeDefault={handleChangeDefault}
                           defaultTemplate={defaultTemplate === title.label}
                           handleRemoveTemplate={handleRemoveTemplate}
            />
          ))}
        </div> :
        <div className="other-settings">
          {templates.map((template, index) => (
            <DescriptionTemplate description={template} key={`description_${index}`}
                                 handleChangeTemplate={handleChangeTemplate}
                                 handleChangeDefault={handleChangeDefault}
                                 defaultTemplate={defaultTemplate === template.label}
                                 handleRemoveTemplate={handleRemoveTemplate}
            />
          ))}
        </div>
      }
      <div className="btn-new-template btn secondary" onClick={onClickNewTemplate}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#EA3553" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#EA3553"/>
        </svg>
        New {templateType === 'titles' ? 'Title' : 'Description'} Template
      </div>
    </div>
  )
}

export default Template;