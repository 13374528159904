import React, {useEffect, useMemo, useRef, useState} from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import deleteRedImage from "../../assets/images/deleteRed.svg";
import editIconDark from "../../assets/images/editIconDark.svg";
import ContentState from "draft-js/lib/ContentState";
import SlidingPane from "react-sliding-pane";
import {DataField} from "./dataField";

export const DescriptionTemplate = (props) => {
  const {description, handleChangeTemplate, defaultTemplate, handleChangeDefault, handleRemoveTemplate} = props
  const [editType, setEditType] = useState('text');
  const [labelText, setLabelText] = useState(description.label);
  const [htmlValue, setHTMlValue] = useState(null);
  const [isStandard, setIsStandard] = useState(description.label === 'Standard Description');
  const [showTemplateSlidePanel, setShowTemplateSlidePanel] = useState(false);
  const [editorState, setEditorState] = useState(null)
  const textareaRef = useRef();

  useEffect(() => {
    convertHTMLToContent(description.value);
  }, [description.value])

  const handleEditorChange = (editor_State) => {
    setEditorState(editor_State);
    convertContentToHTML(editor_State.getCurrentContent());
  }

  const convertContentToHTML = (content) => {
    const value = draftToHtml(convertToRaw(content));
    if (value !== description.value) {
      setHTMlValue(value);
      handleChangeTemplate('value', value, description);
    }
  }

  const convertHTMLToContent = (html) => {
    if (html === htmlValue) {
      return;
    }
    let contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }

  const onChangeValue = (e) => {
    if (description.value !== e.target.value) {
      handleChangeTemplate('value', e.target.value, description);
    }
  }

  const onChangeEditType = (edit_state) => {
    setEditType(edit_state);
    if (edit_state === 'html') {
      convertContentToHTML(editorState.getCurrentContent());
    } else {
      convertHTMLToContent(description.value);
    }
  }

  const onChangeLabel = (e) => {
    handleChangeTemplate('label', e.target.innerText, description);
  }

  const onClickAddDataField = () => {
    convertContentToHTML(editorState.getCurrentContent());
    setShowTemplateSlidePanel(true);
  }

  const onClickMakeDescriptionDefault = (e) => {
    handleChangeDefault(description.label)
  }

  const onAddField = (fieldName) => {
    if (fieldName) {
      if (editType === 'html') {
        const selectionStart = textareaRef.current.selectionStart;
        const selectionEnd = textareaRef.current.selectionEnd;
        const newValue = description.value.substring(0, selectionStart) + `{{${fieldName}}}` +
          description.value.substring(selectionEnd, description.value.length);
        handleChangeTemplate('value', newValue, description);
        setTimeout(() => {
          textareaRef.current.focus();
          textareaRef.current.selectionStart = selectionStart + `{{${fieldName}}}`.length;
          textareaRef.current.selectionEnd = selectionStart + `{{${fieldName}}}`.length;
        }, 10);

      } else {
        const currentContent = editorState.getCurrentContent(), currentSelection = editorState.getSelection();
        const newContent = Modifier.replaceText(currentContent, currentSelection,`{{${fieldName}}}`);
        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        setEditorState(newEditorState)
        convertContentToHTML(newEditorState.getCurrentContent());
      }
    }
  }

  return (
    <div className={"template-container"}>
      { showTemplateSlidePanel &&
        <SlidingPane
          overlayClassName={"settings-sliding-overlay"}
          isOpen={showTemplateSlidePanel}
          width={"25%"}
          hideHeader={true}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setShowTemplateSlidePanel(false);
          }}
        >
          <DataField handleHide={() => {setShowTemplateSlidePanel(false)}}
                     handleAddField={onAddField}
          />
        </SlidingPane>
      }
      <div className={"description-textarea-edit"}>
        <div className="label-edit">
          <span contentEditable={!isStandard} suppressContentEditableWarning={true}
                onInput={onChangeLabel}>{labelText}</span>
          {!isStandard && <img src={editIconDark} alt=""/>}
          {defaultTemplate && <span style={{fontWeight: 700}}>&nbsp;&nbsp;(Default)&nbsp;&nbsp;</span>}
        </div>
        <div className={"edit-option-buttons"}>
          <div className={editType === "text" ? "edit-state": ""} onClick={() => onChangeEditType('text')}>Text</div>
          <div className={editType === "html" ? "edit-state": ""} onClick={() => onChangeEditType('html')}>HTML</div>
        </div>
      </div>
      <div className="fw">
        {editType === 'text' && editorState &&
          <div className={'description-editor-wrapper'}>
            <Editor
              toolbarStyle={{display: isStandard ? 'none' : 'show'}}
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              readOnly={isStandard}
            />
          </div>
        }
        {editType === 'html' &&
          <textarea value={description.value} ref={textareaRef} className={"edit-state description"} disabled={isStandard} onChange={onChangeValue}/>
        }
        <div className={'description-option-buttons'}>
          <button className="btn-template btn secondary" disabled={isStandard} onClick={onClickAddDataField}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#EA3553"/>
            </svg>
            Add Data Fields
          </button>
          {!defaultTemplate &&
            <button className={"btn-template btn secondary"} onClick={onClickMakeDescriptionDefault}>Make default</button>
          }
          {!isStandard && !defaultTemplate && <button className="btn-template btn secondary" onClick={() => {handleRemoveTemplate(description)}}>
            <img src={deleteRedImage} alt=""/>
            Remove Template
          </button>}
        </div>
      </div>
    </div>
  )
}
