import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import membershipConst from "../../../consts/membershipInformation";
import {getUserMembership} from "../../../redux/actions/profile";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import MembershipItem from "../../Settings/membershipItem";
import ConfirmMembershipChange from "./confirmMembershipChange";
import {Spinner} from "react-bootstrap";
import CancelMembership from "../cancelMembership";

const ChangeMembership = (props) => {
  const {handleHide} = props;
  const dispatch = useDispatch();
  const membership = useSelector(state => state.profile.membership)
  const [saving, setSaving] = useState(false);
  const [newMembership, setNewMembership] = useState();
  const [cancelMembership, setCancelMembership] = useState(false);
  const [showConfirmMembership, setShowConfirmMembership] = useState(false);

  const getMembershipClassName = (_membership) => {
    if (membership.membership_id === _membership.id) {
      return "membership-item currently-subscribed"
    }
    if (_membership.name.toUpperCase() === "MVP") {
      return "membership-item mvp"
    }
    return "membership-item"
  }
  const getUpgradeState = (_membership) => {
    if (membershipConst.MEMBERSHIP_KIND[_membership.name] > membershipConst.MEMBERSHIP_KIND[membership.membership_name]) {
      return 1; //upgrade
    } else if (membershipConst.MEMBERSHIP_KIND[_membership.name] < membershipConst.MEMBERSHIP_KIND[membership.membership_name]) {
      return -1; //downgrade
    } else {
      return 0; // current
    }
  }

  const onClickUpdateMembership = (_new_membership) => {
    if (getUpgradeState(_new_membership) !== 0) {
      setNewMembership(_new_membership);
      setShowConfirmMembership(true);
    }
  }

  const onClickCancelMembership = () => {
    setCancelMembership(true);
  }
  const handleCloseCancelMembership = () => {
    setCancelMembership(false);
  }

  const handleCloseConfirmMembership = () => {
    setShowConfirmMembership(false);
  }
  //
  // const handlePayment = (paymentId, email) => {
  //   ApiHelper.post(API_URL.BuySubscription, {}, {}, {
  //       membership_id: newMembership,
  //       email: email,
  //       payment_method_id: paymentId
  //     }, false).then(res=>{
  //       toastr.success('Success', `You subscribed ${membershipConst.getMembership(newMembership).name} class`);
  //       dispatch(getProfile());
  //       dispatch(getUserMembership());
  //     }).finally(() => {
  //       // setAdditionalBuyPopupVisible(0);
  //       dispatch(endPaymentAction());
  //     })
  // }
  useEffect(() => {
    dispatch(getUserMembership())
  }, [])

  if (showConfirmMembership) {
    const currentPlan = membership.membership_content.find(plan => getUpgradeState(plan) === 0);
    return (
      <ConfirmMembershipChange
        currentPlan={currentPlan}
        newPlan={newMembership}
        handleHide={handleCloseConfirmMembership}
      />
    );
  }

  if (cancelMembership) {
    return (
      <CancelMembership
        handleHide={handleCloseCancelMembership}
      />
    );
  }
  return (
    <div className="main-popup-container change-membership-popup">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Change membership</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          <div className="memberships-container">
            {membership.membership_content.length <= 0 &&
              <div className='component-loading-overlay'>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            }
            {membership.membership_content.map((_membership) => {
              return (
                <div className={getMembershipClassName(_membership)} key={_membership.name}>
                  <MembershipItem plan={_membership} />
                  <div>
                    {getUpgradeState(_membership) === 0 && <div className="btn secondary" onClick={onClickCancelMembership}>Cancel Membership</div>}
                    {getUpgradeState(_membership) === 1 &&
                      <button className="btn primary"
                              onClick={() => onClickUpdateMembership(_membership)}
                              disabled={saving}>Upgrade</button>}
                    {getUpgradeState(_membership) === -1 &&
                      <button className="btn primary"
                              onClick={() => onClickUpdateMembership(_membership)}
                              disabled={saving}>Downgrade</button>}
                  </div>
                </div>)
              }
            )}
          </div>
        </div>
      </div>
    </div>)
}

export default ChangeMembership;