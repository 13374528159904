import React, { useEffect, useState } from "react";
import {Container, Row, Col, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";

const EmailVerification = (props) => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const history = useHistory();
  
  useEffect(() => {
    const param = queryString.parse(props?.location?.search);
    setCode(param?.token);
    setEmail(param?.email);
    handleVerify(param?.token, param?.email);
  }, []);
  
  const handleVerify = (_token, _email) => {
    if (_token === '') {
      setErrMsg('Verification code is required')
      return;
    }
    setErrMsg('');
    setLoading(true);
    
    ApiHelper.post(API_URL.AccountActivate, {}, {}, {
      code: _token,
      email: _email
    }, false).then(res => {
      setLoading(false);
      toastr.success('Success!', res.data.message);
      history.push('/login')
    }).catch(err => {
      setLoading(false);
      toastr.error('Fail!', err.response.data.message);
    })
  };
  
  const handleResend = () => {
    setLoading(true);
    ApiHelper.post(API_URL.EmailResend, {}, {}, {
      email: email
    }, false).then(res => {
      setLoading(false);
      if (res.data.Status) {
        toastr.success('Success!', 'Please check your email');
      } else {
        toastr.error('Fail!', 'Your email has been already verified.');
        history.push('/login');
      }
    }).catch(err => {
      setLoading(false);
      toastr.error('Fail!', 'Failed to resend email');
    });
  }
  
  return (
    <div className="wrapper wrapper-full-page">
      <div className="auth">
        {loading &&
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
        <div className="content">
          <Container>
            {loading &&
            <div className="loading-overlay">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>}
            {!loading && errMsg !== '' &&
            <Row>
              <Col md={{span: 6, offset: 3}} sm={6}>
                <span>{errMsg}</span>
              </Col>
            </Row>
            }
          </Container>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;