import React from "react";
import checkedRedImage from "../../assets/images/checkRed.svg";
import noDarkImage from "../../assets/images/noDark.svg";

const MembershipItem = (props) => {
  const {plan} = props
  return (
    <>
      <div className="class-header">
        <p className="membership-title">{plan.name}</p>
        <p className="membership-pricing">${plan.payment}</p>
        <span>Per Month</span>
      </div>
      <div className="class-body">
        <div className="membership-features">
          <div className="membership-feature">
            <img src={checkedRedImage} alt=""/>
            <p className="feature-value">{plan.scan}</p>
            <p className="feature-title">Scans</p>
          </div>
          <div className="membership-feature">
            {!plan.tiff_support ? <img src={noDarkImage} alt=""/>: <img src={checkedRedImage} alt=""/>}
            <p className="feature-title">Tiff Support: </p>
            {!plan.tiff_support ? <p className="feature-value">No</p> : <p className="feature-value">Yes</p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default MembershipItem;