import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import BasicChart from "../../../components/Chart";

import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import linkPinkImg from "../../../assets/images/linkpink.png";
import linkBlueImg from "../../../assets/images/linkblue.png"
import linkYellowImg from "../../../assets/images/linkyellow.png"
import ColorfulChart from "../../../components/Chart/ColorfulChart";
import {useSelector} from "react-redux";


const AdminDashboard = () => {
  const [adminData, setAdminData] = useState({});
  const admin_role = useSelector(state => state.auth.admin_role)
  const history = useHistory();
  
  useEffect(() => {
    ApiHelper.get(API_URL.GetAdminDashboard).then(res => {
      setAdminData(res.data);
    })
  }, []);
  
  const {user_stats, sign_up_stats, scan_stats} = adminData;
  if (!user_stats) {
    return <></>
  }
  return (
    <div className="admin-dashboard">
      <div className="content-container">
        <div className="main_contebt_admin">
          <div className="second_heading_main">
            <h3>User Stats </h3>
          </div>
          <div className="row">
            <div className="col-md-8 chart_remove_paddinf">
              <div className="main_chart">
                <BasicChart
                  xVal={['Prospect', 'Minors', 'Rookie', 'All Star' , 'MVP' , 'HOF']}
                  yVal={[
                    user_stats?.prospect_users,
                    user_stats?.minors_users,
                    user_stats?.rookie_users,
                    user_stats?.all_star_users,
                    user_stats?.mvp_users,
                    user_stats?.hall_of_frame_users,
                  ]}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="right_admin_state">
                <div className="stat_admin_box">
                  <div className="haed_stat_b">
                    <h4>Stats</h4>
                  </div>
                  <div className="data_admin_b">
                    <div className="admin_b_list">
                      <p>Total</p>
                      <h5>{user_stats?.total_users}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Free</p>
                      <h5>{user_stats?.free_users}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Paid</p>
                      <h5>{user_stats?.paid_users}</h5>
                    </div>
                    <div className="admin_b_list">
                      <p>Active Users in Past 30 Days</p>
                      <h5>{user_stats?.past_30_users}</h5>
                    </div>
                  </div>
                  {admin_role === 3 &&
                  <div className="b_all_btn">
                    <button onClick={() => history.push('/admin/user_management')}>
                      Show All
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signup_state">
          <div className="signup_state_head">
            <h3>Sign Up Stats</h3>
          </div>
          <div className="graph_boxes">
            <div className="main_gbox">
              <div className="main_gbox_l">
                <h2>Total</h2>
                <p>{user_stats?.total_users}</p>
              </div>
              <div className="main_gbox_r pink_line">
                <p><i className='bx bxs-right-arrow-alt' /> +12,5%</p>
                <img src={linkPinkImg} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="main_gbox">
              <div className="main_gbox_l">
                <h2>Free</h2>
                <p>{user_stats?.free_users}</p>
              </div>
              <div className="main_gbox_r blue_line">
                <p><i className='bx bxs-right-arrow-alt' /> +12,5%</p>
                <img src={linkBlueImg} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="main_gbox">
              <div className="main_gbox_l">
                <h2>Paid</h2>
                <p>{user_stats?.paid_users}</p>
              </div>
              <div className="main_gbox_r yellow_line down_arrow">
                <p><i className='bx bxs-right-arrow-alt' /> +12,5%</p>
                <img src={linkYellowImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="main_graph_box_main">
            <div className="row">
              <div className="col-md-4">
                <ColorfulChart
                  text="New Users Past 24 Hours"
                  yVal={[
                    sign_up_stats.past_1_day.total,
                    sign_up_stats.past_1_day.free,
                    sign_up_stats.past_1_day.paid,
                  ]}
                />
              </div>
              <div className="col-md-4">
                <ColorfulChart
                  text="New Users Past 7 Days"
                  yVal={[
                    sign_up_stats.past_7_days.total,
                    sign_up_stats.past_7_days.free,
                    sign_up_stats.past_7_days.paid,
                  ]}
                />
              </div>
              <div className="col-md-4">
                <ColorfulChart
                  text="New Users Current Calendar Month"
                  yVal={[
                    sign_up_stats.past_this_month.total,
                    sign_up_stats.past_this_month.free,
                    sign_up_stats.past_this_month.paid,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main_bottom_page">
          <div className="main_chart">
            <div className="second_heading_main">
              <h3>Scan Stats </h3>
            </div>
            <div className="graph_boxes">
              <div className="main_gbox active">
                <div className="main_gbox_l">
                  <h2>Total Scans</h2>
                  <p>{scan_stats.total}</p>
                </div>
              </div>
              <div className="main_gbox">
                <div className="main_gbox_l">
                  <h2>Past 24 hours</h2>
                  <p>{scan_stats.past_1_day}</p>
                </div>
              </div>
              <div className="main_gbox">
                <div className="main_gbox_l">
                  <h2>Past 7 Days</h2>
                  <p>{scan_stats.past_7_days}</p>
                </div>
              </div>
              <div className="main_gbox">
                <div className="main_gbox_l">
                  <h2>Past 30 Days</h2>
                  <p>{scan_stats.past_30_scans}</p>
                </div>
              </div>
            </div>
            <BasicChart
              xVal={["Total Scans", "Past 24 Hours", "Past 7 Days", "Past 30 Days"]}
              yVal={[
                scan_stats.total,
                scan_stats.past_1_day,
                scan_stats.past_7_days,
                scan_stats.past_30_scans
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard;