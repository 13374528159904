import ACTION_TYPE from "../../consts/actions";

const initialState = {
  ebayTemplates: [],
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_EBAY_TEMPLATES:
      return {
        ...state,
        ebayTemplates: action.data
      };
    default:
      return state
  }
}

export default settingsReducer;