import React, {useState} from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg"
import {getCardSpreadColumnName} from "../../helpers/util";

const FillColumn = (props) => {
  const {saveValue, columns, handleHide} = props;
  const [columnValue, setColumnValue] = useState({
    column: 'sport',
    value: ''
  });
  const handleColumnChange = (e) => {
    setColumnValue({...columnValue, column: e.target.value});
  }
  
  const onChangeValue = (e) => {
    setColumnValue({...columnValue, value: e.target.value});
  }
  
  const handleSaveValue = () => {
    saveValue(columnValue);
    handleHide();
  }
  return (
    <div className="main-popup-container fill-column">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Fill column</h2>
          <div className="close-popup">
            <img src={closeIconBlack} alt="" onClick={handleHide}/>
          </div>
        </div>
        <div className="body">
          <div>
            <p style={{textAlign: 'center', maxWidth: '560px', marginBottom: '24px'}}>Please select the column you want to
              fill and enter value</p>
            <div className="export-batch-options-container">
              <select name="column" id="" style={{width: '100%'}} value={columnValue.column} onChange={handleColumnChange}>
                {columns.map(_column => (
                  <option value={_column} key={_column}>{getCardSpreadColumnName(_column)}</option>
                ))}
              </select>
              <div style={{width: '100%'}}>
                <label htmlFor="" style={{marginTop: '12px'}}>Value</label>
                <input type="text" style={{width: '100%'}} value={columnValue.value} onChange={onChangeValue}/>
              </div>
              <div className="btn primary" style={{width: '102px', marginTop: '12px'}} onClick={handleSaveValue}>Fill</div>
            </div>
          </div>
        </div>
    
      </div>
    </div>
  )
}
export default FillColumn;