import React, {useEffect, useState} from "react";
import {Container, Form} from "react-bootstrap";
import WelcomeCard from "../../components/WelcomeCard";
import logoImage from "../../assets/images/logo.png";

import {Link, useHistory} from "react-router-dom";
import ApiHelper from "../../helpers/apiHelper";
import {toastr} from "react-redux-toastr";
import API_URL from "../../consts/urls";

const Welcome = (props) => {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const history = useHistory();
  
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  
  useEffect(() => {
    const email = props.location.state?.email;
    if (!email) {
      history.push('/login');
    }
  }, [props.location.state, history]);
  
  const requestEmail = () => {
    const userEmail = props.location.state?.email;
    ApiHelper.post(API_URL.EmailResend, {}, {}, {
      email: userEmail
    }, false).then(res => {
      if (res.status === 202) {
        toastr.success('Success!', 'Please check your email');
      } else if (res.status === 406) {
        toastr.error('Fail!', 'You are not registered yet.');
        history.push('/register');
      } else if (res.status === 409) {
        toastr.error('Fail!', 'You are already activated.');
        history.push('/login');
      }
    }).catch(err => {
      toastr.error('Fail!', 'Failed to resend email');
    });
  }
  
  
  return (
    <div className="login-bg login_page">
      <div className="main-container login">
        <Container>
            <Form >
              <WelcomeCard
                hidden={cardAnimaton}
                textCenter
                title={
                  <div>
                    <Link to='/login'>
                      <div className="logo">
                        <img src={logoImage} alt="" />
                      </div>
                    </Link>
                    <span>Welcome</span>
                  </div>
                }
                content={
                  <div style={{textAlign: "center"}}>
                    <span>Thank you for submitting the registration form for your CardDealerPro.com account.<br/></span>
                    <span>Please check your email and click on the verification link to complete the registration process and to begin using your account.
                      </span><br/>
                    <br/>
                    <span style={{color: 'red', cursor: 'pointer'}} onClick={requestEmail}>Not received email? Request again.</span>
                  </div>
                }
                ftTextCenter
              />
            </Form>
        </Container>
      </div>
    </div>
  )
}

export default Welcome;
