import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getUserRole, loginByToken} from "../../redux/actions/auth";
import {useHistory} from "react-router";

const Membership = (props) => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(true);
  const history = useHistory();
  
  useEffect(()=> {
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    dispatch(loginByToken(token));
    dispatch(getUserRole()).then(res => {
      history.push('/settings');
    }).catch(err => {
      setRedirect(false);
    })
  })
  
  return (
    <>
    {redirect ? <div>Redirecting..</div>:
      <div>Token is invalid</div>
    }
    </>
  )
}

export default Membership;