import AuthHelper from '../../helpers/authHelper.js';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const AUTHENTICATION_ERROR = 'auth/AUTHENTICATION_ERROR';
export const LOGOUT = 'auth/LOGOUT';

export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE';
export const GET_USER_ROLE_REQUEST = 'auth/GET_USER_ROLE_REQUEST';
export const GET_USER_ROLE_SUCCESS = 'auth/GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAILURE = 'auth/GET_USER_ROLE_FAILURE';
export const GET_USER_MEMBERSHIP_REQUEST = 'auth/GET_USER_MEMBERSHIP_REQUEST';
export const GET_USER_MEMBERSHIP_SUCCESS = 'auth/GET_USER_MEMBERSHIP_SUCCESS';
export const GET_USER_MEMBERSHIP_FAILURE = 'auth/GET_USER_MEMBERSHIP_FAILURE';
export const UPDATE_CASH_STATE = 'auth/UPDATE_CASH_STATE';

export const loginByUserId = (user_id) => dispatch => {
  dispatch({type: LOGIN_REQUEST});
  return AuthHelper.adminLoginByToken(user_id)
      .then(res => {
        dispatch({
          type: LOGIN_SUCCESS,
          data: res.data,
        });
      }).catch(err => {
        dispatch({
          type: LOGIN_FAILURE,
        });
        throw(err);
      });
};

export const loginByToken = (token) => dispatch => {
  const authData = {
    token: token,
    admin_role: {},
    isAuthenticated: true,
    isLoading: false,
    errors: null,
  }
  dispatch({
    type: LOGIN_SUCCESS,
    data: authData,
  });
}

export const login = (email, password) => dispatch => {
  dispatch({type: LOGIN_REQUEST});
  return AuthHelper.login(email, password)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        data: res.data,
      });
    }).catch(err => {
      dispatch({
        type: LOGIN_FAILURE,
        data: err.response.status,
      });
      throw(err);
    });
};

export const logout = () => {
  return {
    type: LOGOUT
  }
};

export const getUserRole = () => dispatch => {
  dispatch({type: GET_USER_ROLE_REQUEST});
  return AuthHelper.getUserRole()
    .then(res => {
      dispatch({
        type: GET_USER_ROLE_SUCCESS,
        data: res.data
      });
    }).catch(err => {
      dispatch({
        type: GET_USER_ROLE_FAILURE,
      });
    });
};
//
// export const getUserMembership = () => dispatch => {
//   dispatch({type: GET_USER_MEMBERSHIP_REQUEST});
//   return AuthHelper.getUserMembership()
//     .then(res => {
//       dispatch({
//         type: GET_USER_MEMBERSHIP_SUCCESS,
//         data: res.data,
//       });
//     }).catch(err => {
//       dispatch({
//         type: GET_USER_MEMBERSHIP_FAILURE,
//       });
//     });
// };
