const BuyOptions = [
  {"amount": 19.99, "scans": 1000},
  {"amount": 49.99, "scans": 5000},
  {"amount": 99.99, "scans": 10000},
  {"amount": 199.99, "scans": 25000},
  {"amount": 349.99, "scans": 50000},
  {"amount": 599.99, "scans": 100000},
  {"amount": 4999.99, "scans": 1000000},
]

const adminRoles = [
  { value: 1, label: "Moderator" },
  { value: 3, label: "Admin" },
]

const modalTypes = {
  delete_collection: { action: 'DELETE COLLECTION', cancel: 'CANCEL', title: 'Alert', msg: 'Are you sure you want to remove current collection?', type: "alert_yes_no"},
  delete_checklist:  { action: 'DELETE CHECKLIST', cancel: 'CANCEL', title: 'Alert', msg: 'Are you sure you want to remove this CheckList?', type: "alert_yes_no"},
  remove_batch: { action: 'DELETE BATCH', cancel: 'KEEP BATCH', title: 'Alert',
    msg: 'Are you sure you want to delete this batch? All information and images will be deleted and can not be recovered.', type: "alert_yes_no"
  },
  delete_cards: { action: 'DELETE CARDS', cancel: 'CANCEL', title: 'Alert', msg: 'Are you sure you want to delete these cards?', type: "alert_yes_no"},
  suspend_user: { action: 'SUSPEND', cancel: 'CANCEL', title: "Alert", msg: "Are you sure you want to suspend this user?", type: "alert_yes_no"},
  activate_user: { action: 'ACTIVATE', cancel: 'CANCEL', title: "Alert", msg: "Are you sure you want to activate this user?", type: "alert_yes_no"},
  reset_password: { action: 'SET', cancel: 'CANCEL', title: "Alert", msg: "Are you sure you want to set password for this user as default (123456789) ?", type: "alert_yes_no"},
  retire_cards: { action: 'RETIRE', cancel: 'CANCEL', title: "Alert", msg: "Are you sure you want to retire current cards?", type: "alert_yes_no"},
  delete_ebay_template: { action: 'YES, I am sure', cancel: 'CANCEL', title: "", msg: "Are you sure you want to delete this template?", type: "alert_yes_no"},
  leave_changes: { action: "YES, I'M SURE", cancel: 'NO, CANCEL', title: "You have unsaved changes", msg: "Are you sure you want to leave without saving?", type: "alert_yes_no"},
  export_condition: { action: 'Override & Export', warn: 'Go Back',
    title: "",
    subtitle: "Some data doesn't meet the requirement",
    msg: "We have identified some issues that will prevent your eBay upload from being successfully processed. We recommend going back and viewing the errors that are marked in red within the spreadsheet view. You can override this warning and download the CSV without fixing, but this will likely cause errors if uploaded to eBay.",
    type: "notice_yes_no"},
}

const batchTemplateFilter = [
  {key: 'ebay', exportType:'ebay', batchFieldsOption: 'eBay', exportSettingOption: 'ebay_export', exportOptionLabel: 'eBay CSV', image_hosted: false},
  {key: 'shopify', exportType:'shopify', batchFieldsOption: 'Shopify', exportSettingOption: 'shopify_export', exportOptionLabel: 'Shopify CSV', image_hosted: false},
  {key: 'simple_auction', exportType: 'auction', batchFieldsOption: 'Simple Auction', exportSettingOption: 'auction_export', exportOptionLabel: 'Simple Auction CSV', image_hosted: false},
  {key: 'custom_csv', exportType: 'custom', batchFieldsOption: 'Custom CSV', exportSettingOption: 'custom_export', exportOptionLabel: 'Custom CSV', image_hosted: false},
  {key: 'all', exportType: 'standard', batchFieldsOption: 'All Fields', exportSettingOption: 'standard', exportOptionLabel: 'All Data & Images', image_hosted: true}
];

const ebayTemplateDurationValues = {
  all: ["3 Days", "5 Days", "7 Days", "10 Days", "30 Days"],
  fixed: ["Good Till Canceled"]
};
const ebayTemplateHandlingTimeValues = [
  1, 2, 3, 4, 5, 10, 15, 20, 30
];
const ebayTemplateShippingServices = [
  "eBay Standard Envelope",
  "USPS Priority Mail",
  "USPS First Class Mail",
  "Fedex Ground",
  "Fedex Express Saver",
  "Fedex 2 Day",
  "FedEx Overnight",
  "UPS Ground",
  "UPS 2 Day",
  "UPS 3 Day",
  "UPS Next Day"
];
const ebayTemplateIntervals = [
  {key: 'minute', value: 'Minute'},
  {key: 'second', value: 'Second'}
];
const ebayTemplateRefundValues = [
  "Money Back", "Buyers Choice Refund or Exchange", "Sellers Choice Refund or Exchange"
];

const ebayTemplateReturnsWithIn = [
  14, 30, 60
];

const ebayTemplateReturnShippingValues = [
  "Buyer", "Seller"
];

const defaultSpreadColumnWidth = {
  'sport': 160,
  'year': 70,
  'card': 80,
  'brand': 175,
  'player': 175,
  'subset': 175,
  'team': 175,
  'attribute': 130,
  'variation': 130,
  'title': 350,
  'flag': 70,
  'grade': 145,
  'numeric_grade': 135,
  'purchase_price': 135,
  'description': 200
}

const toolTips = {
  'green': `Green - All card data is valid and eBay will accept your export.`,
  'yellow': `Yellow - Ebay will accept your export, but we recommend updating the optional fields.`,
  'red': `Red - Some required fields are missing or invalid and eBay will not accept your export`
}
toolTips.enableFlags = toolTips.green + '<br />' + toolTips.yellow + '<br />' + toolTips.red;

const batchCategories = [
  { key: 'Multiple Sports', value: 'Multiple Sports(default)' },
  { key: 'Baseball', value: 'Baseball' },
  { key: 'Football', value: 'Football' },
  { key: 'Basketball', value: 'Basketball' },
  { key: 'Hockey', value: 'Hockey'}];

const flagColor = {
  RED: '#EA3553',
  YELLOW: '#ffa534',
  GREEN: '#6F9A28'
}

const UIConst = {BuyOptions, adminRoles, modalTypes, batchTemplateFilter,
  ebayTemplateDurationValues, ebayTemplateHandlingTimeValues,
  ebayTemplateRefundValues, ebayTemplateReturnShippingValues,
  defaultSpreadColumnWidth, ebayTemplateIntervals,
  ebayTemplateReturnsWithIn, ebayTemplateShippingServices,
  toolTips, batchCategories, flagColor
};
export default UIConst;