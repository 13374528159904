import React, {useState} from "react";
import ApiHelper from "../../../helpers/apiHelper";
import {toastr} from "react-redux-toastr";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import API_URL from "../../../consts/urls";
import membershipConst from "../../../consts/membershipInformation";

export const ChangeMembershipModal = props => {
  const {handleHide, handleAction, id} = props;
  const [membership, setMembership] = useState(1);
  const [error, setError] = useState(null);
  
  const handleSelectMembership = (e) => {
    setMembership(parseInt(e.target.value))
  }
  const handleMembership = () => {
    if (membership === '') {
      setError("Please select a membership");
    } else {
      ApiHelper.put(API_URL.UpdateUserMembership, {}, {}, {
        user_id: id,
        membership_id: membership
      }).then(res => {
        toastr.success("Success!", "Membership was successfully changed.");
        handleHide();
        handleAction();
      }).catch(err => {
        toastr.error("Fail!", "Please try again.");
        handleHide();
      })
    }
  }
  return (
    <div className="main-popup-container change-membership">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Override Membership Level</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <div className="main_modal_data nor_space_btns">
            <p className="collect_modal_coll chnage_fonts_size">
              Override user membership level regardless of what they are paying.</p>
            <div className="select-membership">
              <select placeholder="Please select..." style={{height: '30px'}}
                      value={membership}
                      onChange={handleSelectMembership}>
                {membershipConst.MEMBERSHIP_OPTIONS.map((_option, index) =>
                  <option value={_option.value} key={_option.value}>{_option.label}</option>)
                }
              </select>
              <div className="select-button">
                <div className="btn primary" onClick={handleMembership}>
                  Change
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
