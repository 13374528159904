import React, {useEffect, useState} from "react";
import {toastr} from "react-redux-toastr";
import logoImg from "../../assets/images/logo.png"
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {validateEmail} from "../../helpers/commonHelper";
import supportedPaymentImg from "../../assets/images/supportedPaymentMethods.png";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/Payment/checkoutForm";
import {Link, useHistory} from "react-router-dom";
import {getDashboardMembership} from "../../redux/actions/profile";
import {useDispatch, useSelector} from "react-redux";
import {beginPaymentAction, endPaymentAction} from "../../redux/actions/payment";
import MembershipItem from "../../components/Settings/membershipItem";


const Register = (props) => {
  const history = useHistory();
  const [registerInfo, setRegisterInfo] = useState({
    username: '',
    business_name: '',
    password: '',
    email: '',
    first_name: '',
    last_name: '',
    city: '',
    state: '',
    zip_code: '',
    promo_code: ''
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setErrorText] = useState('');
  const [registerStep, setRegisterStep] = useState("2");
  const [registerMembership, setRegisterMembership] = useState("1");
  const dispatch = useDispatch();
  const membershipContent = useSelector(state => state.profile.membership.membership_content)
  useEffect(() => {
    if (error !== '') {
      toastr.error('Warning', error);
    }
  }, [error]);
  
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const step_id = params.get('step');
    if (step_id) {
      setRegisterStep(step_id)
    } else {
      setRegisterStep("1")
    }
    const email = props.location.state?.email
    if (email && step_id !== '1') {
      setRegisterInfo({
        ...registerInfo, email: email
      });
    }
    dispatch(getDashboardMembership());
  }, [dispatch, props.location.search]);
  
  const handleChangeTerms = (e) => {
    setTerms(!terms);
  }

  const validateRegisterInfo = () => {
    if (registerInfo.first_name === '') {
      setErrorText('First name is required');
      return false;
    }

    if (registerInfo.last_name === '') {
      setErrorText('Last name is required');
      return false;
    }

    if (!validateEmail(registerInfo.email)) {
      setErrorText('Email is invalid');
      return false;
    }

    if (registerInfo.email !== confirmEmail) {
      setErrorText('Email is mismatch');
      return false;
    }

    if (registerInfo.password !== confirmPassword) {
      setErrorText('Password is mismatch');
      return;
    }
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(registerInfo.zip_code);
    if (!isValidZip) {
      setErrorText('Zip code is not valid');
      return false;
    }
    if (terms === false) {
      setErrorText('You should accept Terms & Conditions');
      return false;
    }
    return true;
  }
  const handleRegister = (e) => {
    if (!validateRegisterInfo()) {
      return;
    }
    let registerFormData = new FormData();
    for (let _key in registerInfo) {
      registerFormData.append(_key, registerInfo[_key]);
    }
    
    registerFormData.append('username', registerInfo.email);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    ApiHelper.post(API_URL.Register, {}, config, registerFormData, false)
      .then(res => {
        toastr.success('Success', 'Welcome');
        setRegisterStep("2");
      })
      .catch(err => {
        if (err.response.status === 409) {
          toastr.error('Fail!', 'You have been already registered');
          history.push('/login');
        }
      });
  }
  const onChangeRegisterInfo = (e) => {
    let newRegisterInfo = {...registerInfo};
    newRegisterInfo[e.target.name] = e.target.value;
    setRegisterInfo(newRegisterInfo);
    setErrorText('');
  }
  
  const onChangeEmailConfirm = (e) => {
    setConfirmEmail(e.target.value);
    setErrorText('');
  }
  const onChangeConfirmPassword = (e) => {
    setErrorText('');
    setConfirmPassword(e.target.value);
  }
  
  const handleSelectMembership = (_id) => {
    setRegisterMembership(_id);
    setRegisterStep("3");
  }
  
  const handleFinishRegister = (paymentId, email) => {
    if (!paymentId || email === "" || !email) {
      toastr.info('Please select valid payment method and input valid Email');
      dispatch(endPaymentAction());
      return;
    }
    dispatch(beginPaymentAction());
    ApiHelper.post(API_URL.BuySubscription, {}, {}, {
      membership_id: registerMembership,
      email: email,
      payment_method_id: paymentId
    }, false).then(res=>{
      toastr.success('Success', 'Your account is successfully registered');
      history.push('/login');
    }).finally(() => {
      dispatch(endPaymentAction());
    })
  }
  
  const getSelectedMembership = (_membership_id) => {
    const data = membershipContent?.filter((_membership) => _membership.id === _membership_id);
    // membershipConst.MEMBERSHIP_CONTENT
    return data[0];
  }
  
  const getMembershipClassName = (_membership) => {
    if (_membership.name.toUpperCase() === "MVP") {
      return "membership-item mvp"
    }
    return "membership-item"
  }
  
  return (
    <div className={"register_page"}>
      <div className="main-container register">
        <div className="sec-container">
          <div className="logo">
            <img src={logoImg} alt="" onClick={() => history.push('/login')}/>
          </div>
          {registerStep === "1" && <div className="register-container first">
            <div className={"step-title"}>
              <h1>Register</h1>
              <p>Step 1 of 3</p>
            </div>
            <form action="">
              <div className="form-container-main">
                <div className="input text">
                  <label htmlFor="first_name">First name</label>
                  <input type="text" name="first_name" id="first_name" value={registerInfo.first_name} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="last-name">Last name</label>
                  <input type="text" name="last_name" id="last-name" value={registerInfo.last_name} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" id="email" value={registerInfo.email} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="email_confirm">Confirm Email</label>
                  <input type="text" name="email_confirm" id="email_confirm" value={confirmEmail} onChange={onChangeEmailConfirm}/>
                </div>
                <div className="input text">
                  <label htmlFor="business_name">Business Name</label>
                  <input type="text" name="business_name" id="business_name" value={registerInfo.business_name} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="city">City</label>
                  <input type="text" name="city" id="city" value={registerInfo.city} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="state">State</label>
                  <input type="text" name="state" id="state" value={registerInfo.state} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text">
                  <label htmlFor="zip_code">Zip</label>
                  <input type="text" name="zip_code" id="zip_code" value={registerInfo.zip_code} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text password">
                  <label htmlFor="password">Password</label>
                  <input type="password" name="password" id="password" value={registerInfo.password} onChange={onChangeRegisterInfo}/>
                </div>
                <div className="input text confirm-password">
                  <label htmlFor="confirm-password">Confirm password</label>
                  <input type="password" name="confirm-password" id="confirm-password" value={confirmPassword} onChange={onChangeConfirmPassword}/>
                </div>
                <div className="input text">
                  <label htmlFor="promo_code">Promo/Referral code</label>
                  <input type="text" name="promo_code" id="promo_code" value={registerInfo.promo_code} onChange={onChangeRegisterInfo}/>
                </div>
              </div>
              <div className=""/>
              <div className="agreement">
                <div className={'custom-control custom-checkbox'}>
                  <input type="checkbox" className="custom-control-input" name="accept-terms" id={"accept-terms"}
                         onChange={handleChangeTerms} checked={terms}/>
                  <label className="custom-control-label" htmlFor="accept-terms">I have read the <a href="/">terms & conditions</a></label>
                </div>
              </div>
              
              <div className="btn primary" onClick={handleRegister}>register</div>
            </form>
            <div className="no-account-yet">
              <span>Already have an account?</span> <Link to="/login">Login</Link>
            </div>
          </div>}
          {registerStep === "2" && <div className="register-container second">
            <div className={"step-title"}>
              <h1>Select Membership</h1>
              <p>Step 2 of 3</p>
            </div>
            <div className="memberships">
              <div className="memberships-container">
                {membershipContent.map((_membership, index) => {
                    return (
                      <div className={getMembershipClassName(_membership)} key={_membership.name}>
                        <MembershipItem plan={_membership} />
                        {index === 0 ? <p className="trial-text">7 days free trial</p>: <p className="trial-text">&nbsp;&nbsp;</p>}
                        <div className="btn primary" onClick={()=>handleSelectMembership(_membership.id)}>Select</div>
                      </div>)
                  }
                )}
              </div>
            </div>
          </div>}
          {registerStep === "3" && <div className="register-container first">
            <div className="supported-methods">
              <img src={supportedPaymentImg} alt="" />
            </div>
            <div className={"step-title"}>
              <h1>Enter payment details</h1>
              <p>Step 3 of 3</p>
            </div>
            
            <div className="membership-info">
              <div>
                <p>Membership selected:</p>
                <h3>{getSelectedMembership(registerMembership).name} (Monthly plan) <a style={{marginLeft: '8px', fontSize: '13px', color: 'red'}} onClick={()=>{setRegisterStep("2")}}>Change</a></h3>
              </div>
              <div>
                <p>Payment:</p>
                <h3>7 days free trial then ${getSelectedMembership(registerMembership).payment} per month</h3>
                <p style={{fontSize: '13px',  opacity: '0.7'}}>You will not be charged if you cancel the membership within the
                  trial period of 7 days</p>
              </div>
            </div>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
              <CheckoutForm email={registerInfo.email} action={handleFinishRegister}/>
            </Elements>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Register;