import React from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg";
import UIConst from "../../consts/UIConst";

export const AlertYesNoModal = React.memo(props => {
  const {handleHide, handleAction, handleWarning, modalType} = props;
  return (
    <div className="main-popup-container alert">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2 style={{margin: 'auto'}}>{UIConst.modalTypes[modalType].title}</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          <div className="main_modal_data">
            <h2>{UIConst.modalTypes[modalType]?.subtitle}</h2>
            <p>{UIConst.modalTypes[modalType].msg}</p>
          </div>
          <div className={`popup-yes-no-button ${UIConst.modalTypes[modalType].type}`}>
            {UIConst.modalTypes[modalType]?.warn &&
              <div className="btn warning" onClick={handleWarning}>{UIConst.modalTypes[modalType].warn}</div>
            }
            <div className="btn primary" onClick={handleAction}>{UIConst.modalTypes[modalType].action}</div>
            {UIConst.modalTypes[modalType]?.cancel &&
              <div className="btn cancel" onClick={handleHide}>{UIConst.modalTypes[modalType].cancel}</div>
            }
          </div>
        </div>
      </div>
    </div>
  )
});