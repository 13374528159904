import React, { useState } from "react";
import CustomLazyLoadImage from "../LazyLoadImage";
import warningRed from "../../assets/images/warningRed.svg";
import {useHistory} from "react-router";
import lockOpen16 from "../../assets/images/lockOpen16.svg";
import lockClosedIcon from "../../assets/images/lockClosedIcon.svg";
import ApiHelper from "../../helpers/apiHelper";
import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";

const BatchGalleryView = ({batchDetailData, batch_id, setBatchDetailData}) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const handleCardDetail = (card_id, status) => {
    history.push({
      pathname: `/batches/${batch_id}/${card_id}`,
      search: '?' + new URLSearchParams({popup: 0, status: status}).toString()
    });
  }

  const handleCardSelect = (e, card_id) => {
    e.stopPropagation();
    let newCardsData = batchDetailData.cards.map(card => {
      if (card.card_id === card_id) {
        if (card.selected !== undefined) {
          return {...card, selected: !card.selected}
        } else {
          return {...card, selected: true}
        }
      } else {
        return card;
      }
    })
    setBatchDetailData({
      ...batchDetailData,
      cards: newCardsData
    });
  }
  const getCardStateClassName = (card_info) => {
    let _clsName = "batch-card";
    if (card_info.status === 0) {
      _clsName = _clsName + " not-matched"
    }
    if (card_info.selected) {
      _clsName = _clsName + " selected"
    }
    return _clsName;
  }

  const onClickResubmit = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    ApiHelper.post(API_URL.ResubmitBatch, {}, {}, {
      batch_id: batch_id,
    }).then(res => {
      toastr.success('Success', res.data.message);
      history.push({
        pathname: `/batches/`
      });
    }).finally(() => {
      setLoading(false);
    })
  }
  return (
    <>
      <div className="sec-header">
        <div className="batch-details-info">
          <div className="info-item">
            <span>Cards</span>
            {batchDetailData.list.total_cards !== '' ? <p>{batchDetailData.list.total_cards}</p> : <p>&nbsp;</p>}
          </div>
          <div className="info-item">
            <span>Category</span>
            {batchDetailData.list.category !== '' ? <p>{batchDetailData.list.category}</p> : <p>&nbsp;</p>}
          </div>
          <div className="info-item">
            <span>Created On</span>
            {batchDetailData.list.created_date !== '' ? <p>{batchDetailData.list.created_date}</p> : <p>&nbsp;</p>}
          </div>
          <div className="info-item">
            <span>Modified On</span>
            {batchDetailData.list.modified_date !== '' ? <p>{batchDetailData.list.modified_date}</p> : <p>&nbsp;</p>}
          </div>
          <div className={batchDetailData.list.status === "Open" ? "info-item status open" : "info-item status closed"}>
            <span>Status</span>
            <div>
              {batchDetailData.list.status === 'Open' ? <img src={lockOpen16} alt=""/> : <img src={lockClosedIcon} alt=""/>}
              {batchDetailData.list.status !== '' ? <p>{batchDetailData.list.status}</p> : <p>&nbsp;</p>}
            </div>
          </div>
        </div>
        <div className="batch-details-buttons">
          <button className={'btn secondary'} onClick={onClickResubmit}>Resubmit</button>
        </div>
      </div>
      <div className="batches-container">

      {batchDetailData.cards.map((_batchCard) => {
        return (
          <div className={getCardStateClassName(_batchCard)} key={_batchCard.card_id}
               onClick={() => handleCardDetail(_batchCard.card_id, _batchCard.status)}>
            <div className="batch-card-images">
              <div className="front">
                <CustomLazyLoadImage src={_batchCard.front_image} effect={"blur"} alt=""/>
              </div>
              <div className="back">
                <CustomLazyLoadImage src={_batchCard.back_image} effect={"blur"} alt=""/>
              </div>
            </div>
            {_batchCard.status === 0 ?
              <p className="batch-card-name">
                <img src={warningRed} alt=""/>
                <a href="">Manual Search Required</a>
              </p> :
              <p className="batch-card-name">{_batchCard.title}</p>
            }
            <div className="batch-card-select" onClick={(e) => handleCardSelect(e, _batchCard.card_id)}/>
          </div>
        );
      })
      }
    </div>
    </>
  )
}

export default BatchGalleryView;