import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {AlertYesNoModal} from "./alert";


const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible &&
        <AlertYesNoModal
        handleAction={handleConfirmNavigationClick}
        handleHide={closeModal}
        modalType="leave_changes"
      />}
    </>
  );
};
export default RouteLeavingGuard;