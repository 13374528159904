import React, { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import EbayTemplateForm from "../../Settings/Export/ebayTemplateForm";
import DefaultData from "../../../consts/defaultData";
import {getEBayTemplates} from "../../../redux/actions/settings";
import {toastr} from "react-redux-toastr";
import {deepCloneObject} from "../../../helpers/commonHelper";


const NewEbayTemplate = ({handleHide}) => {
  const [creating, setCreating] = useState(false);
  const [newEbayTemplateInfo, setNewEbayTemplateInfo] = useState(DefaultData.defaultEbayTemplate);
  const dispatch = useDispatch();
  const onClickCreateTemplate = () => {
    if (creating) {
      return;
    }
    setCreating(true);
    const templateParams = deepCloneObject(DefaultData.defaultEbayTemplate);
    for (const k in newEbayTemplateInfo) {
      templateParams[k] = newEbayTemplateInfo[k];
    }
    ApiHelper.post(API_URL.EbayTemplate, {}, {}, templateParams).then(res => {
      dispatch(getEBayTemplates());
      handleHide();
    }).catch(err => {
      toastr.error('Error', err.response.data.message);
    }).finally(() => {
      setCreating(false);
    })
  }
  return (
    <div className="main-popup-container new-ebay-template-container">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Create eBay Template</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          <EbayTemplateForm ebayTemplateData={DefaultData.defaultEbayTemplate} onChange={setNewEbayTemplateInfo}/>
          <button className={'btn primary btn-create'} onClick={onClickCreateTemplate}>Create Template</button>
        </div>
      </div>
    </div>
  )
}

export default NewEbayTemplate;