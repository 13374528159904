import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import EbayTemplateForm from "../../Settings/Export/ebayTemplateForm";
import DefaultData from "../../../consts/defaultData";
import {getEBayTemplates} from "../../../redux/actions/settings";
import ApiHelper from "../../../helpers/apiHelper";
import API_URL from "../../../consts/urls";
import {ReactComponent as BackIcon} from "../../../assets/images/breadCrumbIcon.svg";
import UIConst from "../../../consts/UIConst";
import axios from "axios";
import AuthHelper from "../../../helpers/authHelper";
import {toastr} from "react-redux-toastr";
import FileDownload from "js-file-download";
import {isJsonBlob} from "../../../helpers/commonHelper";
import {Spinner} from "react-bootstrap";



const EbayExport = ({handleHide, batchId, batchName, data}) => {
  const dispatch = useDispatch();
  const ebayTemplates = useSelector(state => state.settings.ebayTemplates);
  const [currentOperationTemplateDetail, setCurrentOperationTemplateDetail] = useState(DefaultData.defaultEbayTemplate);
  const [modifiedTemplate, setModifiedTemplate] = useState(DefaultData.defaultEbayTemplate);
  const [exportStep, setExportStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    schedule_set_all: true,
    live_immediately: true,
    schedule_interval: 'Minutes',
    schedule_delay: 10,
  });

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    dispatch(getEBayTemplates());
  }, [dispatch]);

  const onChangeCurrentTemplate = (e) => {
    setSelectedTemplate(e.target.value);
  }

  const getEBayTemplateDetail = (templateId) => {
    if (loading) {
      return;
    }
    setLoading(true)
    ApiHelper.get(API_URL.EbayTemplateDetail, {
      template_id: templateId
    }, {}, {}).then(res => {
      setCurrentOperationTemplateDetail(res.data.template[0]);
      setModifiedTemplate(res.data.template[0]);
    }).catch(e => {
      setCurrentOperationTemplateDetail(DefaultData.defaultEbayTemplate);
      setModifiedTemplate(DefaultData.defaultEbayTemplate);
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    if (selectedTemplate !== -1) {
      getEBayTemplateDetail(selectedTemplate);
    } else {
      setCurrentOperationTemplateDetail(DefaultData.defaultEbayTemplate)
      setModifiedTemplate(DefaultData.defaultEbayTemplate);
    }
  }, [selectedTemplate, dispatch]);

  useEffect(() => {
    if (ebayTemplates.length > 0) {
      let defaultTemplate = ebayTemplates.find(t => t.default);
      if (!defaultTemplate) {
        defaultTemplate = ebayTemplates[0];
      }
      setSelectedTemplate(defaultTemplate.id);
      return;
    }
    setSelectedTemplate(-1)
  }, [ebayTemplates]);

  const onClickBack = () => {
    setExportStep(exportStep - 1);
  }
  const onClickNext = () => {
    if (exportStep === 0) {
      setCurrentOperationTemplateDetail(modifiedTemplate)
    }
    setExportStep(exportStep + 1);
  }

  const onChangeSettings = (name, value) => {
    setSettings({
      ...settings,
      [name]: value
    })
  }

  const validateInput = () => {
    let params = { ...modifiedTemplate, ...settings, batch: parseInt(batchId) };
    if (settings.live_immediately) {
      params.schedule_date = params.schedule_time = '';
    } else {
      params.schedule_date = moment(startDate).format('YYYY-MM-DD');
      if (typeof startTime === 'string') {
        params.schedule_time = startTime;
      } else {
        params.schedule_time = moment(new Date()).format('HH:mm:ss');
      }

        // schedule_set_all: settings.schedule_set_all,
        // schedule_interval: settings.schedule_interval,
        // schedule_delay: settings.schedule_delay,
        // live_immediately:settings.live_immediately,

        // zip_code: modifiedTemplate.zip_code,
        // store_category: modifiedTemplate.store_category,
        // listing_type: modifiedTemplate.listing_type,
        // duration: modifiedTemplate.duration,
        // shipping_service: modifiedTemplate.shipping_service,
        // free_shipping: modifiedTemplate.free_shipping,
        // shipping_cost: modifiedTemplate.shipping_cost,
        // handling_time: modifiedTemplate.handling_time,
        // returns: modifiedTemplate.returns,
        // returns_within: modifiedTemplate.returns_within,
        // refund_given_as: modifiedTemplate.refund_given_as,
        // return_shipping_paid_by: modifiedTemplate.return_shipping_paid_by,
        // additional_return_info: modifiedTemplate.additional_return_info,
        // listing_price: modifiedTemplate.listing_price,
        // fixed_amount_to_price: modifiedTemplate.fixed_amount_to_price,
        // percent_to_price: modifiedTemplate.percent_to_price,
        // allow_best_offer: modifiedTemplate.allow_best_offer,
        // auto_accept_offer: modifiedTemplate.auto_accept_offer,
        // auto_decline_offer: modifiedTemplate.auto_decline_offer,
        // start_price: modifiedTemplate.start_price,
        //   calculated_method: modifiedTemplate.calculated_method,
        //   enable_buy_it_now_price: modifiedTemplate.enable_buy_it_now_price,
    }
    return params;
  }
  const onClickCreateAndDownload = () => {
    const params = validateInput();
    if (loading) {
      return;
    }
    setLoading(true);
    axios.post(API_URL.ExportEbay, params, {
      headers: {'Authorization': `Token ${AuthHelper.getAccessToken()}`},
      responseType: 'blob'
    }).then(async(res) => {
      const dateTime1 = moment().format("MM-DD-YY");

      const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

      if ("message" in responseJson) {
        toastr.info(responseJson.message);
        return;
      }
      FileDownload(res.data, `${batchName}-Export-${dateTime1}.csv`);
      toastr.success('',`${batchName}-Export-${dateTime1}.csv is successfully downloaded`);
    }).catch(async(err) => {
      const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

      toastr.error('error', `Failed to export collection [${batchName}]. ${responseJson.message}`);
    }).finally(() => {
      setLoading(false)
    });
  }

  return (
    <div className="main-popup-container ebay-export">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>eBay Export</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt="" />
          </div>
        </div>
        <div className="body">
          {loading &&
            <div className='component-loading-overlay'>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          }
          {exportStep === 0 &&
            <div className={"current-template-container"}>
              <div className="fw">
                <label htmlFor="">Select ebay Template</label>
                <select name="current_template" id="current_template" onChange={onChangeCurrentTemplate}
                        value={selectedTemplate}>
                  {ebayTemplates.map(t => (<option value={t.id} key={`template_${t.id}`}>{t.name}</option>))}
                </select>
              </div>
              <EbayTemplateForm ebayTemplateData={currentOperationTemplateDetail} isBatchExport={true} onChange={setModifiedTemplate}/>
            </div>
          }
          {exportStep === 1 &&
            <div className={"scheduling-container"}>
              <div className="ebay-template-info">
                <div className="param-section">
                  <h3>Listing Schedule</h3>
                </div>
                <div className="param-section">
                  <div className="params-container">
                    <div className={'custom-control custom-radio'}>
                      <input type="radio" className="custom-control-input" checked={settings.live_immediately}
                             name="live_immediately" value={"Live Immediately"} id={"live_immediately"} onChange={() => onChangeSettings('live_immediately', true)} />
                      <label className="custom-control-label" htmlFor="live_immediately">Live Immediately</label>
                    </div>
                    <div className={'custom-control custom-radio'}>
                      <input type="radio" className="custom-control-input" checked={!settings.live_immediately}
                             name="live_immediately" value={"Schedule Time"}
                             id={"schedule_start_time"} onChange={() => onChangeSettings('live_immediately', false)} />
                      <label className="custom-control-label" htmlFor="schedule_start_time">Schedule start time</label>
                    </div>
                  </div>
                </div>
              </div>
              {!settings.live_immediately &&
              <div className={'ebay-template-info'}>
                <div className="param-section">
                  <div className="params-container">
                    <div className="fw">
                      <label htmlFor="schedule_date">Date</label>
                      <DatePicker id="schedule_date" selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="fw">
                      <label htmlFor="schedule_time">Time</label>
                        <TimePicker
                          amPmAriaLabel="Select AM/PM"
                          clearAriaLabel="Clear value"
                          clockAriaLabel="Toggle clock"
                          hourAriaLabel="Hour"
                          maxDetail="second"
                          minuteAriaLabel="Minute"
                          nativeInputAriaLabel="Time"
                          onChange={setStartTime}
                          secondAriaLabel="Second"
                          value={startTime}
                        />
                    </div>
                  </div>
                </div>
                <div className="param-section">
                  <div className="params-container">
                    <div className={'custom-control custom-radio'}>
                      <input type="radio" className="custom-control-input" checked={settings.schedule_set_all}
                             name="schedule_set_all" value={"set_for_all"} id={"set_for_all"} onChange={() => onChangeSettings('schedule_set_all', true)} />
                      <label className="custom-control-label" htmlFor="set_for_all">Set for all</label>
                    </div>
                    <div className={'custom-control custom-radio'}>
                      <input type="radio" className="custom-control-input" checked={!settings.schedule_set_all}
                             name="schedule_set_all" value={"set_staggered"}
                             id={"set_staggered"} onChange={() => onChangeSettings('schedule_set_all', false)} />
                      <label className="custom-control-label" htmlFor="set_staggered">Staggered</label>
                    </div>
                  </div>
                </div>
                {!settings.schedule_set_all &&
                <div className="param-section">
                  <div className="params-container">
                    <div className="fw">
                      <label htmlFor="schedule_interval">Interval</label>
                      <select name="schedule_interval" id="schedule_interval" onChange={(e) => onChangeSettings('schedule_interval', e.target.value)}
                              value={settings?.schedule_interval}>
                        {UIConst.ebayTemplateIntervals.map(v => (<option value={v.key} key={v.key}>{v.value}</option>))}
                      </select>
                    </div>
                    <div className="fw">
                      <label htmlFor="delay">Delay</label>
                      <input type="text" name="schedule_delay" min='1' id="delay" onChange={(e) => onChangeSettings('schedule_delay', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))}/>
                    </div>
                  </div>
                  <span className={'stagger-description span-optional'}>Staggered setting will set the start time for the first listing as the date/time selected, and then will set the next listing to be delayed by<br/>
                      the set interval time as indicated.
                      For example, if I set the start time as today at 12:00:00pm and the interval as seconds and the delay as 30.
                      The first listing start time will
                      be today at 12:00:00.  Next listing will be 12:00:30.  Next listing will be 12:01:00, etc.</span>
                </div>
                }
            </div>}
          </div>}
          {exportStep === 2 &&
            <div className={'export-listing'}>
              <p>You will be generating with following settings</p>
              <div className={'export-listing-details'}>
                <p>Listings: {data.length}</p>
                <p>Format: {modifiedTemplate.listing_type}</p>
                <p>Schedule: {settings.live_immediately ? "Live immediately" : "Schedule start time"}</p>
              </div>
            </div>}
        </div>
        <div className="footer">
            {(exportStep >= 1) &&
              <div className={'go-back'} onClick={onClickBack}>
                <BackIcon className={"flipped"} fill={"#EA3553"}/>
                <p>Go back</p>
              </div>
            }
            {(exportStep === 0 || exportStep === 1) &&
              <button className={'btn primary btn-next'} onClick={onClickNext}>Next</button>}
            {(exportStep === 2) &&
              <button className={'btn primary btn-next'} onClick={onClickCreateAndDownload}>Create & Download CSV</button>}
        </div>
      </div>
    </div>
  )
}

export default EbayExport;