import React from "react";
import Chart from "react-apexcharts";

const ColorfulChart = props => {
  const {text, yVal } = props;

  const state = {
    series: [{
      name: 'Contrats',
      data: yVal
    }],
    options: {
      chart: {
        type: 'bar',
        // height: '380px',
        // toolbar: { show: false },
        // foreColor: '#657a8e',
      },
      legend: { show: false },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
          distributed: true,
          colors: {
            backgroundBarColors: ['#F2F4F6']
          },
        }
      },
      xaxis: {
        categories: ["Total", "Free", "Paid"],
        labels: {
          style: {
            fontSize: '10px',
            rotate: 0,
          }
        }
      },
      title: {
        text: text
      },
    }
  }

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      width="100%"
      height="370px"
    />
  )
}

export default ColorfulChart;