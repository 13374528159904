import React, {useState} from "react";
import ApiHelper from "../../../helpers/apiHelper";
import {toastr} from "react-redux-toastr";
import closeIconBlack from "../../../assets/images/closeIconBlack.svg";
import UIConst from "../../../consts/UIConst";
import API_URL from "../../../consts/urls";


export const SetAdminModal = props => {
  const {handleHide, handleAction, id} = props;
  const [adminRole, setAdminRole] = useState(1);
  
  const onChangeAdminRole = (e) => {
    setAdminRole(parseInt(e.target.value));
  }
  const setAdminUser = () => {
    ApiHelper.put(API_URL.SetAdminUser, {}, {}, {
      user_id: parseInt(id),
      role: adminRole
    }).then(res => {
      handleAction()
      handleHide()
      toastr.success("Success!", "This user was set to admin user successfully.");
    }).catch(err => {
      toastr.error("Fail!", "Please try again.");
      handleHide()
    })
  }
  
  return (
    <div className="main-popup-container change-membership">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Set Admin user</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          <div className="main_modal_data nor_space_btns">
            <p className="collect_modal_coll chnage_fonts_size">Override user membership level regardless of what they
              are paying.</p>
            <div className="select-membership">
              <select name="adminRole" value={adminRole} onChange={onChangeAdminRole}>
                {UIConst.adminRoles.map((_option, index) => (
                  <option value={_option.value} key={_option.label}>{_option.label}</option>
                ))}
              </select>
              <div className="select-button">
                <div className="btn primary" onClick={setAdminUser}>
                  Change
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
