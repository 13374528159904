import React, { useCallback, useState } from "react";
import cardValueDark from "../../assets/images/cardValueDark.svg";
import {getMarketLink} from "../../helpers/util";
import cartDark from "../../assets/images/cartDark.svg";
import ebayLogo from "../../assets/images/settings/ebayLogo.svg";
import openInNewTabRed from "../../assets/images/openInNewTabRed.svg";
import gavelDark from "../../assets/images/gavelDark.svg";

import Zoom from "react-medium-image-zoom";
import CustomLazyLoadImage from "../LazyLoadImage";
import {Spinner} from "react-bootstrap";

const SpreadSheetCardPreview = ({currentSelectedCard, batch_id, isLoading}) => {
  const [previewFrontImage, setPreviewFrontImage] = useState(true);
  const previewImage = useCallback(() => {
    if (previewFrontImage) {
      if (currentSelectedCard.front_image !== '') {
        return (
          <Zoom>
            <CustomLazyLoadImage effect="blur" src={currentSelectedCard.front_image} alt="" style={{height: '150px'}}/>
          </Zoom>
        )
      }
      return (
        <div className={"no-preview-image"}>No Image</div>
      )
    } else {
      if (currentSelectedCard.back_image !== '') {
        return (
          <Zoom>
            <CustomLazyLoadImage effect="blur" src={currentSelectedCard.back_image} alt="" style={{height: '150px'}}/>
          </Zoom>
        )
      }
      return (
        <div className={"no-preview-image"}><span>No Image</span></div>
      )
    }
  }, [previewFrontImage, currentSelectedCard]);

  if (isLoading) {
    return (
      <div className="card-item-preview">
        <div className='component-loading-overlay'>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>)
  }
  return (
    <div className="card-item-preview">
      <h3>Card Preview</h3>
      <a className="card-title" href={`/batches/${batch_id}/${currentSelectedCard.card_id}?popup=0&status=${currentSelectedCard.status}`}
         target={'_blank'}>{currentSelectedCard.title}</a>
      {previewImage()}
      <div className={"card-item-image-preview"}>
        <span className={previewFrontImage ? 'active' : ''} onClick={() => setPreviewFrontImage(true)}>Front</span>
        <span className={!previewFrontImage ? 'back active' : 'back'}
              onClick={() => setPreviewFrontImage(false)}>Back</span>
      </div>
      <div className="market-places">
        <div className="market-place card-value">
          <div className="card-value-header">
            <img src={cardValueDark} alt=""/>
            <p>Card value</p>
          </div>
          <div className="values">
            <div className="value">
              <p>Average</p>
              <span>$ {currentSelectedCard.avg_value}</span>
            </div>
            <div className="value">
              <p>High</p>
              <span>$ {currentSelectedCard.high_value}</span>
            </div>
            <div className="value">
              <p>Low</p>
              <span>$ {currentSelectedCard.low_value}</span>
            </div>
          </div>

        </div>
        <a className="market-place" href={getMarketLink('ebay_sold', currentSelectedCard)} target="_blank">
          <img src={cartDark} alt=""/>
          <p>Recent Sales</p>
          <div className="open-in-new">
            <img src={ebayLogo} alt="" className="market-place-logo"/>
            <img src={openInNewTabRed} alt="" className="open-in-new-icon"/>
          </div>
        </a>
        <a className="market-place" href={getMarketLink('ebay_active', currentSelectedCard)} target="_blank">
          <img src={gavelDark} alt=""/>
          <p>Active Listings</p>
          <div className="open-in-new">
            <img src={ebayLogo} alt="" className="market-place-logo"/>
            <img src={openInNewTabRed} alt="" className="open-in-new-icon"/>
          </div>
        </a>
      </div>
    </div>
  )
}

export default SpreadSheetCardPreview;