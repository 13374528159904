import React, {useEffect, useState} from "react";

const News = () => {
  // const [iframeSRC, setiframeSRC] = useState('');
  const iframeId = 'fw-iframe3246701215';
  const fwparam = "152114/";
  const preloader_text = `preloader-text="Loading"`;
  // const set_interval_id = setInterval(addScript, 100);
  //
  // function addScript() {
  //   if (!document.getElementById(iframeId).contentWindow) return;
  //
  //   clearInterval(set_interval_id);
  //   var f = document.getElementById(iframeId).contentWindow.document;
  //   f.open();
  //   f.write('<body onload="'
  //     + 'window.fwIframeId=\'' + iframeId + '\';'
  //     + 'document.body.appendChild(document.createElement(\'script\')).src =\'https://feed.mikle.com/js/fw-widget.js?v=1.1\';'
  //     + '">');
  //   f.close();
  // }
  
  // const _fwMsg = (e) => {
  //   if (e.origin.match(/^https?:\/\/.*?feed\.mikle\.com$/i)) {
  //     let iframes = window.document.getElementsByClassName('fw-iframe');
  //     let backs = window.document.getElementsByClassName('fw-preview-button');
  //     for (let i = 0; i < iframes.length; i++) {
  //       if (iframes[i].dataset.src) {
  //         var src_url = iframes[i].dataset.src;
  //         if (src_url && typeof e.data.uri !== "undefined" &&
  //           unescape(e.data.uri.replace(/^https?:/i, '')) === unescape(src_url.replace(/^https?:/i, ''))) {
  //           iframes[i].style.width = e.data.width;
  //           iframes[i].style.height = e.data.height;
  //           if (iframes[i].parentElement.className === "fw-block") {
  //             iframes[i].parentElement.style.height = e.data.height;
  //           }
  //         }
  //       }
  //
  //       if (e.data.action && e.data.action === 'C_OPEN_INIF') {
  //         if (e.data.iframeId && e.data.iframeId !== iframes[i].getAttribute('id')) {
  //           continue;
  //         }
  //         iframes[i].setAttribute("scrolling", "yes");
  //         iframes[i].src = e.data.linkUri;
  //         backs[i].src = "https://feed.mikle.com/images/back-icon.png";
  //         backs[i].setAttribute("class", "fw-preview-button active");
  //       }
  //     }
  //   }
  // };
  //
  // useEffect(() => {
  //   // window.addEventListener("message", _fwMsg);
  // }, [])
  //
 
  
  return (
      <div className='fw-block' style={{position: 'relative', width: '100%', height: '232px'}}>
        <iframe id={iframeId} name={iframeId} title="fw-iframe"
                style={{position: 'relative', minWidth: '150px', minHeight: '150px', width: '1500px', height: '100%'}}
                className="fw-iframe"
                scrolling="auto"
                frameBorder="0"
                src={"https://feed.mikle.com/widget/v2/152114/?id=fw-iframe3246701215&preloader-text=Loading"}
                data-fw-params={fwparam + preloader_text}>
          <script src={"https://feed.mikle.com/js/fw-widget.js?v=1.1"}/>
        </iframe>
        <div id='preview-back'
             style={{position: 'absolute', top: '0px', left: '0px'}}>
        </div>
      </div>
  )
}

export default News;