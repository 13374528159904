import React, {useEffect, useState} from "react";
import closeIconBlack from "../../assets/images/closeIconBlack.svg"
import AuthHelper from "../../helpers/authHelper";

import API_URL from "../../consts/urls";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import FileDownload from "js-file-download";
import axios from "axios";
import {AlertYesNoModal} from "./alert";
import EbayExport from "./BatchExport/ebayExport";
import {isJsonBlob} from "../../helpers/commonHelper";
import {getBatchCardFlag} from "../../helpers/util";
import UIConst from "../../consts/UIConst";

const getDefaultMode = (settingOptions) => {
  for(const v of UIConst.batchTemplateFilter) {
    if (settingOptions[v.exportSettingOption]) {
      return v.key;
    }
  }
  return 'all';
}

const ExportBatch = ({batchId, batchName, data, handleHide, settingOptions}) => {
  const [exporting, setExporting] = useState(false);
  const [wrongValuePopup, setWrongValuePopup] = useState(false);
  const [showExportWizard, setShowExportWizard] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(getDefaultMode(settingOptions));
  const onChangeSelectedOption = (e) => {
    setSelectedOption(e.target.value);
  }

  useEffect(() => {
    if (selectedOption === 'custom_csv') {
      setFilteredData([])
      return;
    }
    let filterData = data.map((_card, i) => {
      let newCard = {..._card};
      const { flagValue, field } = getBatchCardFlag('ebay', newCard);
      newCard.flagValue = flagValue;
      newCard.flagField = field;
      return newCard;
    });
    setFilteredData(filterData);
  }, [selectedOption]);

  const validExport = () => {
    if (selectedOption === 'ebay') {
      const wrongValueIndex = filteredData.findIndex(r => r.flagValue === 3);
      if (wrongValueIndex >= 0) {
        setWrongValuePopup(true);
        return;
      }
    }
    handleExport();
  }
  const handleExport = () => {
    const currentOption = UIConst.batchTemplateFilter.find(v => v.key === selectedOption);
    setWrongValuePopup(false);
    if (selectedOption === 'ebay') {
      setShowExportWizard(true);
      return;
    }
    if (exporting === true) {
      toastr.warning('', `Download is in progress. Please wait for moments..`);
      return;
    }

    toastr.success('', `Download will start soon [${batchName}]. This will take some more time than expected`);
    setExporting(true)
    axios.get(API_URL.ExportBatch, {
      headers: {'Authorization': `Token ${AuthHelper.getAccessToken()}`},
      params: {
        image_hosted: currentOption.image_hosted,
        type: currentOption.exportType,
        batch: batchId
      },
      responseType: 'blob'
    }).then(async(res) => {
      const format1 = "MM-DD-YY"
      const dateTime1 = moment().format(format1);

      const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

      if ("message" in responseJson) {
        toastr.info(responseJson.message);
        return;
      }
      if (currentOption.image_hosted === true) {
        FileDownload(res.data, `${batchName}-Export-${dateTime1}.zip`);
        toastr.success('',`${batchName}-Export-${dateTime1}.zip is successfully downloaded`);
      }
      else {
        if (currentOption.key === "simple_auction"){
          FileDownload(res.data, `${batchName}-Export-${dateTime1}.xlsx`);
          toastr.success('',`${batchName}-Export-${dateTime1}.xlsx is successfully downloaded`);
        }
        else {
          FileDownload(res.data, `${batchName}-Export-${dateTime1}.csv`);
          toastr.success('',`${batchName}-Export-${dateTime1}.csv is successfully downloaded`);
        }
      }
    }).catch(async(err) => {
      const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

      toastr.error('error', `Failed to export collection [${batchName}]. ${responseJson.message}`);
    }).finally(() => {
      setExporting(false)
    });
  }
  if (wrongValuePopup) {
    return (
    <AlertYesNoModal
      handleAction={handleExport}
      handleHide={() => setWrongValuePopup(false)}
      handleWarning={() => setWrongValuePopup(false)}
      modalType="export_condition"
    />)
  }

  if (showExportWizard) {
    return (<EbayExport data={filteredData} batchName={batchName} batchId={batchId} handleHide={() => setShowExportWizard(false)}/>)
  }

  return (
    <div className="main-popup-container export-batch">
      <div className="main-overlay"/>
      <div className="main-popup">
        <div className="header">
          <h2>Export Batch</h2>
          <div className="close-popup" onClick={handleHide}>
            <img src={closeIconBlack} alt=""/>
          </div>
        </div>
        <div className="body">
          <div>
            <p style={{textAlign: 'center', maxWidth: '560px', marginBottom: '24px'}}>Choose an option below to
              generate the
              correct CSV file for your export. A link to download your export will be emailed to you once
              complete.</p>
            <div className="export-batch-options-container">
              <select name="export-batch-options" value={selectedOption} onChange={onChangeSelectedOption}>
                {UIConst.batchTemplateFilter.filter(v => (v.exportSettingOption === 'standard' || settingOptions[v.exportSettingOption]))
                  .map(v => (<option value={v.key} key={v.key}>{v.exportOptionLabel}</option>)
                )}
              </select>
              <div className="btn primary" style={{width: '102px', marginLeft: '12px'}} onClick={validExport}>Export</div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  )
}

export default ExportBatch;