import Dashboard from "./containers/Dashboard";
import dashboardImg from "./assets/images/Dashboard icon.svg";
import userManagementImg from "./assets/images/admin_manage.png";
import batchesImg from "./assets/images/batches.svg";
import scanImg from "./assets/images/scanners.svg";
import settingsImg from "./assets/images/settings.svg";
import downloadsImg from "./assets/images/download.svg";
import Batches from "./containers/Batches";
import BatchDetail from "./containers/Batches/batchDetail";
import Settings from "./containers/Settings";
import Scanners from "./containers/Scanners";
import Downloads from "./containers/Downloads";
import CardDetail from "./containers/CardDetail";
import About from "./containers/Footer/about";
import ContactUs from "./containers/Footer/contactus";
import AdminDashboard from "./containers/Admin/Dashboard";
import UserManagement from "./containers/Admin/UserManagement";
import UserDetailView from "./containers/Admin/UserManagement/userDetailView";
import ExportSettingsContainer from "./containers/Settings/exportSettingsContainer";

const normalRoutes = {
  role: 0,
  routes: [
    {
      path: "/dashboard",
      name: "Dashboard",
      show: "true",
      icon: dashboardImg,
      component: Dashboard
    },
    {
      path: "/batches",
      name: "Batches",
      show: "true",
      icon: batchesImg,
      component: Batches
    },
    {
      path: "/batches/:batch_id",
      name: "Batches",
      show: "false",
      icon: batchesImg,
      component: BatchDetail
    },
    {
      path: "/batches/:batch_id/:card_id",
      name: "Batches",
      show: "false",
      icon: batchesImg,
      component: CardDetail
    },
    {
      path: "/scanner",
      name: "Scanners",
      show: "true",
      icon: scanImg,
      component: Scanners
    },
    {
      path: "/settings",
      name: "Settings",
      show: "true",
      icon: settingsImg,
      component: Settings
    },
    {
      path: "/settings/export",
      name: "Export Settings",
      show: "false",
      component: ExportSettingsContainer
    },
    {
      path: "/downloads",
      name: "Downloads",
      show: "true",
      icon: downloadsImg,
      component: Downloads
    },
    {
      path: "/contact",
      show: "false",
      component: ContactUs
    },
    {
      path: "/about",
      show: "false",
      component: About
    },
  ]
};

const adminRoutes = {
  role: 3,
  routes: [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      show: "true",
      icon: dashboardImg,
      component: AdminDashboard
    },
    {
      path: "/admin/user_management",
      name: "User Management",
      show: "true",
      icon: userManagementImg,
      component: UserManagement
    },
    {
      path: "/admin/user_management/detail/:id",
      name: "User Management",
      show: "false",
      icon: userManagementImg,
      component: UserDetailView
    },
  ]
}

const moderateRoute = {
  role: 1,
  routes: [
    {
      path: "/dashboard",
      name: "Dashboard",
      show: "true",
      icon: dashboardImg,
      component: AdminDashboard
    },
  ]
}

const routesInfo = {normalRoutes, adminRoutes, moderateRoute};
export default routesInfo;
