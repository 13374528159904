import { City, Country, State } from 'country-state-city';
import {getFormatVal} from "./commonHelper";
import DefaultData from "../consts/defaultData";

const getMarketLink = (extension, cardData, gradeInfo = null) => {
  const EBAY_ACTIVE_URL = "https://www.ebay.com/sch/i.html?_nkw="
  const EBAY_SOLD_URL = "https://www.ebay.com/sch/i.html?LH_Sold=1&_nkw="
  const MAVIN_URL = "https://mavin.io/search?q="
  let backURL = '';
  const brand = cardData.brand.replace(`${cardData.year} `, '');

  let backURLParams = [
    `${getFormatVal(cardData.year)}`.trim(),
    `${brand}`.trim(),
    `${getFormatVal(cardData.card)}`.trim(),
    `${getFormatVal(cardData.player)}`.trim()
  ]

  if (cardData.subset !== "") {
    backURLParams.push(`${getFormatVal(cardData.subset)}`.trim());
  }

  if (gradeInfo) {
    if (gradeInfo?.grader !== '' && gradeInfo?.numeric_grade !== '') {
      backURLParams.push(`${getFormatVal(gradeInfo?.grader)}`.trim() + ' ' + `${getFormatVal(gradeInfo?.numeric_grade)}`.trim())
    }
  } else {
    if (cardData.grader !== '' && cardData.numeric_grade !== '') {
      backURLParams.push(`${getFormatVal(cardData.grader)}`.trim() + ' ' + `${getFormatVal(cardData.numeric_grade)}`.trim())
    }
  }

  backURL = backURLParams.join('+');
  backURL = backURL.replaceAll(' & ', ' ')
  backURL = encodeURI(backURL);
  if (extension === 'ebay_active') {
    backURL = EBAY_ACTIVE_URL + backURL + `&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338833009&customid=&toolid=10001&mkevt=1`;
  } else if (extension === 'ebay_sold') {
    backURL = EBAY_SOLD_URL + backURL + `&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338833009&customid=&toolid=10001&mkevt=1`;
  } else if (extension === 'mavin') {
    backURL = MAVIN_URL + backURL;
  }
  return backURL;
}

const numericCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
  const flValA = parseFloat(valueA);
  const flValB = parseFloat(valueB);
  if (isNaN(flValA) || isNaN(flValB)) {
    return valueA.localeCompare(valueB);
  }
  if (flValA === flValB) return 0;
  return (flValA > flValB) ? 1 : -1;
};

const getBatchCardFlag = (marketMode, data) => {
  const flagData = DefaultData.batchFlagValues[marketMode];
  let flagValue = 0, field = '';
  if (data['title']?.length > 80) {
    flagValue = 3; field = 'title'
    return { flagValue, field };
  }
  for (let f_index = 0; f_index < DefaultData.batchFlagValues.column_info.length; f_index ++) {
    const fieldName = DefaultData.batchFlagValues.column_info[f_index];
    // console.log('fieldName',fieldName)    
    if (fieldName === 'grader' && data['graded'] === 'Ungraded') {
      continue;
    }
    if (fieldName === 'numeric_grade' && data['graded'] === 'Ungraded') {
      continue;
    }
    if (fieldName === 'grade' && data['graded'] === 'Graded') {
      continue;
    }
    if (flagData[f_index] > 1 && (data[fieldName] === null || data[fieldName] === '') && flagData[f_index] > flagValue) {
      flagValue = flagData[f_index];
      field = fieldName;
      if (flagValue === 3) {
        return { flagValue, field };
      }
    }
  }
  return { flagValue, field };
}

const getBatchCardColumnFlag = (marketMode, fieldName) => {
  if (marketMode === 'all') {
    return 1;
  }
  const flagData = DefaultData.batchFlagValues[marketMode];
  const f_index = DefaultData.batchFlagValues.column_info.findIndex(s => (s === fieldName));
  if (f_index >= 0) {
    return flagData[f_index];
  }
  return 0;
}

const getCitiesByStateIsoCode = (stateIso) => {
  const countryCode = 'US';
  return City.getCitiesOfState(countryCode, stateIso);
}

const getStates = () => {
  const countryCode = 'US';
  const country = Country.getCountryByCode(countryCode);
  return State.getStatesOfCountry(country.isoCode);
}

const getCardSpreadColumnName = (field) => {
  if (field === 'start_price') { return 'Price'; }
  if (field === 'grade') { return 'Condition/Grade';}
  field = field.replaceAll('_', ' ');
  return field.charAt(0).toUpperCase() + field.slice(1);
}
export {
  getMarketLink,
  numericCompare,
  getBatchCardFlag,
  getBatchCardColumnFlag,
  getCitiesByStateIsoCode,
  getStates,
  getCardSpreadColumnName
}
