import * as payment from '../actions/payment.js';

const initialState = {
  doingAction: false
};

const paymentReducer = (state = initialState, action) => {
  switch(action.type) {
    case payment.PAYMENT_ACTION_BEGIN:
      return {
        ...state,
        doingAction: true
      };
    case payment.PAYMENT_ACTION_END:
      return {
        ...state,
        doingAction: false
      };
    default:
      return state;
  }
}

export default paymentReducer;