import React, { useState } from 'react';
import { toastr } from "react-redux-toastr";
import {Link, useHistory} from "react-router-dom";
import ApiHelper from "../../helpers/apiHelper";
import logoImage from "../../assets/images/logo.png";
import backArrow from "../../assets/images/backArrowRed.svg";
import API_URL from "../../consts/urls";

export default function ResetPasswordEmail()  {
  const [error, setError] = useState('')
  const history = useHistory();
  
  const handleSubmit = e => {
    e.preventDefault();
    let email = e.target.elements.email.value;
    
    if (email === '') {
      setError('Email address is required.');
      return;
    }
    
    ApiHelper.post(API_URL.PasswordReset, {}, {}, {
      email: email
    }, false).then(res => {
      // setLoading(false);
      toastr.success('Success!', 'Reset Password request was successfully sent.');
      history.push('/login');
    }).catch(err => {
      // setLoading(false);
      toastr.error('Fail!', 'This is not a verified email address.');
    })
  };
  
  const handleChangeInput = () => {
    if (error !== '') setError('');
  };
  
  return (
    <div className='reset-password login-bg'>
      <div className="main-container reset-password">
        <div className="sec-container">
          <div className="logo">
            <img src={logoImage} alt="" onClick={() => history.push('/login')}/>
          </div>
          <div className="reset-password-container">
            <h1>Reset password</h1>
            <p>Enter your account’s verified email address and we will send you a password rest link</p>
            <form onSubmit={handleSubmit}>
              
              <div className="input text">
                <label htmlFor="email-address">Email address</label>
                <input type="text" name="email" id="email-address" onChange={handleChangeInput}/>
              </div>
              
              <button type="submit" className="btn primary">Send</button>
            </form>
            <div className="no-account-yet">
              <Link to="/login"> <img src={backArrow} alt="" /> Back to login page</Link>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}
